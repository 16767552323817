import React, { useCallback, useEffect, useState } from 'react';
import { i18n } from 'Language';

// Utils
import { convert as convertCasefileType } from 'Labs/BetterFlowOverview/utils/convert-casefile-type';
import {
    GroupedRound,
    getDocumentTypeById,
    getSignerTypeById,
    getSigningGroupedRound,
} from 'Labs/BetterFlowOverview/components/BetterFlowOverview';

// Types
import { ClientSideDocument } from 'types/Document';
import { CaseFileType } from 'types/CaseFile';
import { Recipient } from '../RecipientModal';

import { RolesAndActivation } from './types';
import { useAppDispatch, useAppSelector } from 'Store';
import {
    setActivationDates,
    setDateErrors,
    setRolesAndActivations,
} from './redux';

import './casefile-rounds.scss';

import {
    getCustomRole,
    getDocumentsByDocumentTypeId,
    getSignersBySignerTypeId,
} from './utils';
import { BetterCaseFileType } from 'Labs/BetterFlowOverview/types';
import UIButton from 'Common/components/Vega/Button';
import classNames from 'classnames';
import CasefileDocumentPreviewModal from '../CasefileDocumentPreviewModal';
import UIDateTimePicker from 'Common/components/Vega/DateTimePicker';
import {
    getDate,
    getDateTime,
    getDateWithTimezoneOffset,
} from 'Common/components/Vega/utils';
import CasefileActions from 'Casefiles/actions/CasefilesActionCreators';
import { Moment } from 'moment';
import UIBanner from 'Common/components/Vega/Banner';
import { storage } from 'Core';
import UIInlineMessage from 'Common/components/Vega/InlineMessage';

type DateError = { errorTitle: string; errorDescription: string };

const dateErrors: DateError[] = [
    {
        errorTitle: 'Scheduled date and time unavailable',
        errorDescription:
            'The selected date and time for sending Signing Round have passed. Please choose a new date and time.',
    },
    {
        errorTitle: 'Dates do not match',
        errorDescription: `Ensure the sending date follows recipient's activation date in the Signing Round.`,
    },
];

type Props = {
    signers: Recipient[];
    documents: ClientSideDocument[];
    casefileType: CaseFileType;
    sendAtDate: Moment | null;
    sendLater: boolean;
    expireAtDate: Moment | null;
    clearExpireDate: () => void;
};

const CasefileRoundsTimeline = ({
    casefileType,
    documents,
    signers,
    sendAtDate,
    sendLater,
    expireAtDate,
    clearExpireDate,
}: Props) => {
    const dispatch = useAppDispatch();
    const activationDates = useAppSelector(
        (state) => state.rounds.activationDates
    );
    const [hideBanner, setHideBanner] = useState<boolean>(
        !!storage.get('hideFirstSigningRoundBanner')
    );
    const [toggleDetails, setToggleDetails] = useState<Record<string, boolean>>(
        {}
    );
    const [editingDate, setEditingDate] = useState<Record<string, boolean>>({});
    const [dateSelector, setDateSelector] = useState<
        Record<string, number | undefined>
    >({});
    /**
     * Round document(s) preview modal
     */
    const [previewDocumentId, setPreviewDocumentId] = useState<number | null>(
        null
    );
    const [previewDocumentsList, setPreviewDocumentList] = useState<
        ClientSideDocument[]
    >([]);

    const handleHideBanner = useCallback(() => {
        storage.set('hideFirstSigningRoundBanner', true);
        setHideBanner(true);
    }, []);

    const toggleRoundDetails = useCallback(
        (round: number) =>
            setToggleDetails({
                ...toggleDetails,
                [round]: !toggleDetails[round],
            }),
        [toggleDetails]
    );

    const toggleEditingDate = useCallback(
        (round: number) =>
            setEditingDate({
                ...editingDate,
                [round]: !editingDate[round],
            }),
        [editingDate]
    );

    const editRoundDate = useCallback(
        (round: number, date?: number) =>
            setDateSelector({
                ...dateSelector,
                [round]: date,
            }),
        [dateSelector]
    );

    const persistRoundDate = useCallback(
        (round: number) => {
            dispatch(
                setActivationDates({
                    round,
                    date: dateSelector[round] ?? null,
                })
            );

            /**
             * If we are editing the first round, we also edit
             * the whole casefile's send date
             */
            if (round > 0 || !dateSelector[round]) {
                return;
            }

            const newSendAtDate = getDate(dateSelector[round]);

            CasefileActions.sendLaterToggle(true);
            CasefileActions.setSendAtDate(newSendAtDate);

            /**
             * Additionally, if this new date is same or AFTER the expiry date
             * we reset it
             */
            if (
                expireAtDate != null &&
                newSendAtDate.isSameOrAfter(expireAtDate)
            ) {
                clearExpireDate();
            }
        },
        [dateSelector, expireAtDate]
    );

    const resetRoundActivation = useCallback((round: number) => {
        dispatch(
            setActivationDates({
                round,
                date: null,
            })
        );

        /**
         * We remove casefile's send date
         * on resetting the activation
         */
        if (round > 0) {
            return;
        }

        CasefileActions.clearSendDate();
        CasefileActions.sendLaterToggle(false);
    }, []);

    const getAllDocumentsInRoundSection = useCallback(
        (documents: ClientSideDocument[], round: GroupedRound) => {
            let docs: ClientSideDocument[] = [];

            round.documentTypeIds.forEach(
                (ids) =>
                    (docs = docs.concat(
                        getDocumentsByDocumentTypeId(documents, ids)
                    ))
            );

            return docs;
        },
        []
    );

    const previewAllDocumentsInRoundSection = useCallback(
        (documents: ClientSideDocument[], round: GroupedRound) => {
            const docList = getAllDocumentsInRoundSection(documents, round);

            if (!docList.length) {
                return;
            }

            setPreviewDocumentId(docList[0].id);
            setPreviewDocumentList(docList);
        },
        []
    );

    const countDocumentsByDocumentType = useCallback(
        (documents: ClientSideDocument[], documentTypeIds: number[]) =>
            documentTypeIds.reduce(
                (count, documentTypeId) =>
                    count +
                    getDocumentsByDocumentTypeId(documents, documentTypeId)
                        .length,
                0
            ),
        []
    );

    const renderDocumentsCountByRound = useCallback(
        (documents: ClientSideDocument[], groupedRound: GroupedRound[]) => {
            const counter = groupedRound.reduce(
                (count, round) =>
                    count +
                    countDocumentsByDocumentType(
                        documents,
                        round.documentTypeIds
                    ),
                0
            );

            return (
                <span className="casefile-round-counter">
                    <i className="fal fa-copy fa-lg" /> {counter}{' '}
                    {counter === 1 ? i18n`Document` : i18n`Documents`}
                </span>
            );
        },
        []
    );

    const countSignersByRound = useCallback(
        (signers: Recipient[], groupedRound: GroupedRound[]) => {
            /**
             * We use a Set to prevent same recipient
             * with multiple roles being counted twice
             */
            const uniqueSigners = new Set<string>();

            groupedRound.forEach((round) =>
                round.signerTypeIds.forEach((signerTypeId) =>
                    getSignersBySignerTypeId(signers, signerTypeId).forEach(
                        (signer) =>
                            signer.email && uniqueSigners.add(signer.email)
                    )
                )
            );

            return uniqueSigners.size;
        },
        []
    );

    const renderSignersCountByRound = useCallback(
        (signers: Recipient[], groupedRound: GroupedRound[]) => {
            const counter = countSignersByRound(signers, groupedRound);

            return (
                <span className="casefile-round-counter">
                    <i className="fal fa-users fa-lg" /> {counter}{' '}
                    {counter === 1 ? i18n`Signer` : i18n`Signers`}
                </span>
            );
        },
        []
    );

    const renderDocumentsByDocumentType = useCallback(
        (
            documents: ClientSideDocument[],
            casefileType: BetterCaseFileType,
            documentTypeIds: number[]
        ) =>
            documentTypeIds.map((documentTypeId) => {
                const documentList = getDocumentsByDocumentTypeId(
                    documents,
                    documentTypeId
                );

                return (
                    <div
                        key={documentTypeId}
                        className="casefile-round-documents-item casefile-round-list-item">
                        {documentList.length > 0 && (
                            <>
                                <i className="far fa-file-pdf casefile-round-documents-icon" />
                                <i className="far fa-ellipsis-v casefile-round-list-item-edit-icon" />
                            </>
                        )}
                        {documentList.map((doc, index) => (
                            <div
                                className="casefile-round-documents-file"
                                key={`doc-${index}`}>
                                <span>{doc.name}</span>
                            </div>
                        ))}
                        {documentList.length > 0 && (
                            <div className="casefile-round-documents-type">
                                {
                                    getDocumentTypeById(
                                        casefileType,
                                        documentTypeId
                                    ).name
                                }
                            </div>
                        )}
                    </div>
                );
            }),
        []
    );

    const renderSignersBySignerType = useCallback(
        (
            signers: Recipient[],
            casefileType: BetterCaseFileType,
            signerTypeIds: number[]
        ) => {
            return signerTypeIds.map((signerTypeId) =>
                getSignersBySignerTypeId(signers, signerTypeId).map(
                    (signer) => (
                        <div
                            className="casefile-round-signer casefile-round-list-item"
                            key={`signer-${signer.tempId}`}>
                            <i className="far fa-ellipsis-v casefile-round-list-item-edit-icon"></i>
                            <span className="casefile-round-signer-name">
                                {signer.name}
                            </span>
                            <span className="casefile-round-signer-email">
                                {signer.email}
                            </span>
                            <span className="casefile-round-signer-role">
                                {getCustomRole(signer.role, signerTypeId)
                                    ? getCustomRole(signer.role, signerTypeId)
                                    : getSignerTypeById(
                                          casefileType,
                                          signerTypeId
                                      ).role}
                            </span>
                        </div>
                    )
                )
            );
        },
        []
    );

    /**
     * Here we check for inconsistencies on selected dates
     * fx. date has already passed or the previous round's date
     * is after the current one
     */
    const checkDateErrors = useCallback(
        (round: number): DateError | undefined => {
            const currentRound = activationDates[`round-${round}`];

            if (!currentRound) {
                return undefined;
            }

            // Check if date is too old or not
            const currentRoundDate = getDate(currentRound);
            const now = getDate();

            if (currentRoundDate.isSameOrBefore(now)) {
                return dateErrors[0];
            }

            // Check that date is newer than all previous rounds' date

            // if we are on the first round, just return
            if (round === 0) {
                return undefined;
            }

            // otherwise, get all previous rounds WITH a date
            const previousRounds: number[] = [];

            for (let i = 0; i < round; i++) {
                const date = activationDates[`round-${i}`];

                if (date) {
                    previousRounds.push(date);
                }
            }

            if (!previousRounds.length) {
                return undefined;
            }

            // We sort the previous rounds in DESC order, to get the latest date first
            previousRounds.sort((a, b) => b - a);

            if (currentRoundDate.isSameOrBefore(getDate(previousRounds[0]))) {
                return dateErrors[1];
            }

            return undefined;
        },
        [activationDates]
    );

    // Convert Casefile Type to better format.
    const convertedCasefileType = convertCasefileType(casefileType);

    /**
     * Attachments are all doc types that aren't signable
     */
    let attachments: ClientSideDocument[] = [];

    convertedCasefileType.documentTypes
        .filter((documentType) => !documentType.signable)
        .forEach(
            (attachmentType) =>
                (attachments = attachments.concat(
                    getDocumentsByDocumentTypeId(documents, attachmentType.id)
                ))
        );

    // Get rounds
    const { rounds } = convertedCasefileType;

    /**
     * Set activation dates to roles in specific rounds.
     * Note that these will be updated every time signers
     * change, since some role ids might not exist anymore
     * TODO - save this on Redux
     */
    const rolesAndActivation: RolesAndActivation[] = [];

    useEffect(() => {
        /**
         * If there's a "send at" date set for the casefile,
         * use it to set the first round activation date
         */
        if (sendLater && sendAtDate != null && !activationDates[`round-0`]) {
            dispatch(
                setActivationDates({
                    round: 0,
                    date: sendAtDate.unix(),
                })
            );
        }
    }, [sendLater, sendAtDate]);

    useEffect(() => {
        rounds.forEach((round, index) => {
            let signerTypeIds: number[] = [];

            round.forEach((currentRound) => {
                currentRound.signerTypeIds.map((id) => {
                    if (
                        (!rolesAndActivation[index - 1] ||
                            !rolesAndActivation[
                                index - 1
                            ]?.signerTypeIds.includes(id)) &&
                        !signerTypeIds.includes(id)
                    ) {
                        signerTypeIds.push(id);
                    }
                });
            });

            rolesAndActivation[index] = {
                signerTypeIds,
                activation: activationDates[`round-${index}`] ?? null,
            };

            /**
             * Find the first signer with a role with an activation date
             * and use it to assume the activation date for the whole round
             */
            rolesAndActivation.forEach((round, roundIndex) =>
                signers.find((signer) => {
                    const sign = signer.role.find(
                        (role) =>
                            round.signerTypeIds.includes(role.id) &&
                            role.activeAt
                    );

                    if (
                        sign &&
                        activationDates[`round-${roundIndex}`] === undefined
                    ) {
                        dispatch(
                            setActivationDates({
                                round: roundIndex,
                                date: sign.activeAt as number,
                            })
                        );
                    }
                })
            );
        });
    }, [activationDates, rounds]);

    useEffect(() => {
        if (!rolesAndActivation.length) {
            return;
        }

        dispatch(setRolesAndActivations(rolesAndActivation));

        // Check for errors!
        const errors = rolesAndActivation.map((_, i) =>
            checkDateErrors(i) ? true : false
        );

        dispatch(setDateErrors(errors));
    }, [rolesAndActivation]);

    return (
        <>
            <div>
                <h4 className="casefile-details-section-header">
                    {i18n`Timeline`}
                </h4>
                <p>{i18n`Signing rounds will be sent sequentially after collecting all signatures from the previous round as the default option`}</p>
            </div>

            {/* Rounds */}
            <div className="casefile-rounds">
                {rounds.map((round, roundIndex) => {
                    const groupedRound = getSigningGroupedRound(round);

                    /**
                     * If the user has defined an activation date for this round, use that.
                     * By default, if no date has been selected use today's date
                     * for all rounds (which won't be sent to the API)
                     */
                    const activationDate =
                        activationDates[`round-${roundIndex}`];

                    const activateRoundAt = getDate(activationDate);

                    /**
                     * This date is used when user enters "edit mode" to
                     * select a new activation date. If there's none already set,
                     * it defaults to the persisted activation date (which in turn
                     * will return today's date if undefined)
                     */
                    const tempDate = getDate(
                        dateSelector[roundIndex] ?? activationDate
                    );

                    /**
                     * Check for date errors in this round
                     */
                    const hasDateErrors = checkDateErrors(roundIndex);

                    //console.log(roundIndex, hasDateErrors);

                    return (
                        <div
                            className="casefile-round"
                            key={`signing-round-${roundIndex}`}>
                            <div className="casefile-round-container">
                                <div
                                    className={classNames(
                                        'casefile-round-heading',
                                        { error: hasDateErrors }
                                    )}>
                                    <span>
                                        {i18n`Signing round`} {roundIndex + 1}
                                    </span>
                                    {hasDateErrors && (
                                        <i className="fas fa-exclamation-triangle ml-auto text-danger-200" />
                                    )}
                                </div>

                                <div className="casefile-round-content">
                                    {(activationDate ||
                                        (!activationDate &&
                                            editingDate[roundIndex])) && (
                                        <div className="casefile-round-scheduling">
                                            <div
                                                className={classNames(
                                                    'casefile-round-scheduling-selector',
                                                    {
                                                        editing:
                                                            editingDate[
                                                                roundIndex
                                                            ],
                                                    }
                                                )}>
                                                {!editingDate[roundIndex] ? (
                                                    <>
                                                        <div className="casefile-round-date">
                                                            {i18n`Scheduled for:`}
                                                            <span
                                                                className={classNames(
                                                                    'highligthed',
                                                                    {
                                                                        error: hasDateErrors,
                                                                    }
                                                                )}>
                                                                {activateRoundAt.format(
                                                                    'ddd, MMMM Do YYYY HH:mm'
                                                                )}
                                                            </span>
                                                            <a
                                                                href="#"
                                                                className="casefile-round-date-edit"
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    e.preventDefault();
                                                                    toggleEditingDate(
                                                                        roundIndex
                                                                    );
                                                                }}>
                                                                <i className="far fa-calendar fa-lg" />
                                                            </a>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div className="casefile-round-date-picker">
                                                            <UIDateTimePicker
                                                                attachedLabel={i18n`Scheduled for`}
                                                                minDate={getDateTime(
                                                                    getDate()
                                                                )}
                                                                value={getDateTime(
                                                                    tempDate
                                                                )}
                                                                onInput={(
                                                                    event: UIEvent,
                                                                    values: Pick<
                                                                        HTMLInputElement,
                                                                        | 'value'
                                                                        | 'valueAsNumber'
                                                                    >
                                                                ) => {
                                                                    /**
                                                                     * In some browsers, there's a 'clear' button on the date picker.
                                                                     * When clicked, it triggers an input event with an empty value,
                                                                     * so we need to check we are passing a valid timestamp
                                                                     * or default to undefined
                                                                     */
                                                                    let timestamp;

                                                                    if (
                                                                        values.value
                                                                    ) {
                                                                        timestamp =
                                                                            values.valueAsNumber;
                                                                    }

                                                                    editRoundDate(
                                                                        roundIndex,
                                                                        getDateWithTimezoneOffset(
                                                                            timestamp
                                                                        ).unix()
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="casefile-round-date-action casefile-round-date-apply">
                                                            <UIButton
                                                                onClick={() => {
                                                                    persistRoundDate(
                                                                        roundIndex
                                                                    );

                                                                    // close editing mode
                                                                    toggleEditingDate(
                                                                        roundIndex
                                                                    );
                                                                }}
                                                                priority="primary"
                                                                type="accent"
                                                                isDisabled={
                                                                    !dateSelector[
                                                                        roundIndex
                                                                    ]
                                                                }>
                                                                {i18n`Apply`}
                                                            </UIButton>
                                                        </div>
                                                        <div className="casefile-round-date-action casefile-round-date-cancel">
                                                            <UIButton
                                                                onClick={() => {
                                                                    // Reset temp date
                                                                    editRoundDate(
                                                                        roundIndex
                                                                    );

                                                                    // close editing mode
                                                                    toggleEditingDate(
                                                                        roundIndex
                                                                    );
                                                                }}
                                                                priority="tertiary"
                                                                type="accent">
                                                                {i18n`Cancel`}
                                                            </UIButton>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                            {!editingDate[roundIndex] && (
                                                <div className="casefile-round-date-action casefile-round-date-reset">
                                                    <UIButton
                                                        onClick={() =>
                                                            resetRoundActivation(
                                                                roundIndex
                                                            )
                                                        }
                                                        priority="tertiary"
                                                        type="neutral"
                                                        size="sm">
                                                        {i18n`Reset`}
                                                    </UIButton>
                                                </div>
                                            )}
                                        </div>
                                    )}

                                    {hasDateErrors && !editingDate[roundIndex] && (
                                        <div className="mt-2">
                                            <UIInlineMessage
                                                variant="danger"
                                                title={i18n(
                                                    hasDateErrors.errorTitle
                                                )}
                                                description={i18n(
                                                    hasDateErrors.errorDescription
                                                )}
                                                hideCloseButton={true}
                                            />
                                        </div>
                                    )}

                                    {!activationDate &&
                                        !editingDate[roundIndex] && (
                                            <>
                                                <div className="casefile-round-scheduling-toggle">
                                                    <UIButton
                                                        onClick={() =>
                                                            toggleEditingDate(
                                                                roundIndex
                                                            )
                                                        }
                                                        priority="tertiary"
                                                        type="accent"
                                                        size="sm">
                                                        {i18n`Schedule sending`}
                                                        <i
                                                            slot="leftIcon"
                                                            className="far fa-calendar fa-lg"
                                                        />
                                                    </UIButton>
                                                </div>

                                                {roundIndex === 0 &&
                                                    !hideBanner && (
                                                        <div className="mt-2">
                                                            <UIBanner
                                                                variant="accent"
                                                                layout="vertical"
                                                                linkText={i18n`Learn more`}
                                                                linkUrl="#"
                                                                closeButton
                                                                onCloseClick={() =>
                                                                    handleHideBanner()
                                                                }>
                                                                <span slot="titleText">
                                                                    {i18n`Changes in Send Later Casefile`}
                                                                </span>
                                                                <span slot="subtitleText">
                                                                    {i18n`Easy schedule Casefile for the later sending by setting up dates in Signing Round 1`}
                                                                </span>
                                                            </UIBanner>
                                                        </div>
                                                    )}
                                            </>
                                        )}

                                    <div className="casefile-round-stats">
                                        {renderDocumentsCountByRound(
                                            documents,
                                            groupedRound
                                        )}

                                        {renderSignersCountByRound(
                                            signers,
                                            groupedRound
                                        )}

                                        <div className="casefile-round-toggle-details">
                                            <UIButton
                                                onClick={() =>
                                                    toggleRoundDetails(
                                                        roundIndex
                                                    )
                                                }
                                                priority="tertiary"
                                                type="neutral"
                                                size="xs">
                                                {toggleDetails[roundIndex]
                                                    ? i18n`Show more`
                                                    : i18n`Show less`}
                                                <i
                                                    slot="rightIcon"
                                                    className={classNames(
                                                        'far',
                                                        {
                                                            'fa-chevron-down':
                                                                toggleDetails[
                                                                    roundIndex
                                                                ],
                                                            'fa-chevron-up': !toggleDetails[
                                                                roundIndex
                                                            ],
                                                        }
                                                    )}
                                                />
                                            </UIButton>
                                        </div>
                                    </div>

                                    {!toggleDetails[roundIndex] && (
                                        <div
                                            className={
                                                'casefile-round-details'
                                            }>
                                            {groupedRound.map(
                                                (round, index) => {
                                                    return (
                                                        <div
                                                            className="casefile-round-section"
                                                            key={`casefile-round-section-${roundIndex}-${index}`}>
                                                            <div className="casefile-round-list-container">
                                                                <div className="casefile-round-section-header">
                                                                    <span className="casefile-round-section-header-text">
                                                                        {i18n`Documents`}
                                                                    </span>

                                                                    <div className="casefile-round-section-header-actions">
                                                                        <div className="casefile-round-section-header-actions-item">
                                                                            <UIButton
                                                                                onClick={() =>
                                                                                    previewAllDocumentsInRoundSection(
                                                                                        documents,
                                                                                        round
                                                                                    )
                                                                                }
                                                                                priority="tertiary"
                                                                                type="accent"
                                                                                size="xs">
                                                                                {i18n`Preview all`}
                                                                                <i
                                                                                    slot="leftIcon"
                                                                                    className="far fa-expand-arrows fa-lg"
                                                                                />
                                                                            </UIButton>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="casefile-round-list">
                                                                    {renderDocumentsByDocumentType(
                                                                        documents,
                                                                        convertedCasefileType,
                                                                        round.documentTypeIds
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="casefile-round-list-container">
                                                                <div className="casefile-round-section-header">
                                                                    <span className="casefile-round-section-header-text">
                                                                        {i18n`Signed by`}
                                                                    </span>
                                                                </div>

                                                                <div className="casefile-round-list">
                                                                    {renderSignersBySignerType(
                                                                        signers,
                                                                        convertedCasefileType,
                                                                        round.signerTypeIds
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>

                            {roundIndex < rounds.length - 1 && (
                                <div
                                    className="casefile-round-separator"
                                    key={`signing-round-separator-${roundIndex}`}>
                                    <i className="fal fa-line-height fa-lg" />
                                    {i18n`Next in a Casefile sequence`}
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>

            {!!attachments.length && (
                <div className="casefile-round-options-section casefile-attachments">
                    <div className="casefile-round-section-header">
                        <span className="casefile-round-section-header-text">
                            {i18n`Attachments`}
                        </span>
                    </div>

                    <div className="casefile-round-list">
                        {attachments.map((doc, index) => (
                            <div
                                className="casefile-round-documents-item casefile-round-list-item"
                                key={`attachment-doc-${index}`}>
                                <i className="far fa-file-pdf casefile-round-documents-icon"></i>
                                <i className="far fa-ellipsis-v casefile-round-list-item-edit-icon"></i>
                                <div className="casefile-round-documents-file">
                                    <span>{doc.name}</span>
                                </div>
                                <div className="casefile-round-documents-type">
                                    {
                                        getDocumentTypeById(
                                            convertedCasefileType,
                                            doc.documentTypeId
                                        ).name
                                    }
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}

            {previewDocumentId &&
                previewDocumentsList.find(
                    (doc) => doc.id === previewDocumentId
                ) && (
                    <CasefileDocumentPreviewModal
                        id={previewDocumentId}
                        uploadedDocuments={previewDocumentsList}
                        onOpen={(documentId: number) =>
                            setPreviewDocumentId(documentId)
                        }
                        onClose={() => setPreviewDocumentId(null)}
                    />
                )}
        </>
    );
};

export default React.memo(CasefileRoundsTimeline);
