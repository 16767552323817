const fakeFn = () => Promise.resolve();

const mockAmplitude = {
    init: fakeFn,
    track: fakeFn,
    setUserId: fakeFn,
    initUser: fakeFn,
    setUserProperties: fakeFn,
    userProperties: fakeFn,
    identify: fakeFn,
    incrementUserProperty: fakeFn,
    appendUserProperty: fakeFn,
};

export default mockAmplitude;
