import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import assign from 'object-assign';
import lodash from 'lodash';
import { Dispatcher } from 'Core';
import { Languages } from 'Language/Constants';
import { TEMPLATE_ENTITY_KIND, TEMPLATE_ENTITY_NAME } from 'Constants';
import Constants from 'Casefiles/Constants';
import { i18n } from 'Language';
import { notify } from 'react-notify-toast';
import { modal } from 'Common/components/Common/Modal';
import amplitude from 'Common/Amplitude';
import analytics from 'Common/Analytics';
import Loader from 'Common/components/Common/Loader';
import { Helmet } from 'react-helmet';
import { ReduxState } from 'Store';
import { UserEntity } from 'types/User';
import {
    CaseFileAttribute,
    CaseFileEntity,
    CaseFileType,
} from 'types/CaseFile';
import { CasefileMessageValidation } from 'types/DataValidation';
import {
    EmailTemplate,
    EmailTemplateGroup,
    EmailTemplates,
    TemplateEntities,
    TemplateEntityKind,
    TemplateEntityName,
    TemplateType,
} from 'types/EmailTemplates';

// Components
import SendingCasefile from './SendingCasefile';
import CasefileProgressBar from './CasefileProgressBar';
import CasefileDetailsTable from 'Casefiles/components/casefiles/CasefileDetailsTable';
import CasefileDocumentPreviewModal from 'Casefiles/components/casefiles2/CasefileDocumentPreviewModal';

// Stores
import CustomerStore from 'Auth/stores/CustomerStore';
import AuthStore from 'Auth/stores/AuthStore';
import CasefileStore from 'Casefiles/stores/CasefileStore';
import SignerStore from 'Casefiles/stores/SignerStore';
import DocumentStore from 'Casefiles/stores/DocumentStore';
import CasefileNavigationStore from 'Casefiles/stores/CasefileNavigationStore';

// Actions
import CasefileNavigationActions from 'Casefiles/actions/CasefileNavigationActions';
import CasefileActions from 'Casefiles/actions/CasefilesV2ActionCreators';
import SignerActions from 'Casefiles/actions/SignerActionCreators';
import DocumentActions from 'Casefiles/actions/DocumentActionCreators';

import { getUserEmailTemplates } from 'EmailTemplates/redux/actions';
import {
    resetEmailTemplates,
    setInitialEmailTemplates,
    updateEmailTemplate,
} from 'Casefiles/redux/newCaseFile/actions';

import LaunchDarkly, { Flags } from 'Common/LaunchDarkly';
import Button from 'Common/components/Button';
import { formatSSN } from 'utils';

import {
    Client,
    mapCasefileWithClient,
    resetClientState,
} from 'AuditingAccounting';
import {
    EmailMessage,
    NewCasefileState,
} from 'Casefiles/redux/newCaseFile/types';
import { i18nCustomLanguage } from 'Language/utils';
import { EmailTemplatesState } from 'EmailTemplates/redux/reducer';
import { RecipientSigner } from 'types/Recipient';
import { fetchCustomer } from 'Auth/redux/customer/actions';
import { CustomerEntity } from 'types/Customer';

import { translationsPreservedSpaces } from 'Language';
import { aggregateEmailMessages } from 'Casefiles/utils';
import {
    resetNewClientCreation,
    saveClientLinks,
    setNewClientCreation,
} from 'Clients/redux';
import { ClientLinkEntityType, ClientLinks } from 'Clients/types';

import { images } from 'Constants';
import { ReactRouter } from 'types/Router';
import {
    createTemporarySendAt,
    deleteTemporarySendAt,
    updateTemporarySendAt,
} from './utils';
import { resetRounds } from './CasefileRounds/redux';
import { RolesAndActivation } from './CasefileRounds/types';
import { CasefileRoundsExitModal } from './CasefileRounds';

// Retrieve application state from store
function getAppState(action?: string) {
    let casefile = CasefileStore.getCasefile();

    return {
        // Casefile
        casefile: casefile,

        // Conditional Signing Options (Board Sign Count)
        conditionalSigningOptions: CasefileStore.getConditionalSigningOptions(),

        // All fetched casefile data
        fetchedCasefileData: CasefileStore.getFetchedCasefileData(),

        // Validation V2
        navigationRoutes: CasefileNavigationStore.getRoutes(),
        casefileValidation: CasefileStore.getValidation(),
        documentValidation: DocumentStore.getValidation(),
        signerValidation: SignerStore.getValidation(),

        folders: CasefileStore.getFolders(),
        casefileTypes: CasefileStore.getCasefileTypes(),

        // Store folder
        selectedFolderId: CasefileStore.getSelectedFolder(),

        // Documents
        documents: DocumentStore.getDocuments(),
        selectedDocumentTypes: DocumentStore.getDocumentTypes(),
        availableDocumentTypes: DocumentStore.getAvailableDocumentTypes(),
        previewDocumentId: null,
        options: DocumentStore.getDocumentOptions(),

        // Signers & Copy Recipients
        recipients: SignerStore.getSigners(),
        availableSignerTypes: SignerStore.getAvailableSignerTypes(),

        // Date
        sendAtDate: CasefileStore.getSendAtDate(),
        sendLater: CasefileStore.getSendLater(),
        expireAtDate: CasefileStore.getExpireAtDate(),
        expireEnable: CasefileStore.getExpireEnable(),

        // Store Status
        isFetching: CasefileStore.isFetching(),
        error: CasefileStore.getError(),
        action: action,

        // View Actions
        sending: CasefileStore.getStates().sending,
        sent: CasefileStore.getStates().sent,

        // Exiting flow?
        exiting: false,
        nextRoute: undefined,
    };
}

type Props = {
    dispatch: Function;
    casefileEmailTemplates: EmailTemplates;
    emailTemplatesValidation: CasefileMessageValidation;
    userEmailTemplates: EmailTemplate[];
    user: UserEntity;
    location: any;
    emailMessages: NewCasefileState['emailMessages'];
    route: any;
    router: ReactRouter;
    params: any;
    children: any;
    addedRecipientsCount: number;
    defaultTemplates: {
        [TemplateType.COMPLETED]: number | false;
        [TemplateType.COPY_RECIPIENT]: number | false;
        [TemplateType.REMINDER]: number | false;
        [TemplateType.SIGNER]: number | false;
    };
    emailTemplates: EmailTemplatesState['templates'];
    selectedClient?: Client;
    currentLinks: ClientLinks | null;
    newClientCreation: string | null;
    rounds: RolesAndActivation[];
};

/* CasefileContainer serves as a Controller View component
 * It listens to change events and retrieve Application state from Stores.
 * It then passes that data down to its child components via props.
 */
class CasefileContainer extends React.Component<Props> {
    static contextTypes = {
        router: PropTypes.object.isRequired,
    };

    /**
     * Use getAppState func to set initial state
     * NOTE: if there is anything else we need to add to the initial
     * state, append it afterwards. This is to prevent 'wiping' it
     * every time there's a change on the Flux store and
     * getAppState is invoked to replace the current state
     */

    state = getAppState();

    clearStores = () => {
        const { dispatch } = this.props;

        CasefileActions.clearStore();
        // CasefileActions.clearStore doesn't reset the collection of folders fetched
        // If this isn't reset, selecting the folder on the second time this page loads
        // Doesn't work because the application relies on caching a folder for selection
        // until the folders have been fetched but they're already there.

        // Resetting folders can't be added to `clearStore` because the desktop app
        // Never unmounts the `CasefileContainer` component and the folders wouldn't be
        // re-downloaded every single time the user starts sending a new case.
        CasefileActions.clearFolders();
        SignerActions.clearStore();
        DocumentActions.clearStore();
        dispatch(resetEmailTemplates());

        if (LaunchDarkly.variation(Flags.EXPERIMENT_CLIENT_SELECTOR_ENABLED)) {
            dispatch(resetClientState());
        }

        // Reset signing rounds scheduling
        if (LaunchDarkly.variation(Flags.ACTIVATION_DATE_PER_ROUND)) {
            dispatch(resetRounds());
        }
    };

    async componentDidMount() {
        const { casefileId, action } = this.props.params;
        const { query } = this.props.location;
        const isDraft = typeof casefileId !== undefined && action === 'draft';
        const hasIntegrationPayload = query.integrationPayload;

        // Set initial store data
        this.clearStores();

        // Track current route on mount
        let route = this.getCurrentRoute();

        this.trackNavigation(route.name);

        if (hasIntegrationPayload) {
            await this.handleIntegrationData(query.integrationPayload);
        }

        // Custom Actions
        const {
            CREATE_CASEFILE_SUCCESS,
            SAVE_DRAFT_SUCCESS,
        } = Constants.ActionTypes;

        // Attach Change Listeners
        CasefileStore.addChangeListener(this._onChange);
        SignerStore.addChangeListener(this._onChange);
        DocumentStore.addChangeListener(this._onChange);
        CasefileNavigationStore.addChangeListener(this._onChange);

        // Attach Custom Listeners
        CasefileStore.addEventListener(
            CREATE_CASEFILE_SUCCESS,
            this._onCasefileSent
        );
        CasefileStore.addEventListener(SAVE_DRAFT_SUCCESS, this._onDraftSaved);

        await this.loadData();

        // We load the draft data after loading the boostrapping data (folders, templates, etc).
        if (isDraft) {
            await CasefileActions.fetchCasefileDraft(casefileId);
        }

        /**
         * Prevent leaving page by mistake or without saving
         */
        this.props.router.setRouteLeaveHook(this.props.route, (next) => {
            // Certain redirections happen when sending a casefile or saving draft
            const {
                SAVE_DRAFT_REQUEST,
                CREATE_CASEFILE_REQUEST,
            } = Constants.ActionTypes;

            const actionWhitelist = [
                SAVE_DRAFT_REQUEST,
                SAVE_DRAFT_SUCCESS,
                CREATE_CASEFILE_REQUEST,
                CREATE_CASEFILE_SUCCESS,
            ];

            /**
             * If we have already prevented exiting this page or the redirection
             * is due to a whitelisted action
             */
            if (
                this.state.exiting ||
                actionWhitelist.includes(this.state.action)
            ) {
                return true;
            }

            this.setState({ exiting: true, nextRoute: next });

            return false;
        });
    }

    // Unbind change listener
    componentWillUnmount() {
        this.clearStores();

        // Custom Actions
        const {
            CREATE_CASEFILE_SUCCESS,
            SAVE_DRAFT_SUCCESS,
        } = Constants.ActionTypes;

        // Remove Listeners
        CasefileStore.removeChangeListener(this._onChange);
        SignerStore.removeChangeListener(this._onChange);
        DocumentStore.removeChangeListener(this._onChange);
        CasefileNavigationStore.removeChangeListener(this._onChange);

        // Remove Custom Listeners
        CasefileStore.removeEventListener(
            CREATE_CASEFILE_SUCCESS,
            this._onCasefileSent
        );
        CasefileStore.removeEventListener(
            SAVE_DRAFT_SUCCESS,
            this._onDraftSaved
        );

        // Hide any active notifications
        notify.hide();
        modal.hide();
    }

    componentDidUpdate(prevProps, prevState) {
        const { params, dispatch } = this.props;

        // Track route change / scroll to top
        if (this.props.location.pathname !== prevProps.location.pathname) {
            // Scroll to top of next section when updating routes
            let elem = document.getElementById('dashboard-main-content');

            if (elem) {
                elem.scrollTo // not compatible with IE
                    ? elem.scrollTo(0, 0)
                    : (elem.scrollTop = 0); // compatible with IE
            }

            let route = this.getCurrentRoute();

            this.trackNavigation(route.name);

            CasefileNavigationActions.trackNavigation(route.name);
        }

        // If action is new and previous was not then get the UI into the default state
        // or if the case file was sent and user wants to create another case file then
        // also get the UI into the default state
        if (
            (params.action !== prevProps.params.action &&
                params.action === 'new') ||
            (prevState.sent && !this.state.sent)
        ) {
            this.clearStores();
            this.loadData();

            return;
        }

        // If action is draft and previous was not then fetch the draft by id
        if (
            params.action !== prevProps.params.action &&
            params.action === 'draft'
        ) {
            CasefileActions.fetchCasefileDraft(params.casefileId);

            return;
        }

        // When a casefile draft is loaded, update email templates if signing request is present
        if (
            !LaunchDarkly.variation(Flags.SIGNER_SPECIFIC_EMAILS) &&
            !prevState.fetchedCasefileData.emailTemplates &&
            this.state.fetchedCasefileData.emailTemplates
        ) {
            this.updateFetchedTemplates(
                this.state.fetchedCasefileData.emailTemplates
            );
        }

        // If casefile is a draft and templateEntities are fetched, get initial
        // email messages based on templateEntities
        if (
            LaunchDarkly.variation(Flags.SIGNER_SPECIFIC_EMAILS) &&
            params.action === 'draft' &&
            !prevState.fetchedCasefileData.templateEntities &&
            this.state.fetchedCasefileData.templateEntities
        ) {
            dispatch(
                setInitialEmailTemplates(
                    getInitialMessages(
                        this.props.defaultTemplates,
                        this.props.emailTemplates,
                        this.state.fetchedCasefileData.templateEntities,
                        this.state.fetchedCasefileData.signers
                    )
                )
            );
        }

        // If casefile is not a draft, set initial messages based on user's
        // default template
        if (
            LaunchDarkly.variation(Flags.SIGNER_SPECIFIC_EMAILS) &&
            params.action !== 'draft' &&
            !this.props.emailMessages.isLoaded &&
            this.props.emailTemplates.length > 0
        ) {
            dispatch(
                setInitialEmailTemplates(
                    getInitialMessages(
                        this.props.defaultTemplates,
                        this.props.emailTemplates,
                        this.state.fetchedCasefileData.templateEntities,
                        this.state.fetchedCasefileData.signers
                    )
                )
            );
        }
    }

    updateFetchedTemplates = (emailTemplates) => {
        const { dispatch } = this.props;

        Object.keys(emailTemplates).forEach((type) => {
            const template = emailTemplates[type];

            dispatch(
                updateEmailTemplate(type as TemplateType, {
                    id: null,
                    title: null,
                    subject: template.subject,
                    message: template.message,
                    custom: true,
                    default: template.default,
                })
            );
        });
    };

    getCurrentRoute = () => {
        let { router } = this.context;
        let { route, params } = this.props;

        return route.childRoutes.filter((r) =>
            router.isActive({ name: r.name, params: params })
        )[0];
    };

    trackNavigation = (routeName) => {
        analytics.track('casefile - navigation', {
            target: routeName,
        });
    };

    loadData = async () => {
        const { dispatch } = this.props;

        // Always fetch the tpls in english so the default one can be translated
        // on the fly if the user changes language for the case file during the
        // creation process
        await dispatch(
            getUserEmailTemplates(EmailTemplateGroup.CASEFILE, Languages.EN)
        );

        CasefileActions.fetchCasefileTypes();
        CasefileActions.fetchFolders();
        CasefileActions.fetchDefaultFolder();

        // Set initial case file sensitivity
        const customer = CustomerStore.getCustomer(
            AuthStore.getAuthDetails().cid
        ) as CustomerEntity;

        if (customer) {
            CasefileActions.setSensitiveData(customer.accessControl);
            CasefileActions.updateCasefile({ language: customer.language });
            await translationsPreservedSpaces.changeLanguage(customer.language);

            // Fetch customer data (on Redux)
            dispatch(fetchCustomer(customer.id));
        }

        // Set initial document visibility
        CasefileActions.updateCasefile({ visibilityMode: 0 });

        // Skip setting folder from settings if casefile is a draft.
        if (this.props.params.action === 'draft') {
            return;
        }

        // Set target folder based on user settings. (falls back to default folder if not available)
        await CasefileActions.changeFolderToLastUsed();
    };

    async handleIntegrationData(payload: string) {
        try {
            const documentsFailed = await CasefileActions.fetchIntegrationData(
                payload
            );

            if (documentsFailed && documentsFailed.length > 0) {
                this.removeFailedDocuments(documentsFailed);
                this.notifyAboutFailedDocumentUploads(documentsFailed);
            } else {
                notify.show(
                    <span>{i18n`Data was successfully imported!`}</span>,
                    'success',
                    3000
                );
            }
        } catch (error) {
            this.notifyAboutFailedIntegrationDataImport(error);
        } finally {
            // Once the import is handled, there is no use to
            // keeping the payload query parameter in the url
            this.removeIntegrationPayloadQueryParam();
        }
    }

    notifyAboutFailedIntegrationDataImport(error) {
        const {
            response: { headers },
        } = error;
        const hasAmazonHeaders =
            headers['x-amzn-requestid'] && headers['x-amzn-trace-id'];

        notify.show(
            <div style={{ position: 'relative' }}>
                <span>{i18n`Failed to import data, please try again or contact our support`}</span>
                {hasAmazonHeaders && (
                    <pre className="mb0">
                        <b>{`RequestID`}:</b> {headers['x-amzn-requestid']}
                        <br />
                        <b>{`Trace ID`}:</b> {headers['x-amzn-trace-id']}
                    </pre>
                )}
                {hasAmazonHeaders && (
                    <span
                        style={{ position: 'absolute', top: -5, right: -15 }}
                        onClick={notify.hide}>
                        <i className="far fa-times fa-lg"></i>
                    </span>
                )}
            </div>,
            'error',
            // If there is debug info, keep the notification up
            hasAmazonHeaders ? -1 : 3000
        );
    }

    removeFailedDocuments(documentsFailed) {
        const { documents } = this.state;

        documentsFailed
            .map((failed) =>
                documents.findIndex((doc) => doc._id === failed._id)
            )
            .forEach((docIndex: number) =>
                DocumentActions.removeDocument(docIndex)
            );
    }

    notifyAboutFailedDocumentUploads(documentsFailed) {
        let message = `Could not upload: `;

        documentsFailed.forEach((failedDoc) => {
            message += `${failedDoc.name}, `;
        });
        message = message.substring(0, message.length - 2);
        notify.show(<span>{`${message}`}</span>, 'error', 2000);
    }

    removeIntegrationPayloadQueryParam() {
        const location = Object.assign({}, browserHistory.getCurrentLocation());

        delete location.query.integrationPayload;

        browserHistory.replace(location);
    }

    displayCasefileDetails = () => {
        const details = this.getCasefileTypeDetails();

        if (!details) {
            return;
        }

        const config = {
            className: 'full-screen penneo-casefiles',
            title: i18n`Case file details`,
            body: <CasefileDetailsTable casefile={details} />,
            buttons: (
                <Button onClick={modal.hide} theme="blue">
                    {i18n`Close`}
                </Button>
            ),
        };

        modal.show(config);
    };

    getCasefileTypeDetails = (): CaseFileType | undefined => {
        const { casefile } = this.state;

        if (!casefile.caseFileTypeId) {
            return;
        }

        return CasefileStore.getCaseTypeById(casefile.caseFileTypeId);
    };

    getUploadedDocuments = () => {
        const { documents } = this.state;

        return lodash.filter(documents, (doc) => doc.id);
    };

    openDocumentPreview = (documentId) => {
        this.setState({ previewDocumentId: documentId });
    };

    closeDocumentPreview = () => {
        this.setState({ previewDocumentId: null });
    };

    // Update view state when change event is received
    _onChange = (action) => {
        this.setState(getAppState(action));
    };

    // *************************************************************************
    // CASE VALIDATION BEFORE SEND
    // *************************************************************************
    casefileSendValidation = () => {
        this.sendCasefile();
    };

    sendCasefile = async () => {
        let { casefile, fetchedCasefileData } = this.state;
        let data = await this._getCasefileData();

        if (casefile.id) {
            CasefileActions.sendUpdatedCasefile(data, fetchedCasefileData);

            return;
        }

        CasefileActions.sendCasefile(data);
    };

    saveCasefileDraft = async () => {
        let { casefile, fetchedCasefileData } = this.state;

        /**
         * Since we are not retrieving sendAt date from casefile
         * (to prevent sending the casefile if it's present),
         * we save this value in the metadata
         */
        let data = await this._getCasefileData(true);

        if (casefile.id) {
            CasefileActions.updateCasefileDraft(
                data,
                fetchedCasefileData
            ).catch((error) => {
                console.error(error);

                Dispatcher.handleServerAction({
                    type: Constants.ActionTypes.CREATE_CASEFILE_FAILURE,
                    error: error,
                });
            });

            return;
        }

        CasefileActions.createCasefileDraft(data);
    };

    displayDraftConfirmation = (event?: React.MouseEvent) => {
        let { casefile } = this.state;
        const { dispatch, newClientCreation } = this.props;

        event?.preventDefault();

        /**
         * We must check if this is the regular draft prompt
         * ot the one triggered when trying to create a new Client
         */
        const creatingClient =
            LaunchDarkly.variation(Flags.CLIENTS) && newClientCreation;

        if (typeof casefile.title === 'undefined' || casefile.title === '') {
            return notify.show(
                <span>
                    {i18n`Draft needs to have at a title`}{' '}
                    <i className="fa fa-times" />
                </span>,
                'error',
                3000
            );
        }

        let config = {
            title: !creatingClient
                ? i18n`Are you sure?`
                : i18n`Create new client`,
            body: !creatingClient
                ? i18n`The changes you just made to this draft will be saved
                and you will be redirected to the archive. No documents will be sent`
                : i18n`You are about to be redirected to the client creation page. The changes you just made to this draft will be saved before proceeding. No documents will be sent`,
            buttons: (
                <div>
                    <Button
                        onClick={() => {
                            if (creatingClient) {
                                dispatch(resetNewClientCreation());
                            }

                            modal.hide();
                        }}>{i18n`Back`}</Button>
                    <Button theme="green" onClick={this.saveCasefileDraft}>
                        {i18n`Yes, save and continue`}
                    </Button>
                </div>
            ),
            preventClose: true,
        };

        modal.show(config);
    };

    /**
     * This method takes care of new Client creation,
     * calling the save draft functionality to not losing
     * changes done to the current casefile
     */
    handleNewClientCreation = (newClient: string) => {
        this.props.dispatch(setNewClientCreation(newClient));

        /**
         * We add a small delay to displaying the draft modal
         * otherwise the changes to Redux won't be accounted for
         */
        setTimeout(() => this.displayDraftConfirmation(), 300);
    };

    _getCasefileData = async (asDraft = false) => {
        let {
            casefile,
            conditionalSigningOptions,
            documents,
            recipients,
            sendAtDate,
            expireAtDate,
            selectedFolderId,
            options,
        } = this.state;
        const { casefileEmailTemplates, rounds } = this.props;

        /**
         * If it's a draft, we don't send along casefile.sendAt
         * for it would make this casefile be sent, regardless.
         * Instead, we save this setting in the metadata.
         */
        casefile.sendAt = sendAtDate && !asDraft ? sendAtDate.unix() : null;

        casefile.expireAt = expireAtDate ? expireAtDate.unix() : null;

        // Extract signers / copy recipients.
        let signers = recipients.filter((r) => r.type === 'signer');
        let copyRecipients = recipients
            .filter((r) => r.type === 'copyrecipient')
            .map((cr) => ({
                name: cr.name,
                email: cr.email,
                storeAsContact: cr.storeAsContact,
            }));

        signers = signers.map((item) => {
            return {
                ...item,
                ssn: item.ssn ? formatSSN(item.ssn, item.ssnType) : item.ssn,
            };
        });

        // If the casefile type has options, apply them to all documents.
        if (options) {
            documents.forEach((doc) => {
                doc.opts = {};
                doc.opts[options.opts[0].name] = options.opts[0].value;
            });
        }

        DocumentActions.setBoardSignCountOnDocuments(
            conditionalSigningOptions?.enabled ?? false,
            conditionalSigningOptions.count,
            conditionalSigningOptions.role
        );

        let emailMessages = this._normalizeEmailTemplates(
            casefileEmailTemplates
        );

        if (LaunchDarkly.variation(Flags.SIGNER_SPECIFIC_EMAILS)) {
            const translations = await translateAllDefaultTemplates(
                this.props.emailMessages,
                casefile.language
            );

            emailMessages = (translations as unknown) as any;
        }

        if (!LaunchDarkly.variation(Flags.SIGNER_SPECIFIC_EMAILS)) {
            const getEmailMessage = async (
                message: string,
                defaultTemplate: boolean
            ) => {
                return defaultTemplate
                    ? await i18nCustomLanguage(message, casefile.language)
                    : message;
            };

            const {
                completed,
                reminder,
                signing_request,
            } = casefileEmailTemplates;

            emailMessages = {
                emailSubject: await getEmailMessage(
                    signing_request.subject,
                    signing_request.default
                ),
                emailText: await getEmailMessage(
                    signing_request.message,
                    signing_request.default
                ),
                completedEmailSubject: await getEmailMessage(
                    completed.subject,
                    completed.default
                ),
                completedEmailText: await getEmailMessage(
                    completed.message,
                    completed.default
                ),
                reminderEmailSubject: await getEmailMessage(
                    reminder.subject,
                    reminder.default
                ),
                reminderEmailText: await getEmailMessage(
                    reminder.message,
                    reminder.default
                ),
            };
        }

        return {
            casefile: casefile,
            documents: documents,
            signers: signers,
            selectedFolderId: selectedFolderId,
            recipients: copyRecipients,
            emailMessages,
            rounds,
        };
    };

    // TODO: This should be revised and potentially removed once the flag
    // SIGNER_SPECIFIC_EMAILS is permanently turned on for all users
    _normalizeEmailTemplates = (casefileEmailTemplates: EmailTemplates) => {
        const signer = casefileEmailTemplates[TemplateType.SIGNER];
        const completed = casefileEmailTemplates[TemplateType.COMPLETED];
        const reminder = casefileEmailTemplates[TemplateType.REMINDER];

        return {
            emailSubject: signer.subject,
            emailText: signer.message,
            completedEmailSubject: completed.subject,
            completedEmailText: completed.message,
            reminderEmailSubject: reminder.subject,
            reminderEmailText: reminder.message,
        };
    };

    _onCasefileSent = () => {
        const casefile = CasefileStore.getCasefile();
        const { selectedClient } = this.props;
        let { fetchedCasefileData } = this.state;

        analytics.track(
            'casefile - sent',
            this.getTrackingCasefileDetails(casefile)
        );
        amplitude.incrementUserProperty('casefiles sent');

        /**
         * Persisted temporary sendAt date
         *
         * We must make sure to remove the temp value stored
         * in the casefile's meta. Otherwise, it will ignore
         * the one stored in the casefile itself
         */
        const tempSendAt: CaseFileAttribute | null =
            fetchedCasefileData?.temporarySendAt ?? null;

        if (tempSendAt) {
            deleteTemporarySendAt(
                casefile?.casefile?.id ?? casefile?.id,
                tempSendAt.id
            );
        }

        if (
            LaunchDarkly.variation(Flags.EXPERIMENT_CLIENT_SELECTOR_ENABLED) &&
            selectedClient
        ) {
            mapCasefileWithClient(casefile.casefile.id, selectedClient);
        }

        // Save Clients
        if (LaunchDarkly.variation(Flags.CLIENTS)) {
            /**
             * NOTE: there's an inconsistency on the payload of
             * CREATE_CASEFILE_SUCCESS. When it's a completely new
             * casefile, casefile.casefile.id exists.
             * When it's a previously sent draft,
             * casefile.id should be used.
             */
            this.persistClients(casefile?.casefile?.id ?? casefile?.id);
        }

        this.setState({
            casefile,
            sent: true,
        });
    };

    getTrackingCasefileDetails(casefile: CaseFileEntity) {
        const { recipients, documents } = this.state;
        const { selectedClient, addedRecipientsCount } = this.props;

        const signers = recipients.filter((r) => r.type === 'signer');
        const copyRecipients = recipients.filter(
            (r) => r.type === 'copyrecipient'
        );
        const isClientSelectorFeatureEnabled: boolean = LaunchDarkly.variation(
            Flags.EXPERIMENT_CLIENT_SELECTOR_ENABLED
        );

        const hasActivationDateOnAnySigner = signers.some((signer) =>
            signer.role.some((role) => role.activeAt)
        );

        return {
            id: casefile.id,
            caseFileTypeId: casefile.caseFileTypeId,
            sensitiveData: !!casefile.sensitiveData,
            signOnMeeting: !!casefile.signOnMeeting,
            visibilityMode: casefile.visibilityMode,
            language: casefile.language,
            expireAt: casefile.expireAt,
            sendAt: casefile.sendAt,
            documentCount: documents.length,
            signerCount: signers.length,
            copyRecipientCount: copyRecipients.length,
            hasActivationDateOnAnySigner,
            ...(isClientSelectorFeatureEnabled && {
                clientVatin: selectedClient?.vatin,
                signersAddedViaSuggestion: addedRecipientsCount,
            }),
            ...(LaunchDarkly.variation(Flags.SIGNER_SPECIFIC_EMAILS) &&
                aggregateEmailMessages(this.props.emailMessages)),
        };
    }

    persistClients = (casefileId: number) => {
        const { currentLinks, dispatch } = this.props;

        /**
         * There's a delay in which this component is caching the Redux
         * store, that might contain the Clients from a previous casefile.
         * Here we prevent doing anything if the id's don't match
         * (and, if there's no previous id, we proceed anyway)
         */
        if (
            !currentLinks?.linkEntity?.entityId ||
            currentLinks?.linkEntity?.entityId === casefileId
        ) {
            dispatch(
                saveClientLinks({
                    entityType: ClientLinkEntityType.casefile,
                    entityId: casefileId,
                })
            );
        }
    };

    _onDraftSaved = () => {
        let { sendAtDate, fetchedCasefileData, casefile } = this.state;
        const casefileDetails = this.getTrackingCasefileDetails(casefile);

        analytics.track('casefile - draft saved', casefileDetails);

        /**
         * Persist temporary sendAt date
         *
         * When it's a 'legacy' date (stored directly on the casefile)
         * create new and remove it from the casefile.
         * Otherwise, update existing one or delete it,
         * if it's been cleared.
         */
        const tempSendAt: CaseFileAttribute | null =
            fetchedCasefileData?.temporarySendAt ?? null;

        if (sendAtDate) {
            if (!tempSendAt) {
                createTemporarySendAt(casefileDetails.id, sendAtDate.unix());
            } else {
                updateTemporarySendAt(
                    casefileDetails.id,
                    sendAtDate.unix(),
                    tempSendAt
                );
            }
        } else {
            if (tempSendAt) {
                deleteTemporarySendAt(casefileDetails.id, tempSendAt.id);
            }
        }

        // Save Clients
        if (LaunchDarkly.variation(Flags.CLIENTS)) {
            const { newClientCreation } = this.props;

            this.persistClients(casefileDetails.id);

            /**
             * Check if we are creating a new Client
             * and redirect there instead of 'drafts
             */
            if (newClientCreation) {
                this.context.router.push({
                    name: 'client-creation',
                    search: `?name=${newClientCreation}`,
                });

                return;
            }
        }

        this.context.router.push({
            name: 'archive-virtual',
            params: {
                tab: 'drafts',
            },
        });
    };

    saveAsDraftButton = () => (
        <Button
            theme="gray"
            variant="outline"
            onClick={this.displayDraftConfirmation}
            className="mr">
            {i18n('Save as draft')}
        </Button>
    );

    renderError(error) {
        if (error?.message === 'Unauthorized' || error?.status === 403) {
            return (
                <div className="casefile-details ui-v2">
                    <div className="casefile-error text-center">
                        <h2 className="code">[Code: 403]</h2>
                        <img
                            src={`${images}/graphics/security.png`}
                            height="300"
                            alt=""
                        />
                        <h1>{i18n`You don't have access to this case`}</h1>

                        <Button
                            theme="blue"
                            onClick={() => this.props.router.goBack()}>
                            {i18n`Back to archive`}
                        </Button>
                    </div>
                </div>
            );
        }

        return (
            <div className="casefile-details ui-v2">
                <div className="casefile-error text-center">
                    <img
                        src={`${images}/graphics/automated.png`}
                        height="300"
                        alt=""
                    />
                    <h1>{i18n`An unexpected error occurred`}</h1>

                    <Button
                        theme="blue"
                        onClick={() => window.location.reload()}>
                        {i18n`Reload page`}
                    </Button>
                </div>
            </div>
        );
    }

    /**
     * Get loading state based on casefile creation/editing flow
     */
    isLoading() {
        const { action } = this.props.params;
        const { isFetching, casefile } = this.state;

        // If draft, the view is loading until the casefile
        // has been successfully fetched
        if (action === 'draft' && !casefile.id) {
            return true;
        }

        // If we're creating a new casefile, we wait until
        // the bootstrapping data (this.loadData()) has finished.
        if (action === 'new') {
            return isFetching;
        }

        return false;
    }

    /**
     * Prevent navigating out of the casefile editing/creation flow
     */
    handleOnFlowExit = () => this.setState({ exiting: !this.state.exiting });

    render() {
        const { children, params, user } = this.props;
        const previewDocumentId = this.state.previewDocumentId;
        const uploadedDocuments = this.getUploadedDocuments();
        const props = assign(this.state, {
            user,
            clearStores: this.clearStores,
            sendCasefile: this.sendCasefile,
            saveCasefileDraft: this.displayDraftConfirmation,
            showCasefileDetails: this.displayCasefileDetails,
            linkParams: params,
            saveAsDraftButton: this.saveAsDraftButton,
            openDocumentPreview: this.openDocumentPreview,
            uploadedDocuments,
            emailMessages: this.props.emailMessages,
            ...(LaunchDarkly.variation(Flags.CLIENTS) && {
                handleNewClientCreation: this.handleNewClientCreation,
            }),
        });

        const { error } = props;

        // Handle casefile draft fetching error
        if (
            error &&
            error.type === Constants.ActionTypes.FETCH_CASEFILE_DRAFT_FAILURE
        ) {
            return this.renderError(error);
        }

        if (this.state.sending) {
            return <SendingCasefile {...props} loadData={this.loadData} />;
        }

        if (this.isLoading()) {
            return <Loader />;
        }

        return (
            <div>
                <Helmet>
                    <title>
                        {this.props.params.action === 'draft'
                            ? i18n`Edit case file`
                            : i18n`New case file`}
                    </title>
                </Helmet>

                {LaunchDarkly.variation(Flags.ACTIVATION_DATE_PER_ROUND) &&
                    this.state.exiting && (
                        <CasefileRoundsExitModal
                            handleCloseModal={this.handleOnFlowExit}
                            router={this.props.router}
                            nextRoute={this.state.nextRoute}
                            saveCasefileDraft={this.saveCasefileDraft}
                        />
                    )}

                <div>
                    <CasefileProgressBar {...props} />

                    {previewDocumentId && (
                        <CasefileDocumentPreviewModal
                            id={previewDocumentId}
                            uploadedDocuments={uploadedDocuments}
                            onOpen={this.openDocumentPreview}
                            onClose={this.closeDocumentPreview}
                        />
                    )}

                    {React.cloneElement(children, props)}
                </div>
            </div>
        );
    }
}

async function translateAllDefaultTemplates(
    emailMessages: NewCasefileState['emailMessages'],
    language: Languages
) {
    await translationsPreservedSpaces.changeLanguage(language);
    // We need to clone 'emailMessages' because it's immutable

    const messagesClone: NewCasefileState['emailMessages'] = lodash.cloneDeep(
        emailMessages
    );

    // Translate all case file specific messages if the property 'defualt' is
    // set to true
    for (const messageKind in messagesClone.general.messages) {
        const message = messagesClone.general.messages[messageKind];

        if (message?.default) {
            message.subject.content = await i18nCustomLanguage(
                message.subject.content,
                language
            );
            message.body.content = await i18nCustomLanguage(
                message.body.content,
                language
            );
        }
    }

    // Translate all signing request specific messages if the property 'defualt'
    // is set to true
    for (const customMessage of messagesClone.custom) {
        for (const customMessageKind in customMessage.messages) {
            const message: EmailMessage =
                customMessage.messages[customMessageKind];

            if (message.default) {
                message.subject.content = await i18nCustomLanguage(
                    message.subject.content,
                    language
                );
                message.body.content = await i18nCustomLanguage(
                    message.body.content,
                    language
                );
            }
        }
    }

    return messagesClone;
}

function getInitialMessages(
    defaultTemplates: Props['defaultTemplates'],
    emailTemplates: Props['emailTemplates'],
    templateEntities?: TemplateEntities,
    signers?: RecipientSigner[]
): {
    generalMessages: NewCasefileState['emailMessages']['general']['messages'];
    customMessages: {
        messages: NewCasefileState['emailMessages']['custom'][number]['messages'];
        recipients: NewCasefileState['emailMessages']['custom'][number]['recipients'];
    }[];
} {
    const { CASEFILE, SIGNING_REQUEST } = TEMPLATE_ENTITY_NAME;

    function findTemplateEntities(templateEntityName: TemplateEntityName) {
        return templateEntities?.templates.filter((message) =>
            message.entityRelations.find(
                (relation) => relation.name === templateEntityName
            )
        );
    }

    const casefileTemplateEntities = findTemplateEntities(CASEFILE);
    const signerTemplateEntities = findTemplateEntities(SIGNING_REQUEST);

    return {
        generalMessages: getInitialGeneralMessages(
            defaultTemplates,
            emailTemplates,
            casefileTemplateEntities
        ),
        customMessages: getInitialCustomMessages(
            signerTemplateEntities,
            signers
        ),
    };
}

function getInitialCustomMessages(
    templateEntities?: TemplateEntities['templates'],
    signers?: RecipientSigner[]
) {
    if (!signers || signers.length < 1 || !templateEntities) return [];

    const subjectTemplateEntities = templateEntities.filter(
        (message) => message.kind === TEMPLATE_ENTITY_KIND.REQUEST_SUBJECT
    );

    const bodyTemplateEntities = templateEntities.filter(
        (message) => message.kind === TEMPLATE_ENTITY_KIND.REQUEST_BODY
    );

    const customMessagesResult: {
        messages: NewCasefileState['emailMessages']['custom'][number]['messages'];
        recipients: NewCasefileState['emailMessages']['custom'][number]['recipients'];
    }[] = [];

    const customBodiesToSplice: number[] = [];
    const customSubjectsToSplice: number[] = [];

    subjectTemplateEntities.forEach((customSubject, customSubjectIndex) => {
        const matchedBodyIndex = bodyTemplateEntities.findIndex(
            (customBody) => {
                return hasSameSigningRequestIds(
                    customSubject.entityRelations[0].ids,
                    customBody.entityRelations[0].ids
                );
            }
        );

        if (matchedBodyIndex > -1) {
            const mergedMessage: EmailMessage = {
                title: '',
                subject: { content: customSubject.content },
                body: {
                    content: bodyTemplateEntities[matchedBodyIndex].content,
                },
                default: false,
            };

            const recipients = signers.filter((signer) => {
                if (signer.signingRequestId === undefined) return false;

                return customSubject.entityRelations[0].ids.includes(
                    signer.signingRequestId
                );
            });

            customMessagesResult.push({
                messages: { [TemplateType.SIGNER]: mergedMessage },
                recipients,
            });

            customSubjectsToSplice.push(customSubjectIndex);
            customBodiesToSplice.push(matchedBodyIndex);
        }
    });

    const filteredCustomSubjects = subjectTemplateEntities.filter(
        (item, index) => {
            return !customSubjectsToSplice.includes(index);
        }
    );

    const filteredCustomBodies = bodyTemplateEntities.filter((item, index) => {
        return !customBodiesToSplice.includes(index);
    });

    filteredCustomSubjects.forEach((messageSubject) => {
        const customMessage = {
            messages: {
                [TemplateType.SIGNER]: {
                    body: { content: '' },
                    default: false,
                    subject: { content: messageSubject.content },
                    title: '',
                },
            },
            recipients: messageSubject.entityRelations[0].ids.map(
                (signingRequestId) =>
                    signers.find(
                        (signer) => signer.signingRequestId === signingRequestId
                    )
            ) as RecipientSigner[],
        };

        customMessagesResult.push(customMessage);
    });

    filteredCustomBodies.forEach((messageBody) => {
        const customMessage = {
            messages: {
                [TemplateType.SIGNER]: {
                    body: { content: messageBody.content },
                    default: false,
                    subject: { content: '' },
                    title: '',
                },
            },
            recipients: messageBody.entityRelations[0].ids.map(
                (signingRequestId) =>
                    signers.find(
                        (signer) => signer.signingRequestId === signingRequestId
                    )
            ) as RecipientSigner[],
        };

        customMessagesResult.push(customMessage);
    });

    return customMessagesResult;
}

function getInitialGeneralMessages(
    defaultTemplates: Props['defaultTemplates'],
    emailTemplates: Props['emailTemplates'],
    templateEntities?: TemplateEntities['templates']
): NewCasefileState['emailMessages']['general']['messages'] {
    const {
        COMPLETED_BODY,
        COMPLETED_SUBJECT,
        REMINDER_BODY,
        REMINDER_SUBJECT,
        REQUEST_BODY,
        REQUEST_SUBJECT,
    } = TEMPLATE_ENTITY_KIND;

    function findTemplateEntity(entityKind: TemplateEntityKind) {
        const entity = templateEntities?.find(
            (message) => message.kind === entityKind
        );

        if (!entity) return;

        return { content: entity.content };
    }

    function mergeEntityMessages(
        subject?: { content: string },
        body?: { content: string }
    ) {
        if (subject || body) {
            return {
                default: false,
                body: { content: body?.content || '' },
                subject: { content: subject?.content || '' },
                title: '',
            };
        }

        return undefined;
    }

    function findUserDefaultMessage(templateType: TemplateType) {
        return emailTemplates.find(
            (template) => template.id === defaultTemplates[templateType]
        );
    }

    function findGlobalDefaultMessage(templateType: TemplateType) {
        return emailTemplates.find(
            (template) => template.type === templateType
        );
    }

    function convertTemplateToEmail(emailTemplate?: EmailTemplate) {
        if (!emailTemplate) return;

        return {
            body: { content: emailTemplate.message },
            customerId: emailTemplate.customerId,
            default: emailTemplate.default || false,
            id: emailTemplate.id!,
            subject: { content: emailTemplate.subject },
            title: emailTemplate.title!,
            userId: emailTemplate.userId,
        };
    }

    const parsedTemplateEntities = templateEntities
        ? {
              completedBody: findTemplateEntity(COMPLETED_BODY),
              completedSubject: findTemplateEntity(COMPLETED_SUBJECT),
              reminderBody: findTemplateEntity(REMINDER_BODY),
              reminderSubject: findTemplateEntity(REMINDER_SUBJECT),
              signingRequestBody: findTemplateEntity(REQUEST_BODY),
              signingRequestSubject: findTemplateEntity(REQUEST_SUBJECT),
          }
        : undefined;

    const mergedTemplateEntities = {
        request: mergeEntityMessages(
            parsedTemplateEntities?.signingRequestSubject,
            parsedTemplateEntities?.signingRequestBody
        ),
        completed: mergeEntityMessages(
            parsedTemplateEntities?.completedSubject,
            parsedTemplateEntities?.completedBody
        ),
        reminder: mergeEntityMessages(
            parsedTemplateEntities?.reminderSubject,
            parsedTemplateEntities?.reminderBody
        ),
    };

    const userDefaultMessages = {
        request: convertTemplateToEmail(
            findUserDefaultMessage(TemplateType.SIGNER)
        ),
        reminder: convertTemplateToEmail(
            findUserDefaultMessage(TemplateType.REMINDER)
        ),
        completed: convertTemplateToEmail(
            findUserDefaultMessage(TemplateType.COMPLETED)
        ),
    };

    const globalDefaultMessages = {
        request: convertTemplateToEmail(
            findGlobalDefaultMessage(TemplateType.SIGNER)
        ),
        reminder: convertTemplateToEmail(
            findGlobalDefaultMessage(TemplateType.REMINDER)
        ),
        completed: convertTemplateToEmail(
            findGlobalDefaultMessage(TemplateType.COMPLETED)
        ),
    };

    return {
        [TemplateType.SIGNER]:
            mergedTemplateEntities.request ||
            userDefaultMessages.request ||
            globalDefaultMessages.request!,
        [TemplateType.COMPLETED]:
            mergedTemplateEntities.completed ||
            userDefaultMessages.completed ||
            globalDefaultMessages.completed!,
        [TemplateType.REMINDER]:
            mergedTemplateEntities.reminder ||
            userDefaultMessages.reminder ||
            globalDefaultMessages.reminder!,
    };
}

function hasSameSigningRequestIds(ids1: number[], ids2: number[]): boolean {
    return ids1.sort().join(',') === ids2.sort().join(',');
}

export default connect((state: ReduxState) => ({
    userEmailTemplates: state.email.templates || [],
    casefileEmailTemplates: state.newCasefile.emailTemplates,
    emailTemplatesValidation: state.newCasefile.validation.emailTemplates,
    selectedClient: state.auditingAccounting.selectedClient,
    addedRecipientsCount: state.auditingAccounting.addedRecipientsCount,
    emailMessages: state.newCasefile.emailMessages,
    emailTemplates: state.email.templates,
    defaultTemplates: state.settings.data.user.defaultEmailTemplates.casefile,
    currentLinks: state.clients.currentLinks,
    newClientCreation: state.clients.newClientCreation,
    rounds: state.rounds.rolesAndActivation,
}))(CasefileContainer);
