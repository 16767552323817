import { jwtDecode } from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import Store from '../../stores/TouchStore';
import TouchActions from '../../actions/TouchActions';
import Loader from 'Common/components/Common/Loader';
import { TouchActions as Actions } from '../../ActionTypes';
import { i18n } from 'Language';
import Button from 'Common/components/Button';
import analytics from 'Common/Analytics';
import { InsecureSigningMethod } from 'types/Signer';
import SignaturePad from 'Common/components/SignaturePad';
import ImageUpload from 'Common/components/VisualSignature/ImageUpload';
import TextSignature from 'Common/components/VisualSignature/TextSignature';
import PostSignStorageOptions from 'Common/components/StorageOptions/PostSignStorageOptions';
import { V2Validation } from 'Signing/utils';

type Props = {
    challengeKey: string;
    languageCode: string;
    insecureMethod: InsecureSigningMethod;
};

const InsecureSignature = ({
    challengeKey,
    languageCode,
    insecureMethod,
}: Props) => {
    const nameAndAddressProtectionSaving = window.location.href.includes(
        'name-address-protection'
    );

    const [signText, setSignText] = useState<string | null>(null);
    const [base64image, setBase64image] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<Error | null>(null);
    const [saveState, setSaveState] = useState<
        'signing' | 'account-selection' | 'saved'
    >('signing');

    const loadData = () => TouchActions.init(challengeKey, languageCode);

    const onChange = () => {
        setSignText(Store.getSignText());
        setLoading(Store.isFetching());
        setBase64image(Store.getSignatureImage());
        setError(Store.getError());
    };

    const onSuccess = () => {
        setSaveState('saved');
    };

    const retry = () => {
        TouchActions.retry();
        loadData();
    };

    useEffect(() => {
        /**
         * We attach listeners to the Flux store
         * that will run actions to fetch/sign with image data.
         * Below we make sure to remove the listeners and reset the store
         * when component is unmounted.
         */
        Store.addChangeListener(onChange);
        Store.addEventListener(Actions.SIGN_SUCCESS, onSuccess);
        loadData();

        // React calls the effect function twice during tests and devel. Because of this, it sometimes happens that the
        // cleanup function is called before the store gets a chance to emit an update.
        // To be more clear, the call order can be as follows:
        //  1. effect() gets called       => event listeners registered
        //  2. cleanup() gets called      => event listeners unregistered
        //  3. events get triggered, but the component is not listening
        //  4. effect() gets called       => event listeners registered again
        //
        // In production, this does not really happen, but in order to get our tests to be robust, we're gonna schedule
        // an update on the next tick. Normally, because we add [] as the dependency parameter to useEffect(), it would
        // be ok to call setState() inside of useEffect(), but to avoid a huge code small, and a potential trap for
        // future developers, we'll use this instead.
        //
        // The proper fix for this would be moving everything to a redux store.
        const updaterTimeout = setTimeout(onChange);

        return () => {
            clearTimeout(updaterTimeout);

            Store.removeChangeListener(onChange);
            Store.removeEventListener(Actions.SIGN_SUCCESS, onSuccess);
            TouchActions.clearStore();
        };
    }, []);

    const updateSignatureImage = (base64image) =>
        TouchActions.updateSignatureImage(base64image);

    const save = () => {
        analytics.track('Touch signature - Click "Sign Documents" button', {
            method: insecureMethod,
        });

        TouchActions.sign(challengeKey, base64image);
    };

    const processNapSign = () => {
        const userIds = getValidationSessionUserIds();

        if (
            // without signer id validation data, we can't show any archives to save to
            userIds === 'no-session' ||
            // We don't have a way of creating new users without an EID signature atm, we decided to cut corners
            // in this scenario. We can work on it if we discover people actually need it.
            userIds.length === 0
        ) {
            saveWithNAP();

            return;
        }

        setSaveState('account-selection');

        return;
    };

    /**
     * We introduced this method called saveWithNAP to allow us to sign with the name and address protection image signature endpoint
     */
    const saveWithNAP = (
        disposable: boolean = true,
        userId: number | null = null
    ) => {
        analytics.track('Touch signature - Click "Sign Documents" button', {
            method: insecureMethod,
            withNAP: true,
        });

        TouchActions.signWithNAP(challengeKey, base64image, disposable, userId);
    };

    const handleNapSubmit = (disposable: boolean, userId: number | null) => {
        saveWithNAP(disposable, userId);
    };

    const handleSign = () => {
        if (nameAndAddressProtectionSaving) {
            processNapSign();

            return;
        }

        save();
    };

    const methodRender = (method: InsecureSigningMethod) =>
        ({
            draw: (
                <SignaturePad
                    onChange={updateSignatureImage}
                    clear={true}
                    undo={true}
                />
            ),
            image: <ImageUpload onChange={updateSignatureImage} />,
            text: <TextSignature onChange={updateSignatureImage} />,
        }?.[method] ?? null);

    const content = () => {
        const isLoaded = signText && !loading && !error;
        const markup = {
            error: (
                <div
                    className="error-message"
                    data-testid="Auth/components/InsecureSignature/error">
                    <div>
                        {i18n`An error ocurred, please, try again.`}
                        <br />
                        <br />
                        <Button
                            onClick={retry}
                            data-testid="Auth/components/InsecureSignature/retry">{i18n`Retry`}</Button>
                    </div>
                </div>
            ),
            success: (
                <div
                    className="signing-successful"
                    data-testid="Auth/components/InsecureSignature/success">
                    <h2>{i18n`Signing Successful`}!</h2>
                </div>
            ),
            accountSelection: (
                <div>
                    <PostSignStorageOptions
                        challengeKey={challengeKey}
                        signature={{
                            token: '',
                            signature: [base64image as string, ''],
                        }}
                        isSubmitting={false}
                        hasError={false}
                        method="image"
                        onSubmit={handleNapSubmit}
                    />
                </div>
            ),
            method: (
                <div
                    className="widget-content"
                    data-testid="Auth/components/InsecureSignature/method">
                    <div className="sign-text no-scroll">
                        <div
                            dangerouslySetInnerHTML={{
                                __html: signText as string,
                            }}
                        />
                    </div>

                    <div className="insecure-method">
                        {methodRender(insecureMethod)}
                    </div>

                    <div className="controls">
                        <Button
                            theme="green"
                            disabled={!base64image}
                            onClick={handleSign}
                            data-testid="Auth/components/InsecureSignature/signDocuments">
                            {i18n`Sign Documents`}
                        </Button>
                    </div>
                </div>
            ),
        };

        const options = {
            error: error,
            success: saveState === 'saved' && !error,
            accountSelection: saveState === 'account-selection',
            method: isLoaded && saveState === 'signing' && signText,
        };

        const output = Object.keys(options).filter(
            (option) => options[option]
        )[0];

        return markup[output] ?? <Loader />;
    };

    return (
        <div className="insecure-methods-container">
            <div className="wrapper">
                <div className="widget-container form-v2">{content()}</div>
            </div>
        </div>
    );
};

const getValidationSessionUserIds = (): 'no-session' | number[] => {
    const validationToken = V2Validation.get() as string | null;

    if (!validationToken && !validationToken?.match(/^.+\..+\./)) {
        return 'no-session';
    }

    const data = jwtDecode(validationToken) as any;

    return data.uids || [];
};

export default InsecureSignature;
