import React from 'react';
import PropTypes from 'prop-types';
import {
    getOpenIdState,
    clearOpenIdState,
    updateOpenIdState,
} from 'OpenID/utils/openIdState';
import OpenIDError from 'OpenID/components/OpenIDError/OpenIDError';
import LoginUtils from 'Auth/utils/login';
import { getDecodedPenneoToken } from 'Auth/utils/jwt';
import { images } from 'Constants';
import { EID_METHODS } from 'EID';
import LoadingData from 'Common/components/Loaders/LoadingData';
import { Intent, ServiceIDs } from 'OpenID/Constants';
import { connect } from 'react-redux';
import { ReduxState, AppDispatch } from 'Store';
import { collect, enableMitId } from 'OpenID/redux/actions';
import Widget from 'Common/components/Widget';
import WidgetBody from 'Common/components/WidgetBody';
import WidgetHeader from 'Common/components/WidgetHeader';
import { i18n } from 'Language';
import './openid-callback.scss';
import { ERROR_OPENID_013, ERROR_OPENID_015 } from 'OpenID/Errors';
import analytics from '../../../Common/Analytics';
import launchDarkly, { Flags } from 'Common/LaunchDarkly';

export type Props = {
    error: any;
    dispatch: AppDispatch;
    isSuccess: boolean;
    location: {
        query: {
            code: string;
            state: string;
        };
    };
    route: any;
    params: {
        serviceId: ServiceIDs;
    };
};

type State = {
    showError: boolean;
};

export class CallbackContainer extends React.Component<Props, State> {
    static contextTypes = {
        router: PropTypes.object,
    };

    state: State = {
        showError: true,
    };

    componentDidMount() {
        if (getOpenIdState()) {
            this.processIntent();
        }
    }

    processIntent = () => {
        const { dispatch } = this.props;
        const { code, state } = this.props.location.query;
        const openIdState = getOpenIdState();

        if (!openIdState) {
            console.warn('no openid state');

            return false;
        }

        const { serviceId } = this.props.params;

        dispatch(collect(serviceId, openIdState.intent, code, state));
    };

    componentDidUpdate(prevProps: Props) {
        const { isSuccess, error } = this.props;

        const wasProcessSuccessful = !prevProps.isSuccess && isSuccess;
        const wasThereAnError = !prevProps.error && error;

        if (wasProcessSuccessful) {
            this.handleSuccess();
        } else if (wasThereAnError) {
            this.trackFailure();

            if (this.isUserUnathorizedError(error)) {
                this.handleUnathorizedUser();
            }
        }
    }

    async handleSuccess() {
        const state = getOpenIdState();

        if (!state) {
            return null;
        }

        const { dispatch } = this.props;
        const { intent } = state;
        let { successUrl } = state;

        // Don't clear open id state for signing intents
        // The state will be processed and cleared by the signing page
        if (intent === Intent.SIGN || intent === Intent.NAP) {
            updateOpenIdState({
                processed: true,
            });
        } else {
            clearOpenIdState();
        }

        const isMitId = state.serviceId === ServiceIDs.MITID;

        // Temporary solution for enabling MitID as an allowed login method if
        // added by a non-admin user.
        //
        // TODO: This should be removed once we remove the possibility to
        // add MitID throught the notification center.
        if (intent === Intent.ADD && isMitId) {
            try {
                await dispatch(enableMitId());
            } catch (e) {
                successUrl += '&error=true';
            }
        }

        analytics.track(
            `OpenID - ${state.serviceId} - ${intent} - callback success`
        );

        if ([Intent.LOGIN, Intent.VALIDATE_ID].includes(intent)) {
            const defaultLoginPath = LoginUtils.getDefaultRoute(successUrl);
            const token = getDecodedPenneoToken();
            const userSwitcherEnabled = launchDarkly.variation(
                Flags.USER_SWITCHER_ENABLED
            );

            const shouldRedirectToProfileSelect =
                intent === Intent.LOGIN &&
                token &&
                token.u_qty > 1 &&
                userSwitcherEnabled;

            const redirectRoute = shouldRedirectToProfileSelect
                ? {
                      type: 'route',
                      route: {
                          name: 'profile-select',
                          search: successUrl
                              ? `?redirect=${encodeURIComponent(successUrl)}`
                              : undefined,
                      },
                  }
                : defaultLoginPath;

            return this.handleRedirect(redirectRoute);
        }

        return window.location.replace(successUrl);
    }

    /**
     * If unauthorized (ie SSNs dont't match), sends back to the
     * signing page to display the "Access Denied" message
     */
    handleUnathorizedUser() {
        const state = getOpenIdState();

        if (!state) {
            return;
        }

        this.setState({ showError: false }, () => {
            window.location.replace(`${state.successUrl}?validate_error=true`);
        });
    }

    isUserUnathorizedError(error): boolean {
        const isIdValidationError = error.code === ERROR_OPENID_015.code;
        const isUnauthorized = error.status === 403;

        return isIdValidationError && isUnauthorized;
    }

    // @duplicate: LoginPage.jsx
    handleRedirect = (path) => {
        if (path.type === 'route') {
            return this.context.router.replace(path.route);
        }

        return window.location.replace(path.url);
    };

    trackFailure(): void {
        const state = getOpenIdState();

        if (state !== null) {
            const { serviceId, intent } = state;

            analytics.track(`OpenID - ${serviceId} - ${intent} - failure`);
        } else {
            analytics.track(`OpenID - failure`);
        }
    }

    render() {
        const { params, error } = this.props;
        const { showError } = this.state;
        const openIdState = getOpenIdState();
        const method = EID_METHODS.find((m) => m.type === params.serviceId);

        return (
            <Widget className="openid-callback">
                <WidgetHeader>
                    <>
                        <br />
                        <img height="18" src={`${images}/penneo-blue.png`} />
                    </>
                </WidgetHeader>
                <WidgetBody>
                    {!openIdState || !method ? (
                        <OpenIDError
                            serviceId={params.serviceId}
                            error={ERROR_OPENID_013}
                        />
                    ) : (
                        <>
                            <img
                                height="90"
                                className="openid-callback-id-service-logo"
                                src={method.logo}
                            />
                            {error && showError ? (
                                <OpenIDError
                                    intent={openIdState.intent}
                                    serviceId={params.serviceId}
                                    error={error}
                                />
                            ) : (
                                <LoadingData
                                    message={i18n`Redirecting back to Penneo...`}
                                />
                            )}
                        </>
                    )}
                </WidgetBody>
            </Widget>
        );
    }
}

export default connect((state: ReduxState) => {
    return {
        isSuccess: state.openId.intent.isSuccess,
        error: state.openId.error,
    };
})(CallbackContainer);
