import React, { useEffect, useState } from 'react';

import { Chart as ChartJS, ArcElement } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import './activity-graph.scss';
import DashboardWidgetContainer, {
    DashboardWidget,
} from './DashboardWidgetContainer';
import { i18n } from 'Language';
import { Link } from 'react-router';

// State
import { ReduxState } from 'Store';
import {
    fetchCaseFileStats,
    Stats,
    StatTypes,
} from 'Casefiles/Archive/redux/reducer';
import { connect } from 'react-redux';
import { trackDashboardInteraction } from './utils';
import { renderLoading } from './common';

ChartJS.register(ArcElement);

type Activity = {
    count: number;
    name: string;
    label: string;
    color: string;
    link: any; // TODO  - react-router link typing
};

type ActivityGraphProps = {
    stats: Stats;
    dispatch: Function;
};

const enum ActivityColors {
    draft = '#00A0FF',
    rejected = '#E94E77',
    pending = '#F8D96B',
    completed = '#9DE0AD',
    scheduled = '#04224C',
}

export const defaultStatsData: Activity[] = [
    {
        name: 'Completed',
        label: 'Completed',
        color: ActivityColors.completed,
        link: {
            name: 'archive-virtual',
            params: {
                tab: 'completed',
            },
        },
        count: 0,
    },
    {
        name: 'Pending',
        label: 'Pending',
        color: ActivityColors.pending,
        link: {
            name: 'archive-virtual',
            params: {
                tab: 'pending',
            },
        },
        count: 0,
    },
    {
        name: 'Rejected',
        label: 'Rejected',
        color: ActivityColors.rejected,
        link: {
            name: 'archive-virtual',
            params: {
                tab: 'rejected',
            },
        },
        count: 0,
    },
    {
        name: 'Drafts',
        label: 'Drafts',
        color: ActivityColors.draft,
        link: {
            name: 'archive-virtual',
            params: {
                tab: 'drafts',
            },
        },
        count: 0,
    },
    {
        name: 'scheduled',
        label: 'Scheduled delivery',
        color: ActivityColors.scheduled,
        link: {
            name: 'archive-virtual',
            params: {
                tab: 'scheduled-delivery',
            },
        },
        count: 0,
    },
];

const ActivityGraph = (props: ActivityGraphProps) => {
    const { dispatch, stats } = props;
    const [data, setData] = useState<Activity[]>([]);
    const [days, setDays] = useState<number>(0);

    useEffect(() => {
        dispatch(fetchCaseFileStats(days === 0 ? undefined : days));
    }, [dispatch, days]);

    useEffect(() => {
        if (stats.isLoaded) {
            const newData = defaultStatsData.map((stat) => ({
                ...stat,
                count:
                    stats?.items?.[label(stat.name) as StatTypes]?.count || 0,
            }));

            setData(newData);
        }
    }, [stats]);

    const labels = data.map((a) => a.name);

    const dataset = {
        label: 'Casefiles',
        data: data.map((d) => d.count),
        backgroundColor: data.map((d) => d.color),
        cutout: '80%',
    };

    const dateSelectOptions = [
        {
            label: 'All cases',
            days: 0,
        },
        {
            label: 'Last week',
            days: 7,
        },
        {
            label: 'Last month',
            days: 30,
        },
        {
            label: 'Last three months',
            days: 90,
        },
        {
            label: 'Last six months',
            days: 180,
        },
        {
            label: 'Last year',
            days: 365,
        },
    ];

    const handleSelectDate = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const days = event.target.value;

        setDays(Number(days));

        trackDashboardInteraction('Activity Graph date selector changed', {
            newDate: days,
        });
    };

    const label = (n: string) => `${n.toLowerCase().replace(' ', '')}`;

    const renderContent = () => (
        <DashboardWidget title={i18n`Overview`}>
            <>
                <div className="activity-graph-widget-date-select">
                    <select defaultValue={days} onChange={handleSelectDate}>
                        {dateSelectOptions.map((date) => (
                            <option key={date.label} value={date.days}>
                                {i18n(date.label)}
                            </option>
                        ))}
                    </select>
                </div>
                {stats.isLoaded === false ? (
                    renderLoading()
                ) : (
                    <div className="activity-graph-widget-content">
                        <div className="activity-graph-widget-graphic">
                            <Doughnut
                                title={i18n`Doughnut chart showing a collection of ${data.reduce(
                                    (p, c) => p?.['count'] ?? p + c.count,
                                    0
                                )} documents`}
                                data={{
                                    ...{ labels, datasets: [dataset] },
                                }}
                                options={{
                                    responsive: true,
                                    maintainAspectRatio: false,
                                }}
                            />
                            <p
                                className="activity-graph-widget-total"
                                data-testid="total-amount">
                                {data.reduce(
                                    (p, c) => p?.['count'] ?? p + c.count,
                                    0
                                )}
                                <span>{i18n`Documents`}</span>
                            </p>
                        </div>

                        <div className="activity-graph-widget-text">
                            <ul className="activity-graph-legend">
                                {data.map((item, i) => (
                                    <li
                                        key={`activity_${i}`}
                                        data-testid={label(
                                            `legend-${item.name}`
                                        )}
                                        className="activity-graph-legend-item">
                                        <Link
                                            to={item.link}
                                            onClick={() =>
                                                trackDashboardInteraction(
                                                    'Activity Graph counter clicked',
                                                    {
                                                        id: label(item.name),
                                                        count: item.count,
                                                    }
                                                )
                                            }>
                                            <span
                                                style={{
                                                    backgroundColor: item.color,
                                                }}
                                                data-testid={`color-${item.color}`}></span>
                                            {item.count} {i18n(item.label)}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                )}
            </>
        </DashboardWidget>
    );

    return (
        <DashboardWidgetContainer
            title={i18n`Overview`}
            className="activity-graph-widget"
            content={[renderContent()]}
        />
    );
};

export default connect((state: ReduxState) => ({
    stats: state.archive.stats,
}))(ActivityGraph);
