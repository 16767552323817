import PropTypes from 'prop-types';
import React from 'react';
import LoginContainerHeader from '../Common/LoginContainerHeader';
import ActionBar from '../Common/ActionBar';
import BankIDActions from '../../actions/BankIDNOServerActions';
import ViewActions from '../../actions/BankIDNOViewActions';
import BankIDStore from '../../stores/BankIDNOStore';
import BankIDWidget from './BankIDWidget';
import RememberLoginMethod from '../Common/RememberLoginMethod';
import { TranslationStore, i18n } from 'Language';
import StatusPageMessage, {
    ComponentId,
} from 'Common/components/StatusPageMessage';

import launchDarkly, { Flags } from 'Common/LaunchDarkly';
import { BANKID_NORWAY_EIDENT } from 'EID/Constants';
import OpenIDActions from '../../actions/OpenIDActions';
import { Intent } from 'OpenID/Constants';

export default class LoginContainer extends React.Component {
    constructor(props) {
        super(props);

        this.eidButtonRef = React.createRef(null);
    }

    static propTypes = {
        location: PropTypes.object,
        redirect: PropTypes.func,
        children: PropTypes.object,
    };

    state = {
        params: null,
        isFetching: null,
        error: null,
        language: TranslationStore.getLanguage(),
    };

    timeout = null;

    componentDidMount() {
        // If the feature flag is enabled, this will trigger a redirection using
        // the EID web component button. (also rendered conditionally using the same feature flag)
        if (launchDarkly.variation(Flags.BANKID_NORWAY_EIDENT_ENABLED)) {
            this.timeout = setTimeout(() => {
                OpenIDActions.init(BANKID_NORWAY_EIDENT.type, Intent.LOGIN);
            }, 1000);

            return;
        }

        TranslationStore.addChangeListener(this.onChange);
        BankIDStore.addChangeListener(this.onChange);
        ViewActions.clearStore();
    }

    componentWillUnmount() {
        if (this.timeout) {
            clearTimeout(this.timeout);

            return;
        }

        TranslationStore.removeChangeListener(this.onChange);
        BankIDStore.removeChangeListener(this.onChange);
    }

    componentDidUpdate(prevProps, prevState) {
        // Reset the widget if language is changed
        if (prevState.language !== this.state.language) {
            this.retry();
        }
    }

    onChange = () => {
        this.setState({
            params: BankIDStore.getParams(),
            isFetching: BankIDStore.isFetching(),
            error: BankIDStore.getError(),
            language: TranslationStore.getLanguage(),
        });
    };

    onSuccess = (signatureRef) => {
        BankIDActions.login(signatureRef);
    };

    init = (languageCode = TranslationStore.getLanguage()) => {
        BankIDActions.initLogin(languageCode);
    };

    retry = () => {
        ViewActions.retry();
        this.init();
    };

    updateError = (error) => {
        this.setState({ error: error });
    };

    render() {
        let { params, error, isFetching } = this.state;

        return (
            <div className="auth-method-password">
                <LoginContainerHeader
                    back="/login"
                    hideLanguageSelector={true}
                />
                <ActionBar title="Log in with BankID" />

                <div className="eid-widget-container">
                    <StatusPageMessage
                        components={[
                            ComponentId.THIRD_PARTY_NETS_BANKID_NO,
                            ComponentId.LOGIN_BANKID_NO,
                        ]}
                    />

                    {/* If BankID through E-IDent is enabled, ender the EID Button to trigger a redirection on mount */}
                    {launchDarkly.variation(
                        Flags.BANKID_NORWAY_EIDENT_ENABLED
                    ) ? (
                        <>
                            <div className="auth-logo">
                                <img
                                    height="120"
                                    width="120"
                                    src={BANKID_NORWAY_EIDENT.logo}
                                />
                            </div>
                            <p>{i18n(`Redirecting...`)}</p>
                        </>
                    ) : (
                        <>
                            <BankIDWidget
                                params={params}
                                error={error}
                                retry={this.retry}
                                isFetching={isFetching}
                                init={this.init}
                                onSuccess={this.onSuccess}
                                onFailure={this.onFailure}
                                updateError={this.updateError}
                            />

                            <div className="widget-footer">
                                <RememberLoginMethod />
                                <a
                                    href="https://penneo.force.com/support/s/article/I-cannot-continue-past-the-one-time-code-when-signing-with-Norwegian-BankID-on-mobile-why "
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    {i18n('Problems with BankID?')}
                                </a>
                            </div>
                        </>
                    )}
                </div>
            </div>
        );
    }
}
