import { NewSignerAPI } from 'Api';
import { i18n, TranslationActions } from 'Language';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
    signatureLineStatus,
    SigningProcess,
    SigningProcessError,
    signingRequestStatus,
} from 'types/SigningProcess';
import SigningCasefileDocumentPreview from './SigningCasefileDocumentPreview';
import Loader from 'Common/components/Common/Loader';
/* new signing components */
import { SigningFooter } from './SigningFooter';
import { SigningHeader } from './SigningHeader';
import { SigningRole } from './SigningRole';
import SignFail from './SignFail';
import launchDarkly, { Flags } from 'Common/LaunchDarkly';
import './signing.scss';
import {
    getSignerLanguage,
    isV2Signing,
    setSignerLanguage,
    signingBranding,
    trackSigning,
    V2Validation,
} from './utils';
import { getOpenIdState } from 'OpenID/utils/openIdState';
import { UserEntity } from 'types/User';
import { isMobile } from 'utils';
import { Languages } from 'Language/Constants';

export type Props = {
    params: {
        challengeKey: string;
    };
};

const Signing = ({ params: { challengeKey } }: Props) => {
    const [casefileData, setCasefileData] = useState<SigningProcess>();
    const [currentDocId, setCurrentDocId] = useState<number | undefined>();
    const [casefileGone, setCasefileGone] = useState<boolean>(false);
    const [identified, setIdentified] = useState<boolean>(false);
    const [translated, setTranslated] = useState(false);
    const [mobile, setMobile] = useState(false);
    // This is only set to force a re-render after the user selects a language.
    const [_, setUserSelectedLanguage] = useState(''); // eslint-disable-line

    const checkMobile = () => setMobile(isMobile());

    useEffect(() => {
        // prevent tracking twice (fx. after coming from OpenId)
        if (!isV2Signing.check()) {
            trackSigning('Signing start');
            // Setting V2 signing in localStorage (for right routing)
            isV2Signing.set();
        }

        /**
         * We check if we are in a mobile device on page load
         * and after each window resize
         */
        checkMobile();

        window.addEventListener('resize', checkMobile);

        return () => {
            window.removeEventListener('resize', checkMobile);
        };
    }, []);

    useEffect(() => {
        (async () => {
            /**
             * If the API request fails with 403 Forbidden,
             * that means the case has sensitive data.
             * We send the user to the Validation page
             */
            try {
                let caseData: SigningProcess | null = (await NewSignerAPI.get(
                    `/v2/signing-process/${challengeKey}`
                )) as SigningProcess;

                setCasefileData(caseData as SigningProcess);

                const state = getOpenIdState();
                const { customer } = caseData;

                if (customer && caseData) {
                    let user: Partial<UserEntity> = {
                        customerIds: [customer?.id ?? -1],
                    };

                    await launchDarkly
                        .identify(
                            user as UserEntity,
                            null,
                            null,
                            (state?.processed && { method: state.serviceId }) ||
                                null
                        )
                        .then(() => {
                            setIdentified(true);
                        })
                        .catch((err) => {
                            setIdentified(true);
                        });
                }

                setIdentified(true);
            } catch (error) {
                if (error?.status === 410) {
                    // typing
                    const signingDoneData: SigningProcessError = error;

                    // Remove forward trailing slash from the window.location.href
                    // This is due to the fact that some URI' can be of type:
                    // .../signing/{challenge-key}/
                    // instead of:
                    // .../signing/{challenge-key}

                    const url = window.location.href.replace(/\/$/, '');

                    if (
                        launchDarkly.variation(
                            Flags.VIEW_COMPLETED_CASEFILES_VIA_SIGNING_LINK
                        ) &&
                        signingDoneData?.data?.errors
                            ?.completedCasefileAccessibleViaSigningLink
                    ) {
                        return window.location.assign(`${url}/finalized`);
                    }

                    /**
                     * Both the casefile or the current signature line could have expired,
                     * that's why we have to check for those two scenarios.
                     * In these cases, signingRequestStatus will be REJECTED,
                     * which is not giving the real error we need to show.
                     */
                    const currentRequestStatus =
                        signingDoneData?.data?.errors.caseFileExpired ||
                        signingDoneData?.data?.errors.signatureLineStatus ===
                            signatureLineStatus.SIGNATURE_LINE_EXPIRED
                            ? signingRequestStatus.expired
                            : signingDoneData?.data?.errors
                                  .signingRequestStatus;

                    // Redirect to signed/rejected or other custom error page

                    return window.location.assign(
                        `${url}/${currentRequestStatus}`
                    );
                }

                if (error?.status === 403) {
                    const currentUrl = window.location.href;
                    const validationUrl = currentUrl.replace(
                        '/signing/',
                        '/signing/validation/'
                    );

                    /**
                     * If the 403 error is due to wrong token, it won't contain any useful data
                     * Hence, we delete the token from storage before redirecting
                     */
                    if (error?.data?.isSpecificSignerExpected !== undefined) {
                        V2Validation.clear();
                    }

                    // Redirect to Validation page!
                    return window.location.assign(validationUrl);
                }

                setCasefileGone(true);
            }
        })();
    }, [challengeKey]);

    useEffect(() => {
        if (!casefileData) {
            return;
        }

        const { caseFile, documents } = casefileData;

        setCurrentDocId(documents?.[0]?.id);

        (async () => {
            if (!translated) {
                const language = getSignerLanguage() || caseFile.language;

                await TranslationActions.changeLanguageView(language);

                // we need to use the boolean to force re-render after Flux translation
                setTranslated(true);
            }
        })();
    }, [casefileData, translated]);

    async function changeLanguage(languageCode: Languages) {
        await TranslationActions.changeLanguageView(languageCode);

        setSignerLanguage(languageCode);

        setUserSelectedLanguage(languageCode);
    }

    const handleChangeDocument = (id: number) => {
        setCurrentDocId(id);
    };

    if (casefileGone && !casefileData) {
        return <SignFail challengeKey={challengeKey} />;
    }

    /**
     * We run the loader whether we are waiting for the casefile data
     * or the translation file
     */
    if ((!casefileData && !identified) || !currentDocId || !translated) {
        return (
            <>
                <br />
                <br />
                <Loader />
            </>
        );
    }

    const {
        allowedSigningMethods,
        customer,
        documents,
        caseFile,
        isRegisteredLetter,
        encryptedNIN,
        signingRequest,
    } = casefileData as SigningProcess;
    const branding = signingBranding(customer?.branding);

    return (
        (currentDocId && translated && (
            <>
                <Helmet>
                    <title>{i18n`Sign Documents`}</title>
                </Helmet>

                <SigningHeader
                    currentDocId={currentDocId}
                    challengeKey={challengeKey}
                    branding={branding}
                    isValidatedCasefile={
                        !!casefileData?.caseFile.sensitiveData as boolean
                    }
                    changeLanguage={changeLanguage}
                />
                <div className="signing-container">
                    <div className="signing-content">
                        <SigningCasefileDocumentPreview
                            id={currentDocId}
                            challengeKey={challengeKey}
                            uploadedDocuments={documents}
                        />
                        <SigningRole documents={documents} />
                    </div>
                </div>
                <SigningFooter
                    currentDocId={currentDocId}
                    caseFile={caseFile}
                    challengeKey={challengeKey}
                    branding={branding}
                    uploadedDocuments={documents}
                    allowedSigningMethods={allowedSigningMethods}
                    isRegisteredLetter={isRegisteredLetter}
                    signingRequest={signingRequest}
                    changeDocument={handleChangeDocument}
                    encryptedNIN={encryptedNIN}
                    mobile={mobile}
                />
            </>
        )) || <SignFail challengeKey={challengeKey} />
    );
};

export default Signing;
