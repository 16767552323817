// Event taxonomy
// @see: https://docs.google.com/spreadsheets/d/1MpwVaNjV_NC91nyhI-d9kod62zjS4HBeH6QVdLKae3k

import amplitude from 'amplitude-js';
import lodash from 'lodash';
import { env } from 'Constants';
import { parameters } from 'Constants';
import { getFlatManagedFeatures } from 'Settings/utils';
import { Settings } from 'Settings/redux/types';
import mocked from '__mocks__/Amplitude';
import LaunchDarkly, { Flags } from 'Common/LaunchDarkly';
import { AmplitudeFlags } from 'types/Amplitude';
import { CustomerEntity } from 'types/Customer';
import { UserEntity } from 'types/User';
import { debug } from 'Core';

const IS_CYPRESS_RUNNING = !!(window as any).Cypress;
const RUNNING_UNDER_JEST = (window as any).PENNEO_CONFIG?.test === true;
const RUNNING_TESTS = IS_CYPRESS_RUNNING || RUNNING_UNDER_JEST;

/**
 * List of LaunchDarkly flags that will be tracked in amplitude as user properties
 *
 * This serves as an "allow list" to track only certain flags to amplitude, to avoid polluting the
 * user properties with all the flags specified in LaunchDarkly. This means that to allow a new flag
 * to be tracked, it needs to be added to this list.
 *
 * @todo: We can investigate implementing a feature that makes use of the LaunchDarkly feature flag tags
 * to be able to manage the allow list from the LaunchDarkly dashboard instead of code.
 */
const ALLOWED_FLAGS_LIST = [Flags.EXPERIMENT_CLIENT_SELECTOR_ENABLED];

/**
 * Filters all the LaunchDarkly flags in `Common/LaunchDarkly` and returns only the
 * ones that are included in the `ALLOWED_FLAGS_LIST`.
 */
const filterAnalyticsFlags = (flags: AmplitudeFlags): AmplitudeFlags => {
    const allowedFlags: AmplitudeFlags = {};

    ALLOWED_FLAGS_LIST.forEach((flagName) => {
        if (!!flags[flagName]) {
            allowedFlags[flagName] = flags[flagName];
        }
    });

    return allowedFlags;
};

const real = {
    init() {
        const options = {
            platform: env.platform === 'desktop' ? 'Desktop' : 'Web',
            apiEndpoint: window.location.host + parameters.api.bi,
        };

        amplitude
            .getInstance()
            .init(parameters.amplitudeToken, undefined, options);
        amplitude
            .getInstance()
            .setVersionName(process.env.APP_VERSION as string);
    },

    track(eventType, eventProperties = null, userProperties = null) {
        amplitude.getInstance().logEvent(eventType, eventProperties);

        debug.info(`[Amplitude] ${eventType}`, eventProperties);

        // Track user properties from a single track call.
        if (userProperties) {
            amplitude.getInstance().setUserProperties(userProperties);
        }
    },

    setUserId(userId: string) {
        return amplitude.getInstance().setUserId(userId);
    },

    /**
     * Identifies the customer as a group, and enables account level reporting in
     * Amplitude dashboards. (i.e. reporting on a customer level vs. at user level)
     *
     * @see https://help.amplitude.com/hc/en-us/articles/115001765532-Accounts
     * @see https://developers.amplitude.com/docs/group-identify-api
     * @see https://developers.amplitude.com/docs/javascript#group-identify
     */
    setCustomerGroup(customer: CustomerEntity) {
        // Creates an account level report called "Customer".
        // @see: https://analytics.amplitude.com/penneo/account-activity
        const GROUP_TYPE: 'Customer' = 'Customer'; // This value should remain unchanged.

        // We don't use property names like `name`, `id`, `language`, because amplitude
        // groups property names as if they were unique even if they belong to different events or objects.
        // So we need to follow a unique name convention.
        const identify = new amplitude.Identify()
            .set('customerName', customer.name)
            .set('customerId', customer.id)
            .set('customerLanguage', customer.language);

        // Link the current session to the `Customer` group type.
        amplitude.getInstance().setGroup(GROUP_TYPE, customer.name);

        // Add properties to the `Customer` group type.
        return amplitude
            .getInstance()
            .groupIdentify(GROUP_TYPE, customer.name, identify);
    },

    initUser(
        user: UserEntity,
        customers: CustomerEntity[],
        settings: Settings
    ) {
        // Flatten managed settings to send to Amplitude. (Amplitude doesn't support nested data formats)
        const managedFeatures = getFlatManagedFeatures(settings);

        const customerProperties = {
            customerId: user?.customerIds?.[0],
            customerName: lodash.get(customers, '[0].name'),
            customerLanguage: lodash.get(customers, '[0].language'),
        };

        const userProperties = {
            admin: user.admin,
            active: user.active,
            role: user.role,
            language: user.language,
            rights: user.rights,
            flags: filterAnalyticsFlags(LaunchDarkly.flags()),
            ...customerProperties,
            ...managedFeatures,
        };

        // Clean undefined values out of user property payload.
        // This suppresses Amplitude warnings.
        const payload = lodash.pickBy(
            userProperties,
            (value) => typeof value !== 'undefined'
        );

        const actions = [
            this.setUserId(user.id.toString()),
            this.setUserProperties(payload),
        ];

        // Enable account-level reporting only for users mapped to a customer
        if (customers && customers[0]) {
            actions.push(this.setCustomerGroup(customers[0]));
        }

        return Promise.all(actions);
    },

    setUserProperties(properties = null) {
        return amplitude.getInstance().setUserProperties(properties);
    },
    // @see https://amplitude.zendesk.com/hc/en-us/articles/115002889587#class-identify
    // Return Identify class to add, edit, etc user properties
    userProperties() {
        return new amplitude.Identify();
    },
    // Commit identify object / save user properties object
    identify(userProperties) {
        return amplitude.getInstance().identify(userProperties);
    },

    // Increment a user property by an amount (defaults to 1)
    incrementUserProperty(property, amount: number = 1) {
        let identify = new amplitude.Identify().add(property, amount);

        return amplitude.getInstance().identify(identify);
    },

    // Append user property to an array of values
    appendUserProperty(property, value) {
        const identify = new amplitude.Identify().append(property, value);

        return amplitude.getInstance().identify(identify);
    },
};

export default RUNNING_TESTS ? mocked : real;
