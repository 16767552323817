/* eslint camelcase: 0 */
import { Intent, OIDs } from 'OpenID/Constants';
import {
    OpenIDCertificate,
    OpenIDCertificateDisplayInfo,
    OpenIDObj,
    OpenIDSubjectIdentifiers,
    OpenIDToken,
} from 'OpenID/redux/types';

export type NetsIdVerifierToken = OpenIDToken & {
    iss: string;
    acr: string;
    nbf: number;
    amr: string[];
    loa: string;
    identity_type: string;
    idp: string;
    auth_time: string;
    transaction_id: string;
    name: string;
    primary_identifier: string;
    secondary_identifier: string;
    nationality: string;
    issuing_country: string;
    interpreted_nationality: string;
    interpreted_issuing_country: string;
    gender: string;
    facematch_level: number;
    document_type: string;
    document_number: string;
    date_of_expiry: string;
    chip_clone_detection: string;
    birthdate: string;
    authentication_result: string;
    auth_files_url: string;
};

export type NetsIdVerifierSubjectIdentifiers = OpenIDSubjectIdentifiers & {
    [OIDs.PENNEO_OPENID_COUNTRY_NAME_ISO_3166_1_ALPHA_3]: string;
    [OIDs.PENNEO_OPENID_DOCUMENT_TYPE]: string;
    [OIDs.PENNEO_OPENID_DOCUMENT_NUMBER]: string;
    [OIDs.OPENID_FULL_NAME_V2]: string;
};

export type NetsIdVerifierCertificate = OpenIDCertificate<
    NetsIdVerifierSubjectIdentifiers
>;

export const NetIdVerifier: OpenIDObj<
    NetsIdVerifierToken,
    NetsIdVerifierCertificate
> = {
    issuers: [
        'https://www.ident-preprod1.nets.eu/oidc',
        'https://www.ident.nets.eu/oidc',
    ],
    getSubject(token) {
        return {
            [OIDs.OPENID_ISSUER]: token.iss,
            [OIDs.OPENID_ID]: token['sub'],
            [OIDs.PENNEO_OPENID_COUNTRY_NAME_ISO_3166_1_ALPHA_3]:
                token['issuing_country'],
            [OIDs.PENNEO_OPENID_DOCUMENT_TYPE]: token['document_type'],
            [OIDs.PENNEO_OPENID_DOCUMENT_NUMBER]: token['document_number'],
            [OIDs.OPENID_FULL_NAME_V2]: token['name'],
        };
    },
    isOwnToken(token): token is NetsIdVerifierToken {
        return (
            this.issuers.includes(token.iss) &&
            'nationality' in token &&
            'issuing_country' in token
        );
    },
    isOwnCertificate(certificate): certificate is NetsIdVerifierCertificate {
        const { subject } = certificate;

        return (
            this.issuers.includes(subject[OIDs.OPENID_ISSUER]) &&
            !!subject[OIDs.OPENID_FULL_NAME_V2] &&
            !subject[OIDs.OPENID_ORG_IDENTIFIER_TYPE]
        );
    },
    getCertificateDisplayInfo(
        { subject, issuer },
        intent = Intent.SIGN
    ): OpenIDCertificateDisplayInfo {
        return {
            title: subject[OIDs.OPENID_FULL_NAME_V2],
            issuer: issuer[OIDs.OPENID_ORG_NAME_V2],
        };
    },
};
