import React from 'react';
import { Link } from 'react-router';
import { i18n } from 'Language';
import { CaseFileEntity } from 'types/CaseFile';
import { SignerEntity } from 'types/Signer';
import { EventLogState } from './redux/types';
import { ABUNDANT_SIGNER_STATUS } from 'Casefiles/Constants';
import { getSigningRequestStatus } from './statusCodes';
import StatusBadge from './StatusBadge';
import { getSignerRolesFromSignatureLines } from '../utils/rolesFromSignatureLines';
import CasefileSignerRolesList from '../components/casefiles2/CasefileSignerRolesList';
import './casefile-recipients.scss';
import { flatten } from 'lodash';
import { SignatureLineEntity } from '../../types/Document';
import { signingFlowIsRegisteredLetter } from 'RegisteredLetter/utils';
import { AuthStore } from 'Auth';

type CasefileRecipientsProps = {
    casefile: CaseFileEntity;
    casefileId: number;
    eventLog: EventLogState;
};

const CasefileRecipients = ({
    casefile,
    casefileId,
    eventLog,
}: CasefileRecipientsProps) => {
    const { signers, ccRecipients } = casefile;

    return (
        <div>
            <h4 className="casefile-details-section-header">
                {i18n`Recipients`}

                <span className="casefile-details-section-count">
                    {signers.length + (!ccRecipients ? 0 : ccRecipients.length)}
                </span>
            </h4>

            <label>{i18n`Signers`}</label>
            <div className="casefile-recipients">
                {signers.map((signer) => (
                    <Recipient
                        key={signer.id}
                        recipient={signer}
                        casefile={casefile}
                        eventLog={eventLog}
                    />
                ))}
            </div>

            {ccRecipients && ccRecipients.length > 0 && (
                <div>
                    <label>{i18n`Copy Recipients`}</label>
                    <div>
                        {ccRecipients.map((r, i) => (
                            <span
                                key={i}
                                className="casefile-recipients-cc-recipient">
                                <i className="far fa-envelope"></i>
                                {r.name}&nbsp;
                                <span className="email text-blue">
                                    &lt;
                                    {r.email}
                                    &gt;
                                </span>
                            </span>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default CasefileRecipients;

type Props = {
    casefile: CaseFileEntity;
    recipient: SignerEntity;
    eventLog: EventLogState;
};

class Recipient extends React.Component<Props> {
    renderRoles = () => {
        const { recipient, casefile } = this.props;
        const roles = getSignerRolesFromSignatureLines(recipient, casefile);

        return (
            <CasefileSignerRolesList roles={roles} signerId={recipient.id} />
        );
    };

    render() {
        const { recipient, casefile, eventLog } = this.props;

        return (
            <div className="casefile-recipient">
                <Link
                    className="no-underline"
                    to={
                        AuthStore.getAuthDetails().cid === casefile.customerId
                            ? {
                                  name: 'signer-details',
                                  params: {
                                      casefileId: casefile.id,
                                      signerId: recipient.id,
                                  },
                              }
                            : undefined
                    }>
                    <div className="casefile-recipient-container">
                        <div className="casefile-recipient-hover-overlay">
                            <div className="casefile-recipient-hover-overlay-content">
                                <i className="fas fa-arrow-right"></i>
                            </div>
                        </div>

                        <div className="casefile-recipient-name">
                            {recipient.name}
                        </div>
                        <div className="casefile-recipient-email">
                            <i className="far fa-envelope text-small"></i>&nbsp;
                            {recipient.signingRequest.email}
                        </div>
                        {recipient.onBehalfOf && (
                            <div className="casefile-recipient-onbehalfof">
                                <i className="far fa-briefcase text-small" />
                                &nbsp;
                                {recipient.onBehalfOf}
                            </div>
                        )}

                        {this.renderRoles()}

                        <div className="casefile-recipient-footer">
                            <RecipientStatus
                                caseFile={casefile}
                                signer={recipient}
                                eventLog={eventLog}
                            />
                        </div>
                    </div>
                </Link>
            </div>
        );
    }
}

type RecipientStatusProps = {
    caseFile: CaseFileEntity;
    signer: SignerEntity;
    eventLog: EventLogState;
};

const RecipientStatus = (props: RecipientStatusProps) => {
    const { signer, caseFile, eventLog } = props;
    const signatureLines: SignatureLineEntity[] = flatten(
        caseFile.documents.map((document) => document.signatureLines || [])
    );
    const caseFileIsFinalized = caseFile.status === 5;
    const signingRequestStatusIsPending = signer.signingRequest.status === 1;

    const hasActivatedSignatureLines = signatureLines.find(
        (line) =>
            line.signOrder <= caseFile.signIteration &&
            line.signerId === signer.id
    );

    const hasToSignInCurrentRound = signatureLines.find(
        (line) =>
            line.signOrder === caseFile.signIteration &&
            !line?.signedAt &&
            line.signerId === signer.id
    );

    const hasToSignInFutureRound = signatureLines.find(
        (line) =>
            line.signOrder > caseFile.signIteration &&
            line.signerId === signer.id
    );

    const caseFileIsRegisteredLetter = signingFlowIsRegisteredLetter(
        caseFile.caseFileType?.id
    );

    const signerLog = eventLog.data.signers?.find((signerLog) => {
        return signerLog.id === signer.id;
    });

    const signerHasOpenedTheCasefile = signerLog
        ? !!signerLog.events.find((eventLog) => {
              return eventLog.eventType === 2;
          })
        : undefined;

    if (caseFileIsRegisteredLetter && signerHasOpenedTheCasefile) {
        return (
            <StatusBadge
                status={{
                    name: 'Opened',
                    details: 'This recipient has opened the casefile',
                    icon: 'check-circle',
                    color: 'green',
                }}
            />
        );
    }

    if (caseFileIsRegisteredLetter && !signerHasOpenedTheCasefile) {
        return (
            <StatusBadge
                status={{
                    name: 'Unopened',
                    details: 'This recipient has not opened the casefile',
                    icon: 'clock',
                    color: 'yellow',
                }}
            />
        );
    }

    if (
        caseFileIsFinalized &&
        hasActivatedSignatureLines &&
        signingRequestStatusIsPending
    ) {
        /**
         * In case conditional signing is enabled, we can't derive the signer status
         * from the signing requests, therefore if the casefile is finalized and
         * signing request status is pending, we use a label saying that the
         * signature is not longer necessary
         * */
        return <StatusBadge status={ABUNDANT_SIGNER_STATUS} />;
    }

    // This isn't a "real" status based on a status code, it's a special situation where the signer is pending, but
    // their signing round has not come up yet.
    const specialStatus = {
        name: 'Pending other signers',
        details: 'The signer will sign in a future signing round',
        icon: 'clock',
        color: 'cyan',
    };

    if (hasToSignInFutureRound && !hasToSignInCurrentRound) {
        return <StatusBadge status={specialStatus} />;
    }

    if (hasActivatedSignatureLines || hasToSignInCurrentRound) {
        const simpleStatus = getSigningRequestStatus(
            signer.signingRequest.status
        );

        // We check if the case is signed and if there's a name in the signature
        if (signer.validatedName && signer.signingRequest.status === 4) {
            simpleStatus.name = `${i18n`Signed by`} ${signer.validatedName}`;
        }

        return <StatusBadge status={simpleStatus} />;
    }

    return <StatusBadge status={specialStatus} />;
};
