import React from 'react';
import BankIDActions from '../../actions/BankIDNOServerActions';
import ViewActions from '../../actions/BankIDNOViewActions';
import BankIDStore from '../../stores/BankIDNOStore';
import BankIDWidget from './BankIDWidget';
import { TranslationStore, i18n } from 'Language';
import StatusPageMessage, {
    ComponentId,
} from 'Common/components/StatusPageMessage';

type State = {
    params: any | null;
    isFetching: boolean | null;
    error: any | null;
};

export default class BankIDNOValidateContainerV2 extends React.Component<
    {},
    State
> {
    state: State = {
        params: null,
        isFetching: null,
        error: null,
    };

    componentDidMount() {
        BankIDStore.addChangeListener(this.onChange);
        ViewActions.clearStore();
    }

    componentWillUnmount() {
        BankIDStore.removeChangeListener(this.onChange);
    }

    onChange = () => {
        this.setState({
            params: BankIDStore.getParams(),
            isFetching: BankIDStore.isFetching(),
            error: BankIDStore.getError(),
        });
    };

    onSuccess = (signatureRef) => {
        BankIDActions.validate(signatureRef);
    };

    init = (languageCode = TranslationStore.getLanguage()) => {
        BankIDActions.initLogin(languageCode);
    };

    retry = () => {
        ViewActions.retry();
        this.init();
    };

    updateError = (error) => {
        this.setState({ error: error });
    };

    render() {
        const { params, error, isFetching } = this.state;

        return (
            <div className="auth-method-password">
                <div className="eid-widget-container">
                    <StatusPageMessage
                        components={[ComponentId.LOGIN_BANKID_NO]}
                    />
                    <BankIDWidget
                        params={params}
                        error={error}
                        retry={this.retry}
                        isFetching={isFetching}
                        init={this.init}
                        onSuccess={this.onSuccess}
                        updateError={this.updateError}
                    />

                    <div className="widget-footer">
                        <a
                            href="https://penneo.force.com/support/s/article/I-cannot-continue-past-the-one-time-code-when-signing-with-Norwegian-BankID-on-mobile-why"
                            target="_blank"
                            rel="noopener noreferrer">
                            {i18n('Problems with BankID?')}
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}
