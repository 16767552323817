import PropTypes from 'prop-types';
import React from 'react';
import { API_RESPONSE_HEADERS } from 'Constants';
import { i18n } from 'Language';

import { CaseFileEntity } from 'types/CaseFile';

// Actions
import CasefileActions from '../../actions/CasefilesActionCreators';

// Components
import FullScreen from 'Common/components/FullScreen';
import FullScreenSendingError from 'Common/components/FullScreenSendingError';
import { AppDispatch } from 'Store';
import { connect } from 'react-redux';
import { setCasefileSent } from 'Casefiles/Archive/redux/reducer';

type Props = {
    error: any;
    sendCasefile: Function;
    clearStores: Function;
    loadData: Function;
    sent: boolean;
    casefile: CaseFileEntity;
    dispatch: AppDispatch;
};

class SendingCasefile extends React.Component<Props> {
    static contextTypes = {
        router: PropTypes.object.isRequired,
    };

    componentDidUpdate() {
        const { sent, dispatch } = this.props;

        /**
         * If the casefile is set to 'sent', redirect user to Dashboard.
         * Also, update Redux store so it will trigger a success notification there
         */
        if (sent) {
            dispatch(setCasefileSent());
            this.context.router.push({
                name: 'dashboard',
            });
        }
    }

    retry = () => {
        CasefileActions.clearError();
        this.props.sendCasefile();
    };

    back = () => {
        CasefileActions.clearError();
    };

    renderLoader = () => (
        <div>
            <h1>
                <i className="far fa-sync fa-spin"></i>
                <br />
                {i18n`Packing things up!`}
            </h1>
        </div>
    );

    renderFullScreenError() {
        const { error } = this.props;

        let headers:
            | {
                  [API_RESPONSE_HEADERS.REQUEST_ID]?: string;
                  [API_RESPONSE_HEADERS.TRACE_ID]?: string;
              }
            | undefined;

        /**
         * Extract the request & trace IDs from the error coming from a failed request in
         * the chain of calls made when sending a case file. If the error stems from a
         * failed upload of a document, the error will be an object with all the failed
         * documents and their headers and in this case we extract the request/trace IDs
         * from the first document that failed.
         */
        const isFromFailedDocUpload = !!error?.erroredDocuments;

        headers = isFromFailedDocUpload
            ? error.erroredDocuments[0]?.reason?.headers
            : error?.headers;

        const errorRequestId = headers?.[API_RESPONSE_HEADERS.REQUEST_ID];
        const errorTraceId = headers?.[API_RESPONSE_HEADERS.TRACE_ID];

        return (
            <FullScreenSendingError
                error={error}
                onRetry={this.retry}
                onBack={this.back}
                errorRequestId={errorRequestId}
                errorTraceId={errorTraceId}
            />
        );
    }

    render() {
        const { error } = this.props;

        return error ? (
            this.renderFullScreenError()
        ) : (
            <FullScreen>{this.renderLoader()}</FullScreen>
        );
    }
}

export default connect(() => ({}))(SendingCasefile);
