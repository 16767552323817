import PropTypes from 'prop-types';
import React from 'react';
import Constants from '../../Constants';
import { i18n } from 'Language';
import DocumentListItem from './DocumentListItem';
import Dropzone from 'react-dropzone';
import lodash from 'lodash';

import ErrorMessage from '../errors/InputErrorMessage';

import DocumentActions from '../../actions/DocumentActionCreators';

// Drag and drop context
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

import DummyDocumentRow from './DummyDocumentRow';
import { env } from 'Constants';
import Button from 'Common/components/Button';

export default DragDropContext(HTML5Backend)(
    class DocumentList extends React.Component {
        static propTypes = {
            files: PropTypes.array,
            selectedDocumentTypes: PropTypes.array,
            availableDocumentTypes: PropTypes.array,
            changeSelectedDocumentTypes: PropTypes.func,
            changeDocuments: PropTypes.func,
            inputErrors: PropTypes.object,
        };

        state = {
            overflow: 'scroll',
        };

        // uploading a document by a button
        _filesAddHandler = (event) => {
            let fileList = event.target.files;

            lodash.each(fileList, (file) => {
                if (file.type === 'application/pdf') {
                    this._addDocument(file);
                }
            });
        };

        // uploading a document by dragging and dropping
        _onDropHandler = (files) => {
            files.forEach((file) => this._addDocument(file));
        };

        _addDocument = (file) => {
            let { availableDocumentTypes } = this.props;
            let initialDocumentTypeId = null;

            // @todo: make a function in the store to get the next available type.
            if (availableDocumentTypes) {
                if (availableDocumentTypes.length > 0) {
                    initialDocumentTypeId = availableDocumentTypes[0].id;
                }
            }

            let doc = {
                name: file.name.replace(/\.[^/.]+$/, ''),
                file: file,
                caseFileId: null,
                localPath: file.path,
                documentTypeId: initialDocumentTypeId,
            };

            DocumentActions.addDocument(doc);
        };

        removeDocumentHandler = (index, event) => {
            event.preventDefault();

            DocumentActions.removeDocument(index);
        };

        openDocumentPreviewHandler = (index, event) => {
            event.preventDefault();
            let filePath = this.props.files[index].localPath;

            if (env.electron) {
                let { DOCUMENT_PREVIEW_REQUEST } = Constants.ipcEvents;

                return env.electron.ipcRenderer.send(
                    DOCUMENT_PREVIEW_REQUEST,
                    filePath
                );
            }
        };

        // called on document type change
        updateDocumentType = (index, documentTypeId) => {
            let data = {
                documentTypeId: documentTypeId,
            };

            DocumentActions.editDocument(index, data);
        };

        _activateFileAddHandler = () => {
            document.getElementById('fileupload').click();
        };

        render() {
            let { files, inputErrors, availableDocumentTypes } = this.props;

            return (
                <div className="casefile-document-information">
                    <div className="casefile-row-form">
                        <div className="fieldset">
                            <div className="section-content">
                                <div className="row1">
                                    <div className="legend">
                                        <span className="fa-stack">
                                            <i className="fa fa-circle fa-stack-2x"></i>
                                            <strong className="fa-stack-1x fa-stack-text fa-inverse">
                                                2
                                            </strong>
                                        </span>
                                        &nbsp;{i18n('Add Documents')}
                                    </div>
                                    <div className="input-element">
                                        <label
                                            className="file-input"
                                            htmlFor="fileupload">
                                            <input
                                                type="file"
                                                id="fileupload"
                                                name="files[]"
                                                className="file"
                                                multiple
                                                accept="application/pdf"
                                                onClick={(event) => {
                                                    event.target.value = null;
                                                }}
                                                onChange={this._filesAddHandler}
                                            />

                                            <Button
                                                theme="blue"
                                                variant="outline"
                                                renderAsSpan>
                                                {i18n('Select Documents')}
                                            </Button>
                                        </label>

                                        {files.length > 0 && (
                                            <h3 className="pull-right document-count">
                                                {files.length}&nbsp;
                                                {files.length === 1
                                                    ? i18n`Document`
                                                    : i18n`Documents`}
                                            </h3>
                                        )}
                                    </div>
                                </div>
                                <div className="row2 prevent-selection">
                                    <div className="document-list-header">
                                        <span className="icon-header">
                                            <i className="fa fa-sort"></i>
                                        </span>
                                        <span className="text-headers">
                                            <span>{i18n`Name`}</span>
                                            <span>{i18n`Type`}</span>
                                        </span>
                                    </div>
                                    <Dropzone
                                        className="document-info"
                                        disablePreview={true}
                                        onDrop={this._onDropHandler}
                                        disableClick={true}
                                        accept="application/pdf">
                                        <ul
                                            style={{
                                                overflowY: this.state.overflow,
                                            }}>
                                            {files.length === 0 && (
                                                <div className="empty-table-message">
                                                    <br />
                                                    <i
                                                        className="fa fa-inbox fa-4x"
                                                        aria-hidden="true"></i>
                                                    <h3>
                                                        <span
                                                            className="fake-anchor"
                                                            onClick={
                                                                this
                                                                    ._activateFileAddHandler
                                                            }>
                                                            {i18n(
                                                                'Upload your documents'
                                                            )}
                                                        </span>
                                                    </h3>
                                                    <h3>
                                                        <i className="fa fa-info-circle"></i>
                                                        &nbsp;
                                                        {i18n`You can also
                                             drop your documents here`}
                                                    </h3>
                                                </div>
                                            )}

                                            {/* This component needs a dummy row that attaches at least one
                                    react-dnd source. If react-dnd has no sources initialized,
                                    it causes a conflict with react-dropzone.
                                    (Invariant Error: Cannot call hover while dragging)

                                    i.e: Whenever you drop a file to the area. react-dnd will
                                    throw an error because it tries to launch an event without
                                    any children that can listen to it. as none of the rows
                                    are initialized with the proper event listeners.

                                    This is only an issue because every DocumentListItem is both
                                    a drop target and a source. And the drop events are caught
                                    within react-dropzone initially, which doesn't report the
                                    correct state to react-dnd.

                                    To read more about this error, read the linked github issues
                                    in DummyDocumentRow component.
                                */}
                                            <DummyDocumentRow />

                                            {files.map((file, index) => (
                                                <DocumentListItem
                                                    key={index}
                                                    file={file}
                                                    index={index}
                                                    disablePreview={
                                                        !env.electron
                                                    }
                                                    availableDocumentTypes={
                                                        availableDocumentTypes
                                                    }
                                                    remove={
                                                        this
                                                            .removeDocumentHandler
                                                    }
                                                    open={
                                                        this
                                                            .openDocumentPreviewHandler
                                                    }
                                                    updateDocumentType={
                                                        this.updateDocumentType
                                                    }
                                                />
                                            ))}
                                        </ul>
                                    </Dropzone>
                                    {inputErrors.document && (
                                        <ErrorMessage
                                            message={inputErrors.document}
                                        />
                                    )}
                                </div>{' '}
                                {/* end row 2*/}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
);
