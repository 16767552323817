import { SigningAPI } from 'Api';
import { tempPdfFileUploadEndpoint } from '../utils';

const download = (fileName, blob) => {
    const link = document.createElement('a');

    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    // some browser needs the anchor to be in the doc
    document.body.append(link);

    link.click();
    link.remove();

    // in case the Blob uses a lot of memory
    window.addEventListener('focus', () => URL.revokeObjectURL(link.href), {
        once: true,
    });
};

/**
 * @param {object} doc represents user uploaded document
 * @example
 * {
 *     caseFileId: null
 *     documentTypeId: 1
 *     filename: "example.pdf"
 *     localPath: undefined
 *     name: "example"
 *     order: 0
 * }
 * @returns {Promise} Returns promise object which resolves to an array with one object of the uploaded document
 * @example
 * [{
 *     "id": 1,
 *     "extension": "<file extension>",
 *     "title": "<file title>"
 * }]
 */
const temporaryPDFUpload = async (doc) => {
    const payload = {
        files: [
            {
                title: doc.filename,
                content: doc.base64File
                    ? doc.base64File
                    : await _getBase64EncodedDocument(doc.file),
            },
        ],
    };

    return await SigningAPI.post(tempPdfFileUploadEndpoint(), payload);
};

/**
 * @param {number} fileId represents an ID of the file to download from the temporal storage
 */
const temporaryPDFDownload = async (fileId) => {
    const options = {
        responseType: 'blob',
        headers: {
            Accept: 'application/pdf',
        },
    };

    return await SigningAPI.get(`/temporal-pdf-files/${fileId}`, null, options);
};

const _arrayBufferToBase64 = (buffer) => {
    let binary = '';
    let bytes = new Uint8Array(buffer);
    let len = bytes.byteLength;

    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }

    return window.btoa(binary);
};

const _getBase64EncodedDocument = (file) => {
    if (!file) {
        return Promise.reject();
    }

    return new Promise((resolve, reject) => {
        let reader = new FileReader();

        // FileReader callback
        reader.onload = () => {
            // Encode result
            let base64 = _arrayBufferToBase64(reader.result);

            resolve(base64);
        };

        reader.onerror = (error) => {
            reject(error);
        };

        reader.readAsArrayBuffer(file);
    });
};

export { download, temporaryPDFUpload, temporaryPDFDownload };
