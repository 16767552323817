import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { i18n, LanguageDropdown } from 'Language';
import { modal } from 'Common/components/Common/Modal';
import { TextInput, Checkbox, NumberInput } from 'Common/components';
import { ReduxState, AppDispatch } from 'Store';

// Components
import CasefileFolderSelector from 'Casefiles/components/casefiles2/CasefileFolderSelector';
import HelpTooltip from 'Common/components/HelpTooltip';

// Actions
import CasefileActions from 'Casefiles/actions/CasefilesActionCreators';
import CasefileV2Actions from 'Casefiles/actions/CasefilesV2ActionCreators';
import SignerActions from 'Casefiles/actions/SignerActionCreators';

import InputValidation from 'Common/components/InputValidation';
import CopyRecipientWarningModal from './CopyRecipientWarningModal';
import Button from 'Common/components/Button';
import { EmailTemplates } from 'types/EmailTemplates';
import { ConditionalSigningOptions } from 'types/Document';
import LaunchDarkly, { Flags } from 'Common/LaunchDarkly';

import { ClientSelector } from 'AuditingAccounting';
import { SidePanel } from 'Common/components/SidePanel';
import { BetterFlowPage } from 'Labs/BetterFlowOverview/views/BetterFlowPage';
import launchDarkly from 'Common/LaunchDarkly';
import FeatureFlag from 'Common/components/FeatureFlag';
import { UserSettingKeys, UserSettings } from 'Settings/redux/types';
import { updateUserSettings } from 'Settings/redux/actions';
import { AutocompleteClients } from 'Clients/AutocompleteClients';
import { UserEntity } from 'types/User';
import { ClientLinkEntityType } from 'Clients/types';
import Tooltip from 'Common/components/Tooltip';
import UIButton from 'Common/components/Vega/Button';

export type Props = {
    dispatch: AppDispatch;
    action: string;
    error: any;
    casefile: any;
    conditionalSigningOptions: ConditionalSigningOptions;
    casefileValidation: any;
    casefileEmailTemplates: EmailTemplates;
    folders: any[];
    defaultFolder: number;
    casefileTypes: any[];
    caseType: any;
    selectedFolderId: number;
    recipients: any[];
    navigationRoutes: any[];
    linkParams: any;
    params: any;
    saveAsDraftButton: Function;
    showCasefileDetails: () => void;
    userSettings: UserSettings;
    user: UserEntity;
    handleNewClientCreation?: (newClient: string) => void;
};

type State = {
    advanced: boolean;
    showFlowDetails: boolean;
};

export class CasefileDetailStep extends React.Component<Props, State> {
    state = {
        advanced: false,
        showFlowDetails: false,
    };

    // Stores lastUsedFolder in User settings
    handleFolderChange = (folderId: number) => {
        const { dispatch, userSettings } = this.props;

        // Update in Flux
        CasefileActions.changeFolder(folderId);

        // Update user settings if the folderId has changed.
        if (userSettings.lastUsedFolder?.folderId !== folderId) {
            dispatch(
                updateUserSettings(UserSettingKeys.lastUsedFolder, {
                    folderId,
                })
            );
        }
    };

    updateCasefile = (key, value) => {
        let { casefile } = this.props;

        casefile[key] = value;
        CasefileActions.updateCasefile(casefile);
    };

    displayCopyRecipientError = () => {
        let config = {
            component: <CopyRecipientWarningModal />,
            preventClose: false,
        };

        modal.show(config);
    };

    _toggleSensitiveData = (checked) => {
        let { recipients } = this.props;
        let copyRecipients = recipients.filter(
            (r) => r.type === 'copyrecipient'
        );

        if (checked && copyRecipients.length > 0) {
            return this.displayCopyRecipientError();
        }

        CasefileActions.setSensitiveData(checked);
    };

    _togglesignOnMeeting = (checked) => {
        CasefileActions.setSignOnMeeting(checked);
    };

    _updateCasefileLanguage = (language: string) => {
        const { casefile } = this.props;

        casefile.language = language;
        CasefileActions.updateCasefile(casefile);
    };

    _updateFolderId = (event) => {
        CasefileActions.changeFolder(event.target.value);
    };

    _updateCasefileTypeId = async (event) => {
        let typeId = event.target.value;

        CasefileActions.setCaseTypeId(typeId, true); // force refresh of document type values

        // Reset roles and document types after changing casefile type via URI.
        SignerActions.resetSignerRoles();
    };

    _updateVisibilityMode = (event) => {
        const modeCode = parseInt(event.target.value, 10);

        CasefileActions.setVisibilityMode(modeCode);
    };

    displaySensitiveDataInfo = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const config = {
            component: (
                <div className="casefile-modal-v2">
                    <div className="casefile-modal-v2-title">
                        {i18n`Sensitive information`}

                        <div className="close" onClick={modal.hide}>
                            <div className="far fa-times"></div>
                        </div>
                    </div>

                    <p>
                        {i18n`Normally, Penneo sends the signed documents as attachments in emails. However, certain information is not suitable for sending via email and requires strict access control.`}
                    </p>

                    <p>
                        {i18n`If you are sending documents containing such information that you don't want to send via email, you should enable this feature, which acts as an extra security and compliance layer.`}
                    </p>

                    <h4 className="tag">{i18n`Please note`}</h4>
                    <ul className="list">
                        <li>
                            {i18n`All recipients will be asked to authenticate with an Electronic ID, before they can read and sign the documents`}
                        </li>
                        <li>
                            {i18n`You will not be able to use the "Copy recipient" functionality`}
                        </li>
                        <li>
                            {i18n`The signed documents will not be sent out by email as attachments, but must be downloaded from Penneo.com`}
                        </li>
                    </ul>
                    <br />
                    <div className="text-center">
                        <Button
                            theme="green"
                            icon="far fa-check"
                            onClick={modal.hide}>
                            {i18n`I understand`}
                        </Button>
                    </div>
                </div>
            ),
        };

        modal.show(config);
    };

    toggleAdvanced = () => {
        this.setState({ advanced: !this.state.advanced });
    };

    handleToggleConditionalSigning = (
        available: boolean,
        shouldBeEnabled: boolean,
        count: number = this.props.conditionalSigningOptions.count,
        role: string = this.props.conditionalSigningOptions.role
    ) => {
        CasefileV2Actions.updateConditionalSigningOptions({
            available,
            enabled: shouldBeEnabled,
            count,
            role,
        });
    };

    renderAdvancedOptions() {
        const { casefile } = this.props;

        return (
            <div className="box-gray mt">
                <label className="mt0">{i18n`Signing options`}</label>
                <Checkbox
                    checked={casefile.signOnMeeting}
                    onChange={(value) => this._togglesignOnMeeting(value)}
                    label={i18n`This case will be signed in a meeting`}
                />

                <TextInput
                    label={
                        <span>
                            {i18n`Reference`}
                            &nbsp;
                            <HelpTooltip
                                width={300}
                                text={
                                    i18n(
                                        `The reference can be used to identify the case file with an external ` +
                                            `piece of data that can later be used to cross-reference a case file ` +
                                            `with data from another system or to integrate to a process in your ` +
                                            `organization.`
                                    ) +
                                    i18n(
                                        `This doesn't affect the case file process and isn't shown in the ` +
                                            `documents or to the signers.`
                                    )
                                }
                            />
                        </span>
                    }
                    name="reference"
                    value={casefile.reference}
                    placeholder={i18n`Enter case reference`}
                    onChange={(value) =>
                        this.updateCasefile('reference', value)
                    }
                />
            </div>
        );
    }

    renderConditionalSigning = () => {
        const { conditionalSigningOptions } = this.props;

        return (
            <div className="mb">
                <label>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: i18n`Minimum number of <u>${conditionalSigningOptions.role}</u> signing`,
                        }}
                    />
                </label>
                {conditionalSigningOptions.enabled ? (
                    <div className="conditional-signing">
                        <NumberInput
                            onChange={(count: number) =>
                                this.handleToggleConditionalSigning(
                                    true,
                                    true,
                                    count,
                                    conditionalSigningOptions.role
                                )
                            }
                            min={1}
                            value={conditionalSigningOptions.count}
                        />
                        <div
                            className="conditional-signing-switch-off inline-block"
                            onClick={() =>
                                this.handleToggleConditionalSigning(true, false)
                            }>
                            {i18n('Disable')} <i className="far fa-times"></i>
                        </div>
                    </div>
                ) : (
                    <div className="conditional-signing">
                        <span
                            className="conditional-signing-switch-on"
                            onClick={() =>
                                this.handleToggleConditionalSigning(true, true)
                            }>
                            {i18n('Enable')} <i className="far fa-plus" />
                        </span>
                    </div>
                )}
            </div>
        );
    };

    handleShowFlowDetails = () => {
        if (launchDarkly.variation(Flags.NEW_FLOW_OVERVIEW)) {
            return this.setState({
                showFlowDetails: !this.state.showFlowDetails,
            });
        }

        this.props.showCasefileDetails();
    };

    render() {
        const {
            casefile,
            action,
            linkParams,
            casefileTypes,
            user,
        } = this.props;

        // Folders
        const { folders, selectedFolderId } = this.props;

        // Validation errors
        const { errors } = this.props.casefileValidation;

        // Show errors if they have been notified on this route
        const overrideShow = this.props.navigationRoutes[0].errorSeen;

        const { advanced } = this.state;

        const currentCasefileType = casefileTypes.find(
            (ct) => ct.id === casefile.caseFileTypeId
        );

        return (
            <div className="casefile-process-v2">
                <FeatureFlag flag={Flags.NEW_FLOW_OVERVIEW}>
                    {this.state.showFlowDetails && (
                        <SidePanel onClose={this.handleShowFlowDetails}>
                            <BetterFlowPage
                                casefileType={currentCasefileType}
                                onClose={this.handleShowFlowDetails}
                            />
                        </SidePanel>
                    )}
                </FeatureFlag>

                <div className="header">
                    <h1>
                        {casefile.id
                            ? i18n`Edit details of your case file`
                            : i18n`Let's start with a few details`}
                        <span className="dot">.</span>
                    </h1>
                    <p>{i18n`Give a name to your case file so that you can find it later in the archive
                        and choose a flow for the signing process.`}</p>
                </div>

                <div className="body form-v2">
                    {/**
                     * Experimental feature: Client selector from DK registry
                     */}
                    {LaunchDarkly.variation(
                        Flags.EXPERIMENT_CLIENT_SELECTOR_ENABLED
                    ) &&
                        !LaunchDarkly.variation(Flags.CLIENTS) && (
                            <ClientSelector
                                useClientApi={
                                    (!(
                                        LaunchDarkly.variation(
                                            Flags.NON_DANISH_VATIN
                                        ) === true
                                    ) as boolean) || false //Only apply api if Danish VATIN/CVR
                                }
                                onSwitchEntity={(v) => {
                                    if (v !== 'person') return;

                                    (document.querySelector(
                                        "input[name='title']"
                                    ) as HTMLElement)?.focus();
                                }}
                            />
                        )}

                    {/**
                     * Client selector from customer settings
                     */}
                    {LaunchDarkly.variation(Flags.CLIENTS) && (
                        <div className="mb">
                            <label className="label">{i18n`Clients`}</label>

                            {((linkParams.casefileId && casefile.id) ||
                                !linkParams.casefileId) && (
                                <AutocompleteClients
                                    linkEntity={{
                                        entityType:
                                            ClientLinkEntityType.casefile,
                                        entityId: casefile.id,
                                    }}
                                    onCreate={
                                        this.props.handleNewClientCreation
                                    }
                                    user={user}
                                />
                            )}
                        </div>
                    )}

                    {/* Case file Name */}
                    <div className="mb mt">
                        <InputValidation
                            rules={[
                                {
                                    error: errors.title,
                                    test: () =>
                                        overrideShow ||
                                        typeof casefile.title !== 'undefined',
                                },
                            ]}
                            triggers={casefile.title}>
                            <TextInput
                                label={i18n`Title`}
                                name="title"
                                value={casefile.title}
                                placeholder={i18n`Enter case file title`}
                                required={true}
                                autoComplete="off"
                                autoFocus={
                                    !LaunchDarkly.variation(
                                        Flags.EXPERIMENT_CLIENT_SELECTOR_ENABLED
                                    ) && true
                                }
                                onChange={(value) =>
                                    this.updateCasefile('title', value)
                                }
                            />
                        </InputValidation>
                    </div>

                    {/* Case file Type */}
                    <div className="mb">
                        {LaunchDarkly.variation(
                            Flags.ACTIVATION_DATE_PER_ROUND
                        ) ? (
                            <div className="flex items-center">
                                <label className="label">
                                    {i18n`Signing flow`}&nbsp;
                                    <Tooltip
                                        showArrow={true}
                                        text={
                                            <span className="inline-block w-[300px]">
                                                {i18n`Signing Flow defines sequential order of documents and signers in Casefile`}
                                            </span>
                                        }>
                                        <i className="far fa-question-circle text-blue cursor-pointer" />
                                    </Tooltip>
                                </label>
                                {casefile.caseFileTypeId && (
                                    <div className="ml-auto">
                                        <UIButton
                                            onClick={this.handleShowFlowDetails}
                                            priority="tertiary"
                                            type="accent"
                                            size="sm">
                                            {i18n`Preview`}
                                            <i
                                                slot="rightIcon"
                                                className="far fa-arrow-right fa-lg"
                                            />
                                        </UIButton>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <label className="label">
                                {i18n`Signing flow`}&nbsp;
                                <i
                                    className="far fa-question-circle text-blue cursor-pointer"
                                    onClick={this.handleShowFlowDetails}
                                />
                            </label>
                        )}
                        <select
                            data-testid={'casefile-type'}
                            onChange={this._updateCasefileTypeId}
                            value={casefile.caseFileTypeId || -1}
                            className={
                                casefileTypes.length === 0 ? ' text-medium' : ''
                            }>
                            <option value={-1} disabled>
                                {casefileTypes.length === 0
                                    ? i18n('Loading...')
                                    : i18n('- Select a Case File Type -')}
                            </option>
                            {casefileTypes.map((type, index) => (
                                <option key={index} value={type.id}>
                                    {type.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    {/**
                     * Conditional signing
                     */}
                    {this.props.conditionalSigningOptions.available &&
                        this.renderConditionalSigning()}

                    {/* Select Folder */}
                    <div className="mb">
                        <label className="label">
                            {i18n('Store in folder')}
                        </label>
                        <CasefileFolderSelector
                            folders={folders}
                            action={action}
                            selectedFolder={selectedFolderId}
                            handleFolderChange={this.handleFolderChange}
                        />
                    </div>

                    {/* Signer visibility */}
                    <div className="mb">
                        <label htmlFor="document-visibility" className="label">
                            {i18n('Signer can see')}
                        </label>
                        <select
                            id="document-visibility"
                            value={casefile.visibilityMode}
                            onChange={this._updateVisibilityMode}>
                            <option value={0}>{i18n('All documents')}</option>
                            <option value={1}>
                                {i18n('Documents for signature')}
                            </option>
                        </select>
                    </div>

                    {/* Casefile Language */}
                    <div className="mb">
                        <label>{i18n('Language')}</label>
                        <LanguageDropdown
                            type="standalone"
                            name="language"
                            value={casefile.language}
                            skipInitCallback={true}
                            callback={this._updateCasefileLanguage}
                        />
                    </div>

                    <label>
                        {i18n`Security`}&nbsp;
                        <i className="far fa-lock-alt" />
                    </label>
                    <Checkbox
                        checked={casefile.sensitiveData}
                        onChange={(value) => this._toggleSensitiveData(value)}
                        label={i18n`This case contains sensitive information`}
                    />
                    <i
                        className="far fa-question-circle text-blue cursor-pointer"
                        onClick={this.displaySensitiveDataInfo}
                    />

                    {/* Advanced Options */}
                    <div className="mt">
                        <div
                            className="underline-link inline-block"
                            onClick={this.toggleAdvanced}>
                            {!advanced ? (
                                <span>
                                    {i18n`Show advanced options`}&nbsp;
                                    <i className="far fa-chevron-down"></i>
                                </span>
                            ) : (
                                <span>
                                    {i18n`Hide advanced options`}&nbsp;
                                    <i className="far fa-chevron-up"></i>
                                </span>
                            )}
                        </div>
                    </div>

                    {advanced && this.renderAdvancedOptions()}
                </div>

                <div className="footer form-v2">
                    <div className="footer-content right">
                        {this.props.saveAsDraftButton()}

                        <Link
                            to={{
                                name: 'casefile-documents',
                                params: linkParams,
                            }}>
                            <Button theme="blue">
                                {i18n`Save & continue`}
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

const CasefileDetailsStepConnected = connect((state: ReduxState) => ({
    casefileEmailTemplates: state.newCasefile.emailTemplates,
    userSettings: state.settings.data.user,
}))(CasefileDetailStep);

export default CasefileDetailsStepConnected;
