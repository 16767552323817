import React from 'react';
import Dropzone from 'react-dropzone';
import { i18n } from 'Language';
import DummyDocumentRow from 'Casefiles/components/casefiles/DummyDocumentRow';
import DocumentListItem from 'Casefiles/components/casefiles2/DocumentListItem';
import { DocumentEntity, DocumentType } from 'types/Document';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { UploadedDocument } from 'types/CaseFile';

type Props = {
    availableDocumentTypes: DocumentType[];
    /**
     * Regular casefile creation process uses DocumentEntity while registered
     * letters uses UploadedDocument
     */
    documents: (File | DocumentEntity | UploadedDocument)[];
    onAddFileLinkClick: () => void;
    onChangeDocumentOrder: (index: number, newIndex: number) => void;
    onDrop: (files: File[]) => void;
    onEdit?: (index: number) => void;
    onPreview: (index: number) => void;
    /** Must be provided if onEdit is not provided */
    onRemove?: (index: number) => void;
};

export default DragDropContext(HTML5Backend)(
    class DocumentDropZone extends React.Component<Props> {
        render() {
            const {
                availableDocumentTypes,
                documents,
                onAddFileLinkClick,
                onChangeDocumentOrder,
                onDrop,
                onEdit,
                onPreview,
                onRemove,
            } = this.props;

            return (
                <Dropzone
                    accept="application/pdf"
                    className="document-list-v2"
                    disableClick={true}
                    disablePreview={true}
                    onDrop={onDrop}>
                    <ul style={{ overflowY: 'scroll' }}>
                        {documents?.length === 0 && (
                            <div className="document-list-v2-empty">
                                <div className="document-list-empty-graphic">
                                    <i className="far fa-file-alt" />
                                    <i className="far fa-mouse-pointer" />
                                </div>
                                <h2>{i18n`Drag and drop files to upload`}</h2>
                                <p>{i18n`- or -`}</p>
                                <span
                                    className="underline-link"
                                    onClick={onAddFileLinkClick}>
                                    <i className="fas fa-desktop" />
                                    &nbsp;
                                    {i18n`Select files from your computer`}
                                </span>
                            </div>
                        )}

                        {/* This component needs a dummy row that attaches at least one
                            react-dnd source. If react-dnd has no sources initialized, it
                            causes a conflict with react-dropzone. (Invariant Error: Cannot
                            call hover while dragging)

                            i.e: Whenever you drop a file to the area. react-dnd will throw
                            an error because it tries to launch an event without any
                            children that can listen to it. as none of the rows are
                            initialized with the proper event listeners.

                            This is only an issue because every DocumentListItem is both a
                            drop target and a source. And the drop events are caught within
                            react-dropzone initially, which doesn't report the correct state
                            to react-dnd.

                            To read more about this error, read the linked github issues in
                            DummyDocumentRow component. */}
                        <DummyDocumentRow />

                        {documents &&
                            documents.map((file, index) => {
                                return (
                                    <DocumentListItem
                                        availableDocumentTypes={
                                            availableDocumentTypes
                                        }
                                        edit={(index) => onEdit?.(index)}
                                        file={file as UploadedDocument}
                                        index={index}
                                        key={index}
                                        onChangeDocumentOrder={
                                            onChangeDocumentOrder
                                        }
                                        preview={onPreview}
                                        remove={(index) => onRemove?.(index)}
                                    />
                                );
                            })}
                    </ul>
                </Dropzone>
            );
        }
    }
);
