import { jwtDecode } from 'jwt-decode';
import {
    OpenIDToken,
    OpenIDSubjectIdentifiers,
    OpenIDCertificateDisplayInfo,
    OpenIDCertificate,
} from 'OpenID/redux/types';
import { google } from 'OpenID/services/google';
import { ftn } from 'OpenID/services/ftn';
import { itsme } from 'OpenID/services/itsme';
import { mitID } from 'OpenID/services/mitid';
import { mitIDErhverv } from 'OpenID/services/mitidErhverv';
import { bankidSe } from 'OpenID/services/bankidse';
import { bankidNo } from 'OpenID/services/bankidno';
import { NetIdVerifier } from 'OpenID/services/netIdVerifier';
import { Intent } from '../Constants';

export const getOpenIDSubject = (idToken: string): OpenIDSubjectIdentifiers => {
    const parsedToken: OpenIDToken = jwtDecode(idToken);

    if (google.isOwnToken(parsedToken)) {
        return google.getSubject(parsedToken);
    }

    if (bankidSe.isOwnToken(parsedToken)) {
        return bankidSe.getSubject(parsedToken);
    }

    if (bankidNo.isOwnToken(parsedToken)) {
        return bankidNo.getSubject(parsedToken);
    }

    if (ftn.isOwnToken(parsedToken)) {
        return ftn.getSubject(parsedToken);
    }

    if (itsme.isOwnToken(parsedToken)) {
        return itsme.getSubject(parsedToken);
    }

    if (mitID.isOwnToken(parsedToken)) {
        return mitID.getSubject(parsedToken);
    }

    if (mitIDErhverv.isOwnToken(parsedToken)) {
        return mitIDErhverv.getSubject(parsedToken);
    }

    if (NetIdVerifier.isOwnToken(parsedToken)) {
        return NetIdVerifier.getSubject(parsedToken);
    }

    throw new Error('The token could not be identified');
};

export const getCertificateDisplayInfo = (
    certificate: OpenIDCertificate,
    intent = Intent.SIGN
): OpenIDCertificateDisplayInfo | null => {
    if (google.isOwnCertificate(certificate)) {
        return google.getCertificateDisplayInfo(certificate);
    }

    if (bankidSe.isOwnCertificate(certificate)) {
        return bankidSe.getCertificateDisplayInfo(certificate);
    }

    if (bankidNo.isOwnCertificate(certificate)) {
        return bankidNo.getCertificateDisplayInfo(certificate);
    }

    if (ftn.isOwnCertificate(certificate)) {
        return ftn.getCertificateDisplayInfo(certificate);
    }

    if (itsme.isOwnCertificate(certificate)) {
        return itsme.getCertificateDisplayInfo(certificate);
    }

    if (mitID.isOwnCertificate(certificate)) {
        return mitID.getCertificateDisplayInfo(certificate, intent);
    }

    if (mitIDErhverv.isOwnCertificate(certificate)) {
        return mitIDErhverv.getCertificateDisplayInfo(certificate);
    }

    if (NetIdVerifier.isOwnCertificate(certificate)) {
        return NetIdVerifier.getCertificateDisplayInfo(certificate);
    }

    return null;
};
