import { modal } from 'Common/components/Common/Modal';
import {
    EID_METHODS,
    isMethodAvailableToSign,
    SIGNING_METHODS,
    SigningMethod,
} from 'EID';
import React, { useEffect, useState } from 'react';
import Components from 'Exports/Components';
import { Languages } from 'Language/Constants';
import { CustomerSigningMethod } from 'types/Customer';
import { SigningComponent, SigningComponentProps } from './SigningComponent';

import { getExpectedIdentifierType, trackSigning } from './utils';
import { getOpenIdState } from 'OpenID/utils/openIdState';
import { Intent, ServiceIDs } from 'OpenID/Constants';
import launchDarkly, { Flags } from 'Common/LaunchDarkly';

import Eutl from 'Eutl';
import { useDispatch } from 'react-redux';
import { resetTriggerFinalizing, setTriggerFinalizing } from './redux/reducer';
import { InsecureSigningMethod } from 'types/Signer';
import { INSECURE_SIGNING_METHODS, ITSME_BELGIUM_QES } from 'EID/Constants';
import InsecureSignature from 'Auth/components/InsecureSignature/InsecureSignature';
import { insecureSigningMethodsDefault } from 'EID/types';
import Message from '../Common/components/Message';
import { SignerValidationParameters } from '../types/SigningProcess';
import { shouldResumeItsmeQESSigning } from 'ItsmeQES/redux/reducer';

export type SigningRequestSeenBySigner = SignerValidationParameters & {
    enableInsecureSigning: boolean;
    insecureSigningMethods?: InsecureSigningMethod[];
};

export const useSigningMethods = (
    allowedSigningMethods: CustomerSigningMethod[],
    challengeKey: string,
    languageCode: Languages,
    signingRequest: SigningRequestSeenBySigner,
    encryptedNIN?: string
) => {
    const dispatch = useDispatch();
    const expectedIdentifierType = getExpectedIdentifierType(signingRequest);

    /**
     * Due to the current implementation in SigningValidationMethod.tsx,
     * the "sign:true" parameter is required for the method to be displayed on the Validation page.
     * This is why we need to filter it out from the signing page here (line: 48).
     * TODO: When adding new validation-only methods, the above-mentioned implementation should be refactored.
     */
    let signingMethods = SIGNING_METHODS.filter(isMethodAvailableToSign).filter(
        (method) =>
            allowedSigningMethods.includes(
                method.credentialType() as CustomerSigningMethod
            ) && method.type !== CustomerSigningMethod.SMS
    );

    if (
        signingRequest.isSpecificSignerExpected ||
        signingRequest.expectedVatin
    ) {
        signingMethods = signingMethods.filter((method) =>
            method.canIdentifyBy(expectedIdentifierType)
        );
    }

    /**
     * If the flag for splitting signing methods is on
     * we check for insecure methods in the signing request
     * and add them separately to the signingMethods list,
     * removing the simple 'Touch' that was there initially
     */
    if (
        launchDarkly.variation(Flags.SPLIT_INSECURE_SIGNING_METHODS) &&
        allowedSigningMethods.includes(CustomerSigningMethod.IMAGE) &&
        signingRequest.enableInsecureSigning
    ) {
        const removeTouch = signingMethods.filter(
            (method) =>
                (method.credentialType() as CustomerSigningMethod) !==
                CustomerSigningMethod.IMAGE
        );
        const enabledInsecureMethods = INSECURE_SIGNING_METHODS.filter(
            (method) =>
                signingRequest?.insecureSigningMethods?.includes(
                    method.credentialType() as InsecureSigningMethod
                )
        );

        signingMethods = [...removeTouch, ...enabledInsecureMethods];
    }

    /**
     * We make a reference to the original signing components object
     * that we can potentially modify
     */
    let signingComponents:
        | typeof Components
        | (Omit<typeof Components, 'image'> &
              Record<
                  InsecureSigningMethod,
                  typeof InsecureSignature
              >) = Components;

    /**
     * If the flag for splitting signing methods is on
     * we must replace the 'image' (Touch) component for the new InsecureMethods one
     * and also add the remaining new methods
     */
    if (launchDarkly.variation(Flags.SPLIT_INSECURE_SIGNING_METHODS)) {
        signingComponents = {
            ...Components,
            ...{
                image: InsecureSignature,
                draw: InsecureSignature,
                text: InsecureSignature,
            },
        };
    }

    const [methodSelected, setMethodSelected] = useState<
        SigningMethod | undefined
    >();
    const [showQRSS, setShowQRSS] = useState<boolean>(false);

    /**
     * Making props for the SigningComponent as a method,
     * so we can extend it if required (fx. for InsecureMethods)
     */
    const signingComponentProps = (): SigningComponentProps => {
        let props = {
            languageCode: languageCode,
            legacyServiceName: methodSelected!.title,
            serviceId: methodSelected!.type as CustomerSigningMethod,
            challengeKey: challengeKey,
            encryptedNIN,
        };

        /**
         * If the flag is on, we check if the selected method is
         * in the list of insecure methods and, if so
         * we add an extra prop with this method
         */
        if (
            launchDarkly.variation(Flags.SPLIT_INSECURE_SIGNING_METHODS) &&
            insecureSigningMethodsDefault.includes(
                methodSelected?.credentialType() as InsecureSigningMethod
            )
        ) {
            props = {
                ...props,
                ...{
                    insecureMethod: methodSelected!.credentialType() as InsecureSigningMethod,
                },
            };
        }

        return props;
    };

    const handleMethodSelection = (method: SigningMethod) => {
        setMethodSelected(method);
        trackSigning('Signing method selected', {
            method: method?.extendedTitle || method.title,
        });
    };

    const handleCloseModal = () => {
        setMethodSelected(undefined);
        trackSigning('Signing modal closed');

        /**
         * When the modal is closed, it triggers the signing finalization
         * NOTE: This will only have an effect if the user is in the archive selection stage
         */
        dispatch(setTriggerFinalizing());
    };

    useEffect(() => {
        /**
         * We check if the user started signing via OpenID
         * If so, we open the preselected Signing method modal
         */
        const openIdState = getOpenIdState();

        if (openIdState?.processed) {
            setShowQRSS(launchDarkly?.variation(Flags.QRSS_SIGNATURE) ?? false);

            /**
             * If Intent is NAP we merge signingMethods with EID_METHODS[], since we are using signing methods like any.mitid.dk for NAP
             * which is not and should not be available in SigningMethods[]
             */
            const methodPreselected =
                openIdState.intent === Intent.NAP
                    ? ([...signingMethods, ...EID_METHODS].find(
                          (method) => method.type === openIdState.serviceId
                      ) as SigningMethod)
                    : signingMethods.find(
                          (method) => method.type === openIdState.serviceId
                      );

            if (methodPreselected) {
                setMethodSelected(methodPreselected);
            }
        }

        if (shouldResumeItsmeQESSigning()) {
            setMethodSelected(ITSME_BELGIUM_QES);
        }
    }, [signingMethods]);

    const content = () =>
        showQRSS ? (
            <Eutl
                method={methodSelected as SigningMethod}
                languageCode={languageCode}
                serviceId={methodSelected!.type as ServiceIDs}
                challengeKey={challengeKey}
            />
        ) : signingComponents[methodSelected!.type] ? (
            <SigningComponent {...signingComponentProps()}>
                {signingComponents[methodSelected!.type]}
            </SigningComponent>
        ) : (
            <Message type="error">
                {/* This message should only ever be visible in development when adding new signing methods. */}
                <p>No signing component found for {methodSelected!.type}.</p>
            </Message>
        );

    useEffect(() => {
        if (methodSelected && challengeKey) {
            // resets the signing finalization trigger, as a cleanup before signing
            dispatch(resetTriggerFinalizing());

            modal.show({
                className: 'signing-modal',
                body: content(),
                onClose: () => handleCloseModal(),
            });
        }
    });

    return { signingMethods, handleMethodSelection };
};
