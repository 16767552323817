import Loader from 'Common/components/Loading';
import { i18n } from 'Language';
import React from 'react';
import { DragSource, DropTarget } from 'react-dnd';
import { UploadedDocument } from 'types/CaseFile';
import { DocumentEntity, DocumentType } from 'types/Document';
import { renderFileLabelWithTooltip } from './utils';

type Props = {
    availableDocumentTypes: DocumentType[];
    connectDragPreview?: Function;
    connectDragSource?: Function;
    connectDropTarget?: Function;
    edit: (index: number) => void | undefined;
    file: File | UploadedDocument;
    index: number;
    onChangeDocumentOrder: (index: number, newIndex: number) => void;
    preview: Function;
    remove: (index: number) => void | undefined;
};

class DocumentRow extends React.Component<Props> {
    node: HTMLElement | null = null;

    changeDocumentOrder = (index: number, newIndex: number) => {
        this.props.onChangeDocumentOrder(index, newIndex);
    };

    render() {
        let {
            index,
            edit,
            preview,
            file,
            availableDocumentTypes,
            remove,
        } = this.props;

        // react-dnd
        let {
            connectDragSource,
            connectDragPreview,
            connectDropTarget,
        } = this.props;

        function isUploadingFile(
            document: File | DocumentEntity | UploadedDocument
        ): document is File {
            return !('id' in document);
        }

        let documentType = availableDocumentTypes.filter((t) => {
            if (isUploadingFile(file)) {
                return undefined;
            } else {
                return file.documentTypeId === t.id;
            }
        })[0];

        let fetchingDocumentTypes = availableDocumentTypes.length === 0;

        let isDocumentTypeAvailable =
            availableDocumentTypes.length > 0 && documentType;

        // Create a dragging container.
        return connectDragPreview?.(
            connectDropTarget?.(
                <li
                    ref={(node) => (this.node = node)}
                    className="document-list-item-v2">
                    <div className="filename-container">
                        {!isUploadingFile(file) &&
                            file.id &&
                            connectDragSource?.(
                                <i className="far fa-grip-vertical" />
                            )}
                        <i className="far fa-file-pdf text-error" />
                        <span className="filename" data-filename={file.name}>
                            {renderFileLabelWithTooltip({
                                fileName: file.name,
                                tooltipOffset: 22,
                            })}
                        </span>
                    </div>
                    <div className="type-actions-container">
                        <div>
                            {!isUploadingFile(file) && file.documentTypeId && (
                                <span className="type text-penneo-blue">
                                    {isDocumentTypeAvailable ? (
                                        <>
                                            <i className="fas fa-tag" />
                                            {documentType.name}
                                        </>
                                    ) : (
                                        <span>
                                            {fetchingDocumentTypes ? (
                                                <Loader size="small" />
                                            ) : (
                                                i18n`Select a type`
                                            )}
                                        </span>
                                    )}
                                </span>
                            )}
                        </div>
                        {!isUploadingFile(file) && file.id ? (
                            <span className="actions-container">
                                <i
                                    className="far fa-eye text-penneo-cyan mr"
                                    onClick={preview.bind(null, index)}
                                />
                                {edit ? (
                                    <i
                                        className="far fa-edit text-penneo-cyan"
                                        onClick={edit.bind(null, index)}
                                    />
                                ) : (
                                    <i
                                        className="far fa-trash text-red"
                                        onClick={() => remove(index)}
                                    />
                                )}
                            </span>
                        ) : (
                            <span className="actions-container">
                                <span className="uploading-text">
                                    {i18n`Uploading`}...
                                </span>
                                <i className="far fa-circle-notch text-penneo-cyan fa-spin" />
                            </span>
                        )}
                    </div>
                </li>
            )
        );
    }
}

// Row has two behaviours: it is a source and a target, because
// it is able to be dragged and to be reordered.

// Source callbacks:
const source = {
    // returns an object with useful properties when dragging event is over
    beginDrag(props) {
        return {
            index: props.index,
        };
    },
};

// Target callbacks:
const target = {
    hover(props, monitor, component) {
        const dragIndex = monitor.getItem().index;
        const hoverIndex = props.index;

        // prevents replacing items with themselves
        if (dragIndex === hoverIndex) {
            return;
        }

        // Determine rectangle on screen
        // @see https://stackoverflow.com/a/40581747/781779
        const hoverBoundingRect = component.node.getBoundingClientRect();

        // Get vertical middle
        const hoverMiddleYDown =
            hoverBoundingRect.top - hoverBoundingRect.bottom;
        const hoverMiddleYUp = hoverBoundingRect.bottom - hoverBoundingRect.top;

        // Determine mouse position
        const clientOffset = monitor.getClientOffset();

        // Get pixels to the top
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;

        // Only perform the move when the mouse has crossed the border of the other card

        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleYDown) {
            return;
        }

        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleYUp) {
            return;
        }

        // Perform reorder
        component.changeDocumentOrder(dragIndex, hoverIndex);

        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        monitor.getItem().index = hoverIndex;
    },
};

// Connect the callbacks
let Target = DropTarget('DocumentRow', target, (connect) => ({
    connectDropTarget: connect.dropTarget(),
}))(DocumentRow);

Target = DragSource('DocumentRow', source, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
}))(Target);

export default Target;
