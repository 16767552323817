import PropTypes from 'prop-types';
import React from 'react';
import { camelCase } from 'lodash';
import { Link } from 'react-router';
import { TranslationActions, LanguageDropdown } from 'Language';
import analytics from 'Common/Analytics';
import { i18n } from 'Language/i18n';
import moment from 'moment';

// Stores
import CredentialStore from '../../stores/CredentialStore';
import AuthStore from '../../stores/AuthStore';
import PasswordStore from '../../stores/PasswordStore';
import IntegrationStore from '../../stores/IntegrationStore';
import AuthMethodsStore from '../../stores/AuthMethodsStore';

// Actions
import CredentialActions from '../../actions/CredentialActionCreators';
import PasswordActions from '../../actions/PasswordActionCreators';
import IntegrationActions from '../../actions/IntegrationActionCreators';
import AuthMethodsActions from '../../actions/AuthMethodsActions';
import OpenIDActions from '../../actions/OpenIDActions';

import { images, AUTH_METHODS } from 'Constants';
import { USER_SETUP_METHODS, findByType } from '../../../EID';
import LoginUtils from 'Auth/utils/login';
import Button from 'Common/components/Button';
import ItsmeButton from 'Common/components/ItsmeButton';
import { Intent } from 'OpenID/Constants';
import { isMethodAvailableToSetupUser } from '../../../EID/utils';
import { ITSME_BELGIUM_CONNECT } from '../../../EID/Constants';

export default class ActivateUser extends React.Component {
    static contextTypes = {
        router: PropTypes.object,
    };

    static propTypes = {
        user: PropTypes.object,
        params: PropTypes.object,
        children: PropTypes.object,
        location: PropTypes.object,
        methodRoutes: PropTypes.object,
        logo: PropTypes.string,
    };

    static defaultProps = {
        user: AuthStore.getUser(),
        methodRoutes: USER_SETUP_METHODS.concat(AUTH_METHODS)
            .filter(isMethodAvailableToSetupUser)
            .reduce((prev, method) => {
                prev[method.credentialType()] = {
                    title: method.title,
                    type: method.type,
                    openid: method.openid || false,
                    path: method.paths ? method.paths.activate : null,
                };

                return prev;
            }, {}),
    };

    state = {
        authProviders: [],
        credentials: {},
        children: {},
        isValid: false,
        isConfigured: false,
        fetched: [],
        configured: [],
    };

    componentDidMount() {
        CredentialStore.addChangeListener(this.onChange);
        PasswordStore.addChangeListener(this.onPasswordChange);
        IntegrationStore.addChangeListener(this.onIntegrationChange);
        AuthMethodsStore.addChangeListener(this.onAuthMethodsChange);

        this.loadData();
    }

    componentDidUpdate(_, prevState) {
        /**
         * When the setup request is completed, we have to mark it as completed to enable the user account.
         * This happens after the user has configured all of the required auth methods
         *
         * @note: We evaluate against `isConfigured()` instead to this.state.isConfigured to avoid
         * users being prematurely activated by modifying the component's state (for example. using the React Dev Tools)
         * i.e. The internal logic for isConfigured is more complicated to fake than just updating a state boolean value.
         */
        if (!prevState.isConfigured && this.isConfigured() === true) {
            this.completeSetup();
        }
    }

    componentWillUnmount() {
        CredentialStore.removeChangeListener(this.onChange);
        PasswordStore.removeChangeListener(this.onPasswordChange);
        IntegrationStore.removeChangeListener(this.onIntegrationChange);
        AuthMethodsStore.removeChangeListener(this.onAuthMethodsChange);

        // Clear Stores
        PasswordStore.resetStore();
    }

    loadData = () => {
        CredentialActions.fetchCurrentUserCredentials();
        PasswordActions.fetchPasswordCredentials();
        IntegrationActions.fetchCredentials();
        IntegrationActions.fetchActivationStatus();
        AuthMethodsActions.fetchCredentials();
    };

    updateFetchedMethods = (method) => {
        let { fetched } = this.state;

        if (fetched.indexOf(method) === -1) {
            fetched.push(method);
        }

        // Clone array
        let newFetched = fetched.slice(0);

        this.setState({
            fetched: newFetched,
            isConfigured: this.isConfigured(),
        });
    };

    updateConfiguredMethods = (method) => {
        let { configured } = this.state;

        configured.push(method);

        // Clone array
        let newConfigured = configured.slice(0);

        this.setState({
            configured: newConfigured,
            nextMethod: this.getNextOption(),
            isConfigured: this.isConfigured(),
        });
    };

    onPasswordChange = () => {
        let credentials = PasswordStore.getPasswordCredentials();

        if (credentials) {
            this.updateConfiguredMethods('classic');
            this.setState({
                passwordCredentials: credentials,
            });
        }

        this.updateFetchedMethods('classic');
    };

    onIntegrationChange = () => {
        let credentials = IntegrationStore.getCredentials();
        let activationConfirmed = IntegrationStore.getActivationStatus();

        if (credentials) {
            this.setState({
                apiCredentials: credentials,
            });
        }

        if (credentials && activationConfirmed) {
            this.updateConfiguredMethods('api');
        }

        this.updateFetchedMethods('api');
    };

    onAuthMethodsChange = () => {
        let credentials = AuthMethodsStore.getCredentials();

        if (credentials && credentials.length > 0) {
            this.setAuthMethods(credentials);
        }

        USER_SETUP_METHODS.forEach((method) =>
            this.updateFetchedMethods(method.credentialType())
        );
    };

    setAuthMethods = (credentials) => {
        for (let i = credentials.length - 1; i >= 0; i--) {
            const credential = credentials[i];
            const method = findByType(USER_SETUP_METHODS, credential.provider);

            if (!method) {
                continue;
            }

            this.updateConfiguredMethods(method.credentialType());

            // The `xyzCredentials` state props are only needed for providers
            // for which we do the login "embedded" in our app. OpenID providers
            // send the user to a new page, so they don't need them.
            if (!method.openid) {
                // bankid_no -> bankidNO
                const stateKey = `${camelCase(method.type)}Credentials`;

                this.setState({
                    [stateKey]: credential,
                });
            }
        }
    };

    onChange = () => {
        const userCredentials = CredentialStore.getCurrentUserCredentials();

        userCredentials.allowed = userCredentials.allowed.filter((cred) => {
            const lookup = findByType(
                USER_SETUP_METHODS.concat(AUTH_METHODS),
                cred
            );

            return isMethodAvailableToSetupUser(lookup);
        });
        this.setState({
            credentials: CredentialStore.getCurrentUserCredentials(),
            isConfigured: this.isConfigured(),
        });
    };

    getOptions = (routeOptions) => {
        let { credentials } = this.state;

        if (!credentials.allowed) {
            return false;
        }

        let options = [];

        for (let i = 0; i < credentials.allowed.length; i++) {
            let option = routeOptions[credentials.allowed[i]];

            if (option) {
                options.push(option);
            }
        }

        return options;
    };

    isLoaded = () => {
        let { credentials } = this.state;

        if (!credentials.allowed) {
            return false;
        }

        let { allowed } = credentials;
        let { fetched } = this.state;

        for (let i = 0; i < allowed.length; i++) {
            if (fetched.indexOf(allowed[i]) === -1) {
                return false;
            }
        }

        return true;
    };

    isConfigured = () => {
        let { credentials } = this.state;

        if (!credentials.allowed) {
            return false;
        }

        let { allowed } = credentials;
        let { configured } = this.state;

        for (let i = 0; i < allowed.length; i++) {
            if (configured.indexOf(allowed[i]) === -1) {
                return false;
            }
        }

        return true;
    };

    renderConfiguredList = () => {
        const { methodRoutes } = this.props;
        let { credentials, configured } = this.state;

        if (!credentials.allowed) {
            return false;
        }

        let { allowed } = credentials;

        return (
            <ul className="method-list">
                {allowed.map((credential, index) => (
                    <li key={index}>
                        <span className="tag">
                            {i18n('Step')} {index + 1}
                        </span>
                        <span className="title">
                            {i18n('Activate')} {methodRoutes[credential].title}
                        </span>
                        <span>
                            {configured.indexOf(credential) !== -1 && (
                                <i className="fa fa-check"></i>
                            )}
                        </span>
                    </li>
                ))}
            </ul>
        );
    };

    getNextOption = () => {
        let { credentials, configured } = this.state;

        if (!credentials.allowed) {
            return false;
        }

        let { allowed } = credentials;

        for (let i = 0; i < allowed.length; i++) {
            if (configured.indexOf(allowed[i]) === -1) {
                return allowed[i];
            }
        }
    };

    createNextLink = (type) => {
        if (!type) {
            return false;
        }

        const { params, methodRoutes } = this.props;
        const method = methodRoutes[type];

        // Display custom button for itsme activation
        if (method.openid && type === 'itsme.be') {
            return (
                <ItsmeButton
                    theme="tangerine"
                    onClick={this.openIdInit.bind(
                        this,
                        ITSME_BELGIUM_CONNECT.type
                    )}>
                    {i18n`Sign up with itsme`}
                </ItsmeButton>
            );
        }

        if (method.openid) {
            return (
                <Button
                    onClick={this.openIdInit.bind(this, method.type)}
                    theme="blue">
                    {i18n('Continue to configure')} {i18n(method.title)}
                </Button>
            );
        }

        const route = {
            name: method.path,
            params: params,
        };

        return (
            <Link to={route}>
                <Button theme="blue">
                    {i18n('Continue to configure')} {i18n(method.title)}
                </Button>
            </Link>
        );
    };

    openIdInit = (provider) => {
        let state = {
            inviteToken: this.props.params.token, // Get preshared token from URL parameters
        };

        OpenIDActions.init(provider, Intent.ACTIVATION, state);
    };

    renderWelcomeMessage = () => {
        const user = AuthStore.getUser();
        const isLoaded = this.isLoaded();
        const nextOption = this.getNextOption();
        const isFirstOption = this.state.configured.length === 0;
        const method = this.props.methodRoutes[nextOption];

        return (
            <div className="welcome-message">
                <h2>
                    {i18n('Welcome to Penneo')}, {user.fullName}
                </h2>
                <h3>
                    {i18n(`Activate all login methods listed below to activate
                     your account and be able to continue to the Penneo application.`)}
                </h3>

                <div>{this.renderConfiguredList()}</div>

                <p>
                    <br />
                    {isFirstOption
                        ? i18n`Click on continue to start configuring your account.`
                        : i18n`Click below to connect ${method.title} to your account`}
                </p>
                <br />

                {isLoaded && this.createNextLink(nextOption)}
            </div>
        );
    };

    renderSuccessMessage = () => {
        return (
            <div className="welcome-message">
                <h2>{i18n("You're ready to use Penneo")}!</h2>
                <h3>
                    {i18n(`All the necessary authentication methods have been
                                configured properly`)}
                </h3>

                <div>{this.renderConfiguredList()}</div>

                <p>
                    <br />
                    {i18n(`Click below to login onto the application`)}
                </p>
                <br />

                <a href="#" onClick={this.redirectToArchive}>
                    <Button theme="green" size="large">
                        {i18n(`Start Penneo`)}
                    </Button>
                </a>
            </div>
        );
    };

    // Mark the activation process as completed and track activation in analytics.
    completeSetup = async () => {
        await CredentialActions.completeSetupRequest();

        analytics.track('user activated', null, {
            methods: this.state.configured,
            'signup date': moment().toISOString(),
        });
    };

    redirectToArchive = async (event) => {
        event.preventDefault();

        let path = LoginUtils.getDefaultRoute();

        // @duplicate: CallbackContainer.jsx
        if (path.type === 'route') {
            return this.context.router.replace(path.route);
        }

        return window.location.replace(path.url);
    };

    renderMethodContent = () => {
        let user = AuthStore.getUser();
        let {
            bankidSeCredentials,
            nemidCredentials,
            bankidNoCredentials,
            passwordCredentials,
            apiCredentials,
        } = this.state;
        let nextOption = this.getNextOption();

        return React.cloneElement(this.props.children, {
            bankidSeCredentials,
            bankidNoCredentials,
            nemidCredentials,
            apiCredentials,
            passwordCredentials,
            onActivateSuccess: this.onActivateSuccess,
            user: user,
            nextLink: this.createNextLink(nextOption),
            nextOption: nextOption,
        });
    };

    onActivateSuccess = () => {
        if (this.state.isConfigured) {
            let { router } = this.context;

            router.push({
                name: 'invite',
                params: this.props.params,
            });
        }
    };

    redirectToNextMethod = () => {
        let nextOption = this.getNextOption();
        let { params, methodRoutes } = this.props;

        let { router } = this.context;

        let route = {
            name: methodRoutes[nextOption].path,
            params: params,
        };

        router.push(route);
    };

    getCurrentItem = () => {
        const { methodRoutes } = this.props;
        let { router } = this.context;

        let { credentials } = this.state;

        if (!credentials.allowed) {
            return false;
        }

        for (let i in methodRoutes) {
            if (!methodRoutes.hasOwnProperty(i)) {
                continue;
            }

            let route = {
                name: methodRoutes[i].path,
                params: this.props.params,
            };

            if (router.isActive(route)) {
                return {
                    type: i,
                    index: credentials.allowed.indexOf(i),
                    item: methodRoutes[i],
                };
            }
        }
    };

    successMessage = () => {
        return (
            <div className="auth-page">
                <div className="auth-page-container activate-user">
                    {this.renderLanguageSelector()}
                    {this.renderSuccessMessage()}
                </div>
            </div>
        );
    };

    open = () => {
        this.setState({
            open: true,
        });
    };

    close = () => {
        this.setState({
            open: false,
        });
    };

    onLanguageChange = (languageCode) => {
        TranslationActions.setLanguage(languageCode, { persist: true });
    };

    renderLanguageSelector = () => {
        const { open } = this.state;

        return (
            <div
                className={'language-dropdown-login' + (open ? ' open' : '')}
                onMouseEnter={this.open}
                onMouseLeave={this.close}>
                <LanguageDropdown
                    closeDropdown={this.close}
                    callback={this.onLanguageChange}
                    persist={false}
                />
            </div>
        );
    };

    render() {
        const isLoaded = this.isLoaded();

        if (!isLoaded) {
            return false;
        }

        if (this.isConfigured()) {
            return this.successMessage();
        }

        const { methodRoutes } = this.props;
        const options = this.getOptions(methodRoutes);

        if (!options) {
            return false;
        }

        const currentItem = this.getCurrentItem();

        const logo = this.props.logo || `${images}/penneo-white.png`;

        return (
            <div className="auth-page">
                <div className="branding-logo">
                    <img src={logo} height="35" />
                </div>

                <div className="auth-page-container">
                    <div className="activate-user">
                        {this.renderLanguageSelector()}

                        {!this.props.children && this.renderWelcomeMessage()}

                        {this.props.children && (
                            <div className="activation-content">
                                <h2>
                                    {i18n('Step')} {currentItem.index + 1}
                                    :&nbsp;
                                    {currentItem.type === 'api' ? (
                                        <span>
                                            {i18n('Copy your API Keys')}
                                        </span>
                                    ) : (
                                        <span>
                                            {i18n('Activate')}{' '}
                                            {currentItem.item.title}
                                        </span>
                                    )}
                                </h2>
                                <div className="auth-method-content">
                                    {this.renderMethodContent(
                                        this.props.children
                                    )}
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="activate-user__footnote mt text-center">
                        <i className="activate-user__footnote__icon far fa-info-circle" />
                        <strong>{i18n`Please note`}!</strong>{' '}
                        {i18n`You won't be able to log in until you finish the setup process.`}
                    </div>
                </div>
            </div>
        );
    }
}
