import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { i18n, TranslationStore } from 'Language';

// Components
import ActivityGraph from './ActivityGraph';
import StartWorkWidget from './StartWorkWidget';
import ExpiresSoonWidget from './ExpiresSoonWidget';
import CompletedRecentlyWidget from './CompletedRecentlyWidget';
import MyPendingSignaturesWidget from './MyPendingSignaturesWidget';
import { FeedbackPanelWidget } from './FeedbackPanelWidget';
import { UserEntity } from 'types/User';
import { CustomerEntity } from 'types/Customer';

import './dashboard.scss';
import { isV2Signing, signingUrlBase } from 'Signing/utils';
import { notify } from 'react-notify-toast';
import { clearOpenIdState } from 'OpenID/utils/openIdState';
import { AppDispatch, ReduxState } from 'Store';
import { connect } from 'react-redux';
import { resetCasefileSent } from 'Casefiles/Archive/redux/reducer';
import { resettedState } from 'RegisteredLetter/redux/reducer';
import { signingStages } from 'types/SigningProcess';
import { trackDashboardInteraction } from './utils';
import { UserSettings } from 'Settings/redux/types';
import {
    hasCasefileSendAccess,
    hasFormsAccess,
} from 'Common/utils/userPermissions';

type Props = {
    user: UserEntity;
    customer: CustomerEntity;
    dispatch: AppDispatch;
    casefileSent: boolean;
    registeredletterSent: boolean;
    userSettings: UserSettings;
};

const Dashboard = ({
    user,
    customer,
    dispatch,
    casefileSent,
    registeredletterSent,
    userSettings,
}: Props) => {
    const base = signingUrlBase();
    const language = TranslationStore.getLanguage();
    // config for notifications
    const notifyCustom = {
        background: 'transparent',
        text: '#666',
    };

    useEffect(() => {
        /**
         * Logic to show sign success/rejection notification
         */
        if (isV2Signing.check()) {
            const stage = isV2Signing.get();

            /**
             * We clear everything related to V2 signing (like OpenID state, etc)
             * and we skip tracking
             */
            if (
                stage !== signingStages.signed &&
                stage !== signingStages.rejected
            ) {
                // cleanup
                isV2Signing.clear();
                clearOpenIdState();

                return;
            }

            notify.show(
                <aside
                    className="casefile-sent-notification"
                    aria-live="assertive">
                    <p className="casefile-sent-notification-heading">
                        {stage === signingStages.signed ? (
                            <>
                                <i
                                    className="fas fa-check-circle"
                                    aria-hidden></i>{' '}
                                {i18n`Your signature has been successfully registered.`}
                            </>
                        ) : (
                            i18n`You have rejected to sign.`
                        )}
                    </p>
                    <p>
                        {i18n`You can find your documents in your`}
                        {` `}
                        <a
                            href={
                                stage === signingStages.signed
                                    ? `${base}casefiles/manage/completed`
                                    : `${base}casefiles/manage/rejected`
                            }
                            onClick={() =>
                                trackDashboardInteraction('navigation', {
                                    source: 'Signing V2 notification',
                                    to: 'archive',
                                    stage: stage,
                                })
                            }>
                            {i18n`archive`}
                        </a>
                    </p>
                </aside>,
                'custom',
                8000,
                notifyCustom
            );

            // makes sure to cleanup, so notification is not shown twice
            isV2Signing.clear();
        }
    }, [base, language]);

    useEffect(() => {
        /**
         * Logic to show casefile sending success notification
         */
        if (casefileSent || registeredletterSent) {
            notify.show(
                <aside
                    className="casefile-sent-notification"
                    aria-live="assertive">
                    <p className="casefile-sent-notification-heading">
                        <i className="fas fa-check-circle" aria-hidden></i>{' '}
                        {i18n`Your documents were successfully sent and are on the way to be signed`}
                    </p>
                    <p>
                        {i18n`You can find them in your`}
                        {` `}
                        <a
                            href={`${base}casefiles/manage/pending`}
                            onClick={() =>
                                trackDashboardInteraction('navigation', {
                                    source: 'Casefile sent notification',
                                    to: 'archive',
                                })
                            }>
                            {i18n`archive`}
                        </a>
                    </p>
                </aside>,
                'custom',
                10000,
                notifyCustom
            );

            // Reset Redux state for sent cases
            dispatch(resetCasefileSent());
            dispatch(resettedState());
        }
    }, [base, casefileSent, registeredletterSent]);

    const { active: isCustomerActive } = customer;

    const isStartWorkEnabled =
        isCustomerActive &&
        (hasCasefileSendAccess(user) || hasFormsAccess(user));
    const shouldShowFeedbackWidget = !userSettings.feedbackPanel?.closedDate;

    return (
        <main id="content" className="dashboard-new">
            <Helmet>
                <title>{i18n`Dashboard`}</title>
            </Helmet>
            <h1 className="sr-only">Dashboard</h1>
            <section className="dashboard-new-widgets-grid">
                <ActivityGraph />
                <StartWorkWidget disabled={!isStartWorkEnabled} user={user} />
                <ExpiresSoonWidget />
                <CompletedRecentlyWidget />
                <MyPendingSignaturesWidget />
                {shouldShowFeedbackWidget && <FeedbackPanelWidget />}
            </section>
        </main>
    );
};

export default connect((state: ReduxState) => ({
    casefileSent: state.archive.sent,
    registeredletterSent: state.registeredLetter.view.isSent,
    userSettings: state.settings.data.user,
}))(Dashboard);
