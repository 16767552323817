import { renderFileLabelWithTooltip } from 'Casefiles/components/casefiles2/utils';
import classnames from 'classnames';
import { i18n } from 'Language';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { ReduxState } from 'Store';
import { CaseFileEntity } from 'types/CaseFile';
import { DocumentEntity } from 'types/Document';
import './casefile-documents.scss';
import { fetchDocument } from './redux/actions';
import StatusBadge from './StatusBadge';
import { getDocumentStatus } from './statusCodes';

type CasefileDocumentsProps = {
    casefile: CaseFileEntity;
    title?: string;
    documents?: DocumentEntity[];
    openDocumentDetailsRoute?: {
        name: string;
        params: {
            [key: string]: any;
        };
    };
};

const CasefileDocuments = ({
    title,
    openDocumentDetailsRoute,
    casefile,
    documents = [],
}: CasefileDocumentsProps) => {
    return (
        <div>
            <h4 className="casefile-details-section-header">
                {title || i18n`Documents`}

                <span className="casefile-details-section-count">
                    {documents.length}
                </span>
            </h4>

            <div className="casefile-documents">
                <div className="casefile-documents-container">
                    {documents.map((item) => (
                        // @ts-ignore
                        <DocumentConnect
                            key={item.id}
                            document={item}
                            openDocumentDetailsRoute={openDocumentDetailsRoute}
                            casefileId={casefile.id}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default CasefileDocuments;

type DocumentProps = {
    casefileId: number;
    document: DocumentEntity;
    thumbnail: string;
    isFetching: boolean;
    isLoaded: boolean;
    error: any;
    pageCount: number;
    dispatch: any;
    openDocumentDetailsRoute?: {
        name: string;
        params: {
            [key: string]: any;
        };
    };
};

class Document extends React.Component<DocumentProps> {
    componentDidMount() {
        const { dispatch } = this.props;

        dispatch(fetchDocument(this.props.document.id));
    }

    render() {
        const {
            casefileId,
            isFetching,
            document,
            error,
            thumbnail,
            openDocumentDetailsRoute,
        } = this.props;
        const status = getDocumentStatus(document.status);

        const link = openDocumentDetailsRoute || {
            name: 'document-details',
            params: {
                casefileId: casefileId,
                documentId: document.id,
            },
        };

        const thumbnailClassName = classnames('casefile-document-thumbnail', {
            isFetching: 'loading',
        });

        return (
            <div className="casefile-document">
                <Link className={`inherit-color no-underline`} to={link}>
                    <div className="casefile-document-hover-overlay">
                        <div className="casefile-document-hover-overlay-content">
                            <i className="fas fa-arrow-right"></i>
                        </div>
                    </div>
                    <div
                        className={thumbnailClassName}
                        style={
                            thumbnail
                                ? { backgroundImage: `url("${thumbnail}")` }
                                : {}
                        }>
                        {isFetching && (
                            <div className="casefile-document-thumbnail-icon">
                                <i className="far fa-sync fa-spin"></i>
                            </div>
                        )}
                        {error && (
                            <div className="casefile-document-thumbnail-icon">
                                <i className="far fa-eye-slash"></i>
                                <br />
                                <span className="casefile-document-thumbnail-icon-description">
                                    {i18n`No preview`}
                                </span>
                            </div>
                        )}
                    </div>
                    <div className="casefile-document-title">
                        {renderFileLabelWithTooltip({
                            fileName: document.title,
                            tooltipOffset: 10,
                        })}
                    </div>
                    <div className="casefile-document-type">
                        <i className="far fa-tag"></i>
                        &nbsp;
                        {document.type ? (
                            <span>{document.type.name}</span>
                        ) : (
                            <span>{i18n`Document`}</span>
                        )}
                    </div>

                    {document.signable ? (
                        <StatusBadge status={status} />
                    ) : (
                        <StatusBadge
                            status={{
                                name: 'Attachment',
                                details: 'This document does not get signed',
                                icon: 'paperclip',
                                color: '',
                            }}
                        />
                    )}
                </Link>
            </div>
        );
    }
}

const DocumentConnect = connect((state: ReduxState, props: DocumentProps) => {
    const data = state.caseFileDetails.documents[props.document.id] || {};

    return {
        thumbnail: data.thumbnail,
        isFetching: data.isFetching,
        isLoaded: data.isLoaded,
        error: data.error,
        pageCount: data.pageCount,
    };
})(Document);
