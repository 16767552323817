import { Languages } from 'Language/Constants';
import { SignTextFormats } from 'OpenID/Constants';
import { SignText } from 'OpenID/redux/types';
import { getCrypto } from './crypto';
import { debug } from 'Core';
import { NewSignerAPI, PublicSigningAPI } from 'Api';
import { isV2Signing } from 'Signing/utils';

export const fetchSignText = async (
    challengeKey: string,
    language: Languages = Languages.EN,
    onlyHTML = false
): Promise<SignText> => {
    /**
     * If we are coming from the New Signing page, we use the new endpoint
     * and API configuration (to use Bearer token instead of PHP sesh)
     */
    const endpoint = (
        params: any
    ): Promise<SignText[SignTextFormats.XML | SignTextFormats.HTML]> =>
        isV2Signing.check()
            ? NewSignerAPI.get(`/v2/signing-process/${challengeKey}/signtext`, {
                  ...params,
              })
            : PublicSigningAPI.get(
                  `/signingrequests/${challengeKey}/signtext`,
                  { ...params }
              );

    try {
        // Make a sign text request for each of the formats needed.
        const [HTMLSignText, XMLSignText] = await Promise.all([
            endpoint({
                language,
                format: SignTextFormats.HTML,
            }) as Promise<SignText[SignTextFormats.HTML]>,
            onlyHTML
                ? Promise.resolve({
                      xmlData: '',
                      xslData: '',
                  })
                : (endpoint({
                      language,
                      format: SignTextFormats.XML,
                  }) as Promise<SignText[SignTextFormats.XML]>),
        ]);

        /**
         * Currently, the link to the EULA in the HTML string isn't a link.
         * We have to find it and replace it for the right code
         */
        const signTextWithLink = HTMLSignText.replace(
            `https://penneo.com/eula`,
            `<a href="https://penneo.com/eula" target="_blank">https://penneo.com/eula</a>`
        );

        return {
            xml: XMLSignText,
            html: signTextWithLink,
        };
    } catch (error) {
        throw Error("Couldn't fetch sign text");
    }
};

// Extracts XML data from an XML DOM string
export const getDocumentDigestsFromSignText = (
    signTextXMLData: SignText['xml']['xmlData']
) => {
    try {
        const parser = new DOMParser(); // Create an xml parser.

        // Create XML Document
        const xmlDoc = parser.parseFromString(signTextXMLData, 'text/xml');

        // Create document objects
        const xmlDocumentTags = xmlDoc.getElementsByTagName('penneo:document');
        const xmlDocumentArray = (Array.from(
            xmlDocumentTags
        ) as unknown) as Element[];

        return xmlDocumentArray.reduce((result, penneoDocument) => {
            const documentKey = readXMLTag(
                penneoDocument,
                'penneo:documentKey'
            );
            const digestAlgorithm = readXMLTag(
                penneoDocument,
                'penneo:digestAlgorithm'
            );
            const digest = readXMLTag(penneoDocument, 'penneo:digest');

            result[documentKey] = {
                digestAlgorithm,
                digest,
            };

            return result;
        }, {});
    } catch (error) {
        debug.error(signTextXMLData, error);
        throw Error('There was a problem parsing the XML sign text');
    }
};

// Extracts XML node content from an XML tag, based on the tag name.
const readXMLTag = (xmlDoc: Element, xmlTagName): string => {
    return xmlDoc.getElementsByTagName(xmlTagName)[0].childNodes[0].nodeValue;
};

/**
 * Creates XML Document with data that is going to be signed
 * @param  {Object} signText Data
 * @return {Object}          XMLDocument object
 */
export const getXMLSignatureDocument = (XMLSignText: SignText['xml']) => {
    const { Parse } = getCrypto();
    const { xmlData, xslData } = XMLSignText;

    return Parse(
        `<PenneoSignXML>
            <PenneoXML>
                ${xmlData}
            </PenneoXML>
            <PenneoXSL>
                ${xslData}
            </PenneoXSL>
        </PenneoSignXML>`
    );
};
