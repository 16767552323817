import _, { curry } from 'lodash';
import launchDarkly, { Flags } from 'Common/LaunchDarkly';
import { AvailabilityType, EIDMethod } from 'EID/types';
import parameters from 'Constants';

/**
 * Checks if an EID method is available for use for the given intent,
 * based on the presence and the value of its LaunchDarkly flag
 */
const isMethodAvailable = (
    method: EIDMethod,
    intent: AvailabilityType
): boolean => {
    const intentAvailability = method.availability?.[intent];

    if (intentAvailability === undefined) {
        return true;
    }

    if (typeof intentAvailability === 'function') {
        return intentAvailability();
    }

    const enabledExperimentalEIDs = launchDarkly.variation(
        Flags.EXPERIMENTAL_EIDS_ENABLED
    );

    if (!Array.isArray(enabledExperimentalEIDs)) {
        return false;
    }

    if (
        parameters.EXPERIMENTAL_EID_WILDCARD_ENABLED &&
        enabledExperimentalEIDs.includes('*')
    ) {
        return true;
    }

    return (
        enabledExperimentalEIDs.includes(method.type) ||
        enabledExperimentalEIDs.includes(`${method.type}:${intent}`)
    );
};
const isMethodAvailableToLogIn = curry(isMethodAvailable)(_, 'login');
const isMethodAvailableToConfigureForLogin = curry(isMethodAvailable)(
    _,
    'configureForLogin'
);
const isMethodAvailableToSetupUser = curry(isMethodAvailable)(_, 'setupUser');
const isMethodAvailableToSign = curry(isMethodAvailable)(_, 'sign');
const isMethodAvailableToValidateId = curry(isMethodAvailable)(
    _,
    'idValidation'
);

/**
 * Given a type, it finds the matching method by either its `type` or `typeAlias`
 */
const findByType = (methods: EIDMethod[], type: string) =>
    methods.find((method) =>
        [method.type, method.typeAlias, method.credentialType()].includes(
            type.toLowerCase()
        )
    );

export {
    isMethodAvailable,
    isMethodAvailableToLogIn,
    isMethodAvailableToConfigureForLogin,
    isMethodAvailableToSign,
    isMethodAvailableToSetupUser,
    isMethodAvailableToValidateId,
    findByType,
};
