import amplitude from './Amplitude';

const analytics = {
    identify(...args) {
        return Promise.all([amplitude.initUser(...args)]);
    },

    track(...args) {
        return Promise.all([amplitude.track(...args)]);
    },

    // Reference to individual tracking mechanisms
    amplitude: amplitude,
};

export default analytics;
