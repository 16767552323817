import React from 'react';
import PropTypes from 'prop-types';
import { i18n } from 'Language';
import { UserEntity } from 'types/User';
import ProfileActions from 'Auth/actions/ProfileActions';
import UserAvatar from 'Common/components/UserAvatar';
import type { UserProfileWithFullname } from 'Common/components/UserAvatar';
import { ProfileItem } from 'Auth/redux/profiles/types';
import loginUtils from 'Auth/utils/login';

import LoadingSpinner from 'Common/components/Vega/LoadingSpinner';

import './profiles-list.scss';

type State = {
    isLoading: boolean;
    selectedUserId?: number;
};

type Props = {
    currentUser: UserEntity;
    profiles: ProfileItem[];
    redirectUrl?: string;
    hasMultipleIdentities?: boolean;
};

export default class ProfilesList extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { isLoading: false };
    }

    static contextTypes = {
        router: PropTypes.object,
    };

    renderUserProfile(profile: ProfileItem, selectedUser: boolean) {
        const { name, role, customer, createdAt } = profile;
        const userIsConnectedToCustomer = !!role && !!customer;

        // We add the 'fullName' property to user since UserAvatar expects it.

        const profileWithFullName: UserProfileWithFullname = {
            ...profile,
            fullName: profile.name,
            userIsConnectedToCustomer,
        };

        let roleCapitalized: string = '';

        if (userIsConnectedToCustomer) {
            roleCapitalized = role.charAt(0).toUpperCase() + role.slice(1);
            roleCapitalized = i18n(roleCapitalized);
        }

        const tagline = userIsConnectedToCustomer
            ? i18n`${roleCapitalized} at ${customer.name}`
            : i18n`Personal account`;

        return (
            <div className="flex items-center justify-between">
                <UserAvatar
                    user={profileWithFullName}
                    name={name}
                    tagline={tagline}
                    badge={true}
                    createdAt={createdAt}
                />
                {!selectedUser && (
                    <button
                        className="py-2 px-3 
                                    appearance-none 
                                    rounded 
                                    border-0 
                                    text-transparent 
                                    bg-transparent 
                                    focus:outline-0
                                    hover:bg-secondary-50 
                                    focus:bg-secondary-50
                                    focus:text-secondary-600 
                                    group-hover:text-secondary-600 
                                    "
                        aria-label={i18n`Select user ${name} - ${tagline}`}>
                        <i className="fas fa-arrow-right"></i>
                    </button>
                )}
                {selectedUser && (
                    <span className="text-secondary-400">
                        <LoadingSpinner />
                    </span>
                )}
            </div>
        );
    }

    handleClickProfile(userId: number) {
        const { currentUser } = this.props;
        const { router } = this.context;

        this.setState({ isLoading: true, selectedUserId: userId });

        if (currentUser.id === userId) {
            const route = loginUtils.getDefaultRoute(this.props.redirectUrl);

            return loginUtils.hardRedirectToDefaultRoute(route, router);
        }

        ProfileActions.switchProfile(
            userId,
            currentUser.id,
            router,
            this.props.redirectUrl
        );
    }

    render() {
        const { isLoading, selectedUserId } = this.state;
        const { profiles } = this.props;
        const className =
            (this.props.hasMultipleIdentities &&
                'profile-selector-linked-list mb-0 pb-0') ||
            'profile-selector-linked-list';

        const baseListEntry = 'group profile-select-list-entry';

        const entryClassName = (selection) =>
            (selection === selectedUserId && `${baseListEntry} selected`) ||
            (selection !== selectedUserId &&
                isLoading &&
                `${baseListEntry} disabled`) ||
            `${baseListEntry}`;

        return (
            <ul className={className}>
                {profiles.map((profile, index) => (
                    <li
                        className={entryClassName(profile.id)}
                        key={index}
                        data-user-id={profile.id}
                        onClick={() =>
                            !isLoading && this.handleClickProfile(profile.id)
                        }>
                        {this.renderUserProfile(
                            profile,
                            selectedUserId === profile.id && isLoading
                        )}
                    </li>
                ))}
            </ul>
        );
    }
}
