import keyMirror from 'keymirror';
import Constants from 'Constants';

const constants = {
    AUTH_METHODS: {
        SESSION: 'SID',
        SECRET: 'SECRET',
        JWT: 'JWT',
        PUBLIC: 'PUBLIC',
    },

    ActionTypes: keyMirror({
        // Branding Actions
        BRANDING_UPDATED: null,
        FETCH_BRANDING: null,
        FETCH_BRANDING_TOKEN: null,
        APPLY_DEFAULT_BRANDING: null,
    }),
};

export const defaultBranding = {
    siteUrl: 'https://penneo.com',
    textColor: '#FFFFFF',
    highlightColor: '#16BC65', // $penneo-green in _variables.scss
    backgroundColor: '#153047', // $color-blue-main in _variables.scss
    imageUrl: Constants.PENNEO_ORIGIN + '/assets/images/logo-header-white.png',
};
export default constants;
