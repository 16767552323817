import { i18n } from 'Language';
import React from 'react';
import { notify } from 'react-notify-toast';

import { CaseFileEntity } from 'types/CaseFile';
import { DocumentEntity, DocumentType } from 'types/Document';

import CasefileActions from 'Casefiles/actions/CasefilesV2ActionCreators';
import DocumentActions from 'Casefiles/actions/DocumentActionCreators';

import { RadioButton, TextInput } from 'Common/components';
import Button from 'Common/components/Button';
import { modal } from 'Common/components/Common/Modal';
import { renderFileLabelWithTooltip } from './utils';

type Props = {
    documents: DocumentEntity[];
    availableDocumentTypes: DocumentType[];
    files: File[];
    casefile?: CaseFileEntity;
};

type State = {
    activeIndex: number;
    documents: {
        name: string;
        filename: string;
        file: File;
        caseFileId: null | number;
        documentTypeId: number;
        _id?: string;
    }[];
};

export default class DocumentModalAdd extends React.Component<Props, State> {
    parseFilename = (name) => {
        // Remove underscores, remove .pdf extension
        return name
            .replace(/\.[^/.]+$/, '')
            .replace(/_/g, ' ')
            .replace(/.pdf$/, '');
    };

    getAvailableType = () => {
        let documentType = this.props.availableDocumentTypes[0];

        return documentType.id;
    };

    getActiveDocument = () => {
        let { documents, activeIndex } = this.state;

        return documents[activeIndex];
    };

    next = () => {
        this.setState({ activeIndex: this.state.activeIndex + 1 });
    };

    onTitleChange = (name) => {
        let { documents, activeIndex } = this.state;

        documents[activeIndex].name = name;

        this.setState({ documents: documents });
    };

    onTypeChange = (documentTypeId) => {
        let { documents, activeIndex } = this.state;

        documents[activeIndex].documentTypeId = documentTypeId;

        this.setState({ documents: documents });
    };

    renderDocumentTypes = (
        documentTypes: DocumentType[],
        activeDocument: {
            name: string;
            filename: string;
            file: File;
            caseFileId: number | null;
            documentTypeId: number;
            _id?: string | undefined;
        }
    ) => {
        const indexOfFirstAttachment = documentTypes.findIndex(
            (documentType) => !documentType.signerTypes.length
        );

        return documentTypes.map((t, index) => (
            <div key={index}>
                {index === 0 && (
                    <p>
                        <b>{i18n`Signable document`}</b>
                    </p>
                )}
                {index === indexOfFirstAttachment && (
                    <p>
                        <b>{i18n`Attachment`}</b>
                    </p>
                )}
                <RadioButton
                    checked={t.id === activeDocument.documentTypeId}
                    onChange={(value) =>
                        value ? this.onTypeChange(t.id) : null
                    }
                    name="document-type"
                    label={t.name}
                    data-document-type-id={t.id}
                />
            </div>
        ));
    };

    upload = async () => {
        const newDocuments = this.state.documents;

        if (this.props.casefile && this.props.casefile.id) {
            await CasefileActions.addDocumentsToDraft(
                this.props.casefile.id,
                newDocuments,
                this.props.documents
            );
            modal.hide();

            return;
        }

        newDocuments.forEach(async (doc) => {
            await DocumentActions.uploadDocument(doc, (failed) => {
                const index = this.props.documents.findIndex(
                    (doc) => doc._id === failed._id
                );

                DocumentActions.removeDocument(index);
                notify.show(
                    <span>{`Could not upload ${failed.filename}`}</span>,
                    'error',
                    2000
                );
            });
        });

        modal.hide();
    };

    state: State = {
        activeIndex: 0,
        documents: this.props.files.map((file) => ({
            name: this.parseFilename(file.name),
            filename: file.name,
            file: file,
            caseFileId: null,
            documentTypeId: this.getAvailableType(),
        })),
    };

    render() {
        let documentTypes = this.props.availableDocumentTypes;
        let activeDocument = this.getActiveDocument();

        let total = this.state.documents.length;
        let active = this.state.activeIndex + 1;

        return (
            <div className="document-modal-add casefile-modal-v2 form-v2">
                <h3 className="casefile-modal-v2-title">
                    {i18n`Import documents`}
                    <div className="close" onClick={modal.hide}>
                        <div className="far fa-times"></div>
                    </div>
                </h3>

                <div className="mt">
                    <label>{i18n`File name`}</label>
                    <div className="box-gray">
                        <i className="far fa-lg fa-file-pdf text-error"></i>
                        &nbsp;
                        {renderFileLabelWithTooltip({
                            fileName: activeDocument.filename,
                        })}
                    </div>
                </div>
                <div className="mt">
                    <TextInput
                        label={i18n`Edit title (optional)`}
                        className="match-parent"
                        value={activeDocument.name}
                        onChange={this.onTitleChange}
                    />
                </div>

                <div className="document-type-container">
                    <label className="document-type-header">{i18n`Which type of document is this?`}</label>
                    <div className="document-type-list">
                        {this.renderDocumentTypes(
                            documentTypes,
                            activeDocument
                        )}
                    </div>
                </div>

                <div className="text-center">
                    {total > 1 && (
                        <label>{i18n`${active} of ${total} documents`}</label>
                    )}
                    {active === total ? (
                        <Button
                            theme="blue"
                            icon="far fa-plus-circle"
                            onClick={this.upload}
                            renderIconLeft={true}>
                            {i18n`Upload documents`}
                        </Button>
                    ) : (
                        <Button
                            theme="blue"
                            onClick={this.next}
                            icon="far fa-arrow-right">
                            {i18n`Next document`}
                        </Button>
                    )}
                </div>
            </div>
        );
    }
}
