import LoadingData from 'Common/components/Loaders/LoadingData';
import { images } from 'Constants';
import { i18n } from 'Language';
import React from 'react';
import { useCallback } from 'react';
import { CustomerBranding, signingRequestStatus } from 'types/SigningProcess';
import { checkColorSchemeValid } from './utils';
import { Languages } from 'Language/Constants';
import './signing-done.scss';
import './signing-footer.scss';

type Props = {
    requestStatus: signingRequestStatus;
    shouldRedirect: boolean;
    linkTracking(name: string, url: string): void;
    links: {
        name: string;
        url: string;
    }[];
    language: Languages;
    branding: CustomerBranding;
    linkLocalization: (language?: Languages) => string;
};

export const SigningDoneComponents = ({
    requestStatus,
    shouldRedirect,
    linkTracking,
    links,
    language,
    branding,
    linkLocalization,
}: Props) => {
    const renderContent = useCallback(() => {
        if (shouldRedirect) {
            return <LoadingData />;
        }

        switch (requestStatus) {
            case signingRequestStatus.rejected:
                return (
                    <>
                        <h1 className={requestStatus}>
                            {i18n`You have rejected to sign.`}
                            <img src={`${images}/sign/signing-rejected.svg`} />
                        </h1>
                        <p>{i18n`If you specified a rejection reason, this will be delivered to the case file sender.`}</p>
                    </>
                );

            case signingRequestStatus.deleted:
                return (
                    <>
                        <h1 className={requestStatus}>
                            {i18n`The signing process has completed`}
                            .
                            <img src={`${images}/sign/signing-rejected.svg`} />
                        </h1>
                        <p>{i18n`You have been removed as signer.`}</p>
                        <p>
                            {i18n`There can be several reasons why you were removed as signer.`}{' '}
                            {i18n`Please contact the sender directly, if you think that a mistake has been made.`}
                        </p>
                    </>
                );

            case signingRequestStatus.expired:
                return (
                    <>
                        <h1 className={requestStatus}>
                            {i18n`The signing process has completed`}
                            .
                            <img src={`${images}/sign/signing-rejected.svg`} />
                        </h1>
                        <p>{i18n`The deadline for signing has passed.`}</p>
                        <p>
                            {i18n`The sender specified a deadline for signing on this case file. This deadline has now passed.`}{' '}
                            {i18n`Please contact the sender directly, if you think that a mistake has been made.`}
                        </p>
                    </>
                );

            default:
                return null;
        }
    }, [requestStatus, linkTracking, shouldRedirect]);

    return (
        <>
            <div className="signing-container-done">
                <div className="signing-done">
                    <div className="signing-content-done">
                        {renderContent()}

                        <p>
                            {i18n`Need to know what signing a document digitally with Penneo means? Learn more about:`}
                        </p>
                        <ul className="signing-links-list">
                            {links.map((link) => {
                                const href = `https://penneo.com/${linkLocalization(
                                    language
                                )}${link.url}`;

                                return (
                                    <li key={link.name}>
                                        <a
                                            href={href}
                                            target="_blank"
                                            rel="noreferrer"
                                            onClick={() =>
                                                linkTracking(link.name, href)
                                            }>
                                            {i18n(link.name)}
                                        </a>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
            </div>

            <div
                className="signing-footer"
                style={{
                    ...(branding &&
                        checkColorSchemeValid(branding) && {
                            backgroundColor: branding?.backgroundColor,
                        }),
                }}></div>
        </>
    );
};
