import React from 'react';
import { Link } from 'react-router';
import lodash from 'lodash';
import { i18n } from 'Language';
import { DocumentValidation } from 'types/DataValidation';
import { DocumentEntity, DocumentType } from 'types/Document';
import DocumentActions from 'Casefiles/actions/DocumentActionCreators';
import DocumentStore from 'Casefiles/stores/DocumentStore';
import { getDocumentValidationErrorMessage } from 'Casefiles/utils/casefileValidation';
import Button from 'Common/components/Button';
import DocumentModalAdd from './DocumentModalAdd';
import DocumentModalEdit from './DocumentModalEdit';
import { modal } from 'Common/components/Common/Modal.jsx';
import { DocumentDropZone } from 'Common/components/DocumentDropZone';
import { CasefileRoundsFlowPreview } from './CasefileRounds';
import LaunchDarkly, { Flags } from 'Common/LaunchDarkly';
import { CaseFileEntity } from 'types/CaseFile';

type Props = {
    casefile: CaseFileEntity;
    availableDocumentTypes: DocumentType[];
    documentValidation: DocumentValidation;
    documents: DocumentEntity[];
    linkParams: any;
    openDocumentPreview: Function;
    saveAsDraftButton: Function;
    uploadedDocuments: DocumentEntity[];
};

export default class CasefileDocumentStep extends React.Component<Props> {
    activateFileAddHandler = () => {
        const fileUpload = document.getElementById('fileupload') as HTMLElement;

        fileUpload.click();
    };

    // Exclude non-pdf files.
    filterPDFs = (files: File[]) => {
        return lodash.filter(files, (file) => file.type === 'application/pdf');
    };

    // uploading a document by button
    onFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) {
            return;
        }

        const files = Array.from(event.target.files || []);

        let data = this.filterPDFs(files);

        if (data.length) {
            this.openDocumentImport(data);
        }
    };

    openDocumentImport = (files: File[]) => {
        if (files.length < 1) return;

        let config = {
            component: <DocumentModalAdd files={files} {...this.props} />,
            preventClose: false,
        };

        modal.show(config);
    };

    openDocumentEdit = (index: number) => {
        let config = {
            component: <DocumentModalEdit index={index} {...this.props} />,
            preventClose: false,
        };

        modal.show(config);
    };

    onDocumentPreview = (index) => {
        const { openDocumentPreview, documents } = this.props;
        const documentId = documents[index].id;

        openDocumentPreview(documentId);
    };

    mapObject = (object, callback) => {
        return Object.keys(object).map((key) => {
            return callback(key, object[key]);
        });
    };

    renderDocumentTypeError = (documentTypeId, validation) => {
        let { errors, valid } = validation;

        if (valid) {
            return false;
        }

        let { code, count, required } = errors.limit;
        let documentType = DocumentStore.getDocumentType(documentTypeId);
        let message = getDocumentValidationErrorMessage(
            required,
            count,
            documentType,
            code
        );

        // Display document type limit errors
        return (
            <div
                className="document-type-requirements-item"
                key={documentTypeId}>
                <i className="fas fa-info-circle" />
                &nbsp;
                {message}
            </div>
        );
    };

    renderRequirements = () => {
        let { setup, documentTypes } = this.props.documentValidation;

        if (documentTypes.valid && setup.valid) {
            return false;
        }

        return (
            <div className="documents-requirements">
                {documentTypes.valid === false && (
                    <div className="document-type-requirements">
                        {this.mapObject(
                            documentTypes.items,
                            this.renderDocumentTypeError
                        )}
                    </div>
                )}

                {setup.valid === false &&
                    documentTypes.valid &&
                    this.mapObject(setup.errors, (key, error) => (
                        <div key={key}>
                            <i className="fas fa-info-circle" />{' '}
                            {i18n(error.message)}
                        </div>
                    ))}
            </div>
        );
    };

    handleChangeDocumentOrder = (index: number, newIndex: number) => {
        DocumentActions.reorderDocument(index, newIndex);
    };

    render() {
        const {
            casefile,
            documents,
            availableDocumentTypes,
            linkParams,
            uploadedDocuments,
        } = this.props;

        return (
            <div className="casefile-process-v2 medium">
                <div className="header">
                    <h1>
                        {i18n`Add some documents to the mix`}
                        <span className="dot">.</span>
                    </h1>
                    <p>{i18n`Upload the documents and attachments that will be sent out to the recipients`}</p>
                </div>
                <div className="body form-v2">
                    {LaunchDarkly.variation(
                        Flags.ACTIVATION_DATE_PER_ROUND
                    ) && <CasefileRoundsFlowPreview casefile={casefile} />}

                    <div className="casefile-process-body-header">
                        {/* File Input */}
                        <label className="file-input">
                            <input
                                type="file"
                                id="fileupload"
                                name="files[]"
                                className="file"
                                accept="application/pdf"
                                onChange={this.onFileInputChange}
                                onClick={(event) =>
                                    ((event.target as HTMLInputElement).value =
                                        '')
                                }
                                multiple={true}
                            />
                            <Button
                                theme="blue"
                                variant="text"
                                renderAsSpan
                                icon="far fa-plus-circle"
                                renderIconLeft={true}>
                                {i18n('Upload document')}
                            </Button>
                        </label>

                        {
                            /* Document Count */
                            uploadedDocuments.length === documents.length ? (
                                <span className="pull-right count">
                                    {documents.length}&nbsp;
                                    {documents.length === 1
                                        ? i18n`document`
                                        : i18n`documents`}
                                </span>
                            ) : (
                                <span className="pull-right count">
                                    {i18n`Uploaded ${uploadedDocuments.length} / ${documents.length}`}
                                    &nbsp;
                                    {documents.length === 1
                                        ? i18n`document`
                                        : i18n`documents`}
                                </span>
                            )
                        }
                    </div>

                    <DocumentDropZone
                        availableDocumentTypes={availableDocumentTypes}
                        documents={documents}
                        onAddFileLinkClick={this.activateFileAddHandler}
                        onChangeDocumentOrder={this.handleChangeDocumentOrder}
                        onDrop={this.openDocumentImport}
                        onEdit={this.openDocumentEdit}
                        onPreview={this.onDocumentPreview}
                    />

                    {/* Render Validation */}
                    {this.renderRequirements()}
                </div>

                <div className="footer form-v2">
                    <div className="footer-content right">
                        <Link
                            to={{
                                name: 'casefile2-details',
                                params: linkParams,
                            }}>
                            <Button
                                theme="gray"
                                variant="text"
                                className="pull-left">
                                {i18n`Back`}
                            </Button>
                        </Link>

                        {this.props.saveAsDraftButton()}

                        <Link
                            to={{
                                name: 'casefile-recipients',
                                params: linkParams,
                            }}>
                            <Button theme="blue">
                                {i18n`Save & continue`}
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}
