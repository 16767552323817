import { storage } from 'Core';
import { AuthStore } from 'Auth';
import Constants, { StorageKeys, env } from 'Constants';
import { Intent } from 'OpenID/Constants';
import { OpenIdLocalStorageState, OpenIdMetadata } from 'OpenID/redux/types';
import { getChallengeKeyFromSignUrl } from '../../utils';

export const getOpenIdState = (): OpenIdLocalStorageState | null => {
    const state = storage.get(StorageKeys.OPENID_STATE);

    if (!state) {
        return null;
    }

    return JSON.parse(state);
};

export const updateOpenIdState = (data: Partial<OpenIdLocalStorageState>) => {
    const state = getOpenIdState();

    if (!state) {
        return setOpenIdState(data as OpenIdLocalStorageState);
    }

    // Merge data and set it back to localStorage
    setOpenIdState({
        ...state,
        ...data,
    });
};

export const setOpenIdState = (data: OpenIdLocalStorageState) => {
    storage.set(StorageKeys.OPENID_STATE, JSON.stringify(data));
};

export const clearOpenIdState = () => {
    return storage.clear(StorageKeys.OPENID_STATE);
};

export function storeOpenIdIntentState(
    state: Pick<
        OpenIdLocalStorageState,
        'serviceId' | 'intent' | 'csrfToken' | 'encryptedNIN'
    >,
    metadata: OpenIdMetadata = {}
) {
    const isLegacy = env.legacy;

    let successUrl = getSuccessUrl(state);

    // When adding an EID in the legacy app, we don't redirect to the same place where
    // the request was originated, because this would re-open the modal to select a new electronic ID to connect.
    if (state.intent === Intent.ADD && isLegacy) {
        successUrl = Constants.PENNEO_ORIGIN + '/user/profile#/credentials';
    }

    // If metadata contains the successUrl, we use that.
    if (metadata.successUrl) {
        successUrl = metadata.successUrl;
    }

    const data: OpenIdLocalStorageState = {
        successUrl: successUrl,
        serviceId: state.serviceId,
        intent: state.intent,
        csrfToken: state.csrfToken,
        legacy: isLegacy,
        processed: false,
        metadata: metadata,
        encryptedNIN: state.encryptedNIN,
    };

    setOpenIdState(data);
}

function getSuccessUrl(
    state: Pick<
        OpenIdLocalStorageState,
        'serviceId' | 'intent' | 'csrfToken' | 'encryptedNIN'
    >
): string {
    // If "login" or "validate id" intent, get default success URL
    if (state.intent === Intent.LOGIN || state.intent === Intent.VALIDATE_ID) {
        return AuthStore.getSuccessUrl();
    }

    /**
     * If intent is "nap" we set the url to the /signing/:challengeKey,
     * to ensure that we get back to the correct page after authentication in MitID.
     */
    if (state.intent === Intent.NAP) {
        const challengeKey = getChallengeKeyFromSignUrl(window.location.href);

        return window.origin + '/signing/' + challengeKey;
    }

    return window.location.href;
}
