import { AppDispatch } from 'Store';
import createReducer from 'create-reducer';
import { batchActions } from 'redux-batched-actions';
import { debug } from 'Core';
import * as inMemory from 'Core/in-memory';
import { CaseFileItem, Type } from '../../';
import { FolderShareData, SimpleFolderEntity } from 'types/Folder';
import { GetState } from 'Store';
import { PrefixObject } from '../../utils';
import moment from 'moment';
import produce from 'immer';
import { ApiClient, SigningAPI } from 'Api';
import analytics from 'Common/Analytics';

import { i18n } from 'Language';
import { ACCESS_FULL, ACCESS_READONLY, ACCESS_READ_WRITE } from 'Constants';
import { CancelTokenSource } from 'axios';

const CASE_FILES_EXPIRATION_TIME = '5s';

const FETCH_CASE_FILES_ITEMS_REQUEST = 'FETCH_CASE_FILES_ITEMS_REQUEST';
const FETCH_CASE_FILES_ITEMS_SUCCESS = 'FETCH_CASE_FILES_ITEMS_SUCCESS';
const FETCH_CASE_FILES_ITEMS_FAILURE = 'FETCH_CASE_FILES_ITEMS_FAILURE';
const RESET_CASE_FILES_ITEMS_ERROR = 'RESET_CASE_FILES_ITEMS_ERROR';

const OPTIMISTIC_UPDATE_CONTENT_CASE_FILES_ITEMS =
    'OPTIMISTIC_UPDATE_CONTENT_CASE_FILES_ITEMS';
const OPTIMISTIC_UPDATE_TOP_LEVEL_CASE_FILES_ITEMS =
    'OPTIMISTIC_UPDATE_TOP_LEVEL_CASE_FILES_ITEMS';
const OPTIMISTIC_UPDATE_CASEFILES_ITEMS = 'OPTIMISTIC_UPDATE_CASEFILES_ITEMS';

const DELETE_ITEMS_REQUEST = 'DELETE_ITEMS_REQUEST';
const DELETE_ITEMS_SUCCESS = 'DELETE_ITEMS_SUCCESS';
const DELETE_ITEMS_FAILURE = 'DELETE_ITEMS_FAILURE';

const OPTIMISTIC_DELETE_ITEMS = 'OPTIMISTIC_DELETE_ITEMS';

const SET_DEFAULT_FOLDER_REQUEST = 'SET_DEFAULT_FOLDER_REQUEST';
const SET_DEFAULT_FOLDER_SUCCESS = 'SET_DEFAULT_FOLDER_SUCCESS';
const SET_DEFAULT_FOLDER_FAILURE = 'SET_DEFAULT_FOLDER_FAILURE';

const OPTIMISTIC_SET_DEFAULT_FOLDER = 'OPTIMISTIC_SET_DEFAULT_FOLDER';

const UPDATE_CASE_FILE_ITEM_TITLE_REQUEST =
    'UPDATE_CASE_FILE_ITEM_TITLE_REQUEST';
const UPDATE_CASE_FILE_ITEM_TITLE_SUCCESS =
    'UPDATE_CASE_FILE_ITEM_TITLE_SUCCESS';
const UPDATE_CASE_FILE_ITEM_TITLE_FAILURE =
    'UPDATE_CASE_FILE_ITEM_TITLE_FAILURE';

const OPTIMISTIC_UPDATE_CASE_FILE_ITEM_TITLE =
    'OPTIMISTIC_UPDATE_CASE_FILE_ITEM_TITLE';

const UPDATE_BREADCRUMBS = 'UPDATE_BREADCRUMBS';
const RESET_BREADCRUMBS = 'RESET_BREADCRUMBS';
const DELETE_BREADCRUMBS = 'DELETE_BREADCRUMBS';
const DELETE_FROM_BREADCRUMBS = 'DELETE_FROM_BREADCRUMBS';

const ADD_BREADCRUMBS = 'ADD_BREADCRUMBS';
const REMOVE_LAST_BREADCRUMB = 'REMOVE_LAST_BREADCRUMB';

const CREATE_FOLDER_REQUEST = 'CREATE_FOLDER_REQUEST';
const CREATE_FOLDER_SUCCESS = 'CREATE_FOLDER_SUCCESS';
const CREATE_FOLDER_FAILURE = 'CREATE_FOLDER_FAILURE';

const SELECT_CASE_FILE = 'SELECT_CASE_FILE';
const RESET_CASE_FILE = 'RESET_CASE_FILE';

const MOVE_FOLDERS_AND_CASE_FILES_REQUEST =
    'MOVE_FOLDERS_AND_CASE_FILES_REQUEST';
const MOVE_FOLDERS_AND_CASE_FILES_FAILURE =
    'MOVE_FOLDERS_AND_CASE_FILES_FAILURE';
const MOVE_FOLDERS_AND_CASE_FILES_SUCCESS =
    'MOVE_FOLDERS_AND_CASE_FILES_SUCCESS';

const FETCH_DELETE_PENDING_INVITATION_REQUEST =
    'FETCH_DELETE_PENDING_INVITATION_REQUEST';
const FETCH_DELETE_PENDING_INVITATION_FAILURE =
    'FETCH_DELETE_PENDING_INVITATION_FAILURE';
const FETCH_DELETE_PENDING_INVITATION_SUCCESS =
    'FETCH_DELETE_PENDING_INVITATION_SUCCESS';

const FETCH_UNSHARE_FOLDER_REQUEST = 'FETCH_UNSHARE_FOLDER_REQUEST';
const FETCH_UNSHARE_FOLDER_FAILURE = 'FETCH_UNSHARE_FOLDER_FAILURE';
const FETCH_UNSHARE_FOLDER_SUCCESS = 'FETCH_UNSHARE_FOLDER_SUCCESS';

export const REMOVE_SELF_FROM_SHARED_FOLDER_REQUEST =
    'REMOVE_SELF_FROM_SHARED_FOLDER_REQUEST';
export const OPTIMISTIC_REMOVE_SELF_FROM_SHARED_FOLDER =
    'OPTIMISTIC_REMOVE_SELF_FROM_SHARED_FOLDER';
export const REMOVE_SELF_FROM_SHARED_FOLDER_OPTIMISTIC_ROLLBACK =
    'REMOVE_SELF_FROM_SHARED_FOLDER_OPTIMISTIC_ROLLBACK';
export const REMOVE_SELF_FROM_SHARED_FOLDER_SUCCESS =
    'REMOVE_SELF_FROM_SHARED_FOLDER_SUCCESS';
export const REMOVE_SELF_FROM_SHARED_FOLDER_FAILURE =
    'REMOVE_SELF_FROM_SHARED_FOLDER_FAILURE';

const FETCH_SHARE_FOLDER_REQUEST = 'FETCH_SHARE_FOLDER_REQUEST';
const FETCH_SHARE_FOLDER_SUCCESS = 'FETCH_SHARE_FOLDER_SUCCESS';
const FETCH_INVITE_FOLDER_SUCCESS = 'FETCH_INVITE_FOLDER_SUCCESS';

const FETCH_FOLDER_SHARE_DATA_REQUEST = 'FETCH_FOLDER_SHARE_DATA_REQUEST';
const FETCH_FOLDER_SHARE_DATA_SUCCESS = 'FETCH_FOLDER_SHARE_DATA_SUCCESS';
const FETCH_FOLDER_SHARE_DATA_FAILURE = 'FETCH_FOLDER_SHARE_DATA_FAILURE';

const FETCH_UPDATE_USER_FOLDER_RIGHTS_REQUEST =
    'FETCH_UPDATE_USER_FOLDER_RIGHTS_REQUEST';
const FETCH_UPDATE_USER_FOLDER_RIGHTS_FAILURE =
    'FETCH_UPDATE_USER_FOLDER_RIGHTS_FAILURE';
const FETCH_UPDATE_USER_FOLDER_RIGHTS_SUCCESS =
    'FETCH_UPDATE_USER_FOLDER_RIGHTS_SUCCESS';

const FETCH_ARCHIVE_STATS_REQUEST = 'FETCH_ARCHIVE_STATS_REQUEST';
const FETCH_ARCHIVE_STATS_SUCCESS = 'FETCH_ARCHIVE_STATS_SUCCESS';

const SET_CASE_FILE_SENT = 'SET_CASE_FILE_SENT';
const RESET_CASE_FILE_SENT = 'RESET_CASE_FILE_SENT';

const FETCH_SHARED_FOLDERS_REQUEST = 'FETCH_SHARED_FOLDERS_REQUEST';
const FETCH_SHARED_FOLDERS_SUCCESS = 'FETCH_SHARED_FOLDERS_SUCCESS';
const FETCH_SHARED_FOLDERS_FAILURE = 'FETCH_SHARED_FOLDERS_FAILURE';

const FETCH_SHARED_FOLDERS_STATS_REQUEST = 'FETCH_SHARED_FOLDERS_STATS_REQUEST';
const FETCH_SHARED_FOLDERS_STATS_SUCCESS = 'FETCH_SHARED_FOLDERS_STATS_SUCCESS';

const archiveUpdateRequestQueue: CancelTokenSource[] = [];

export type BreadcrumbsBase = {
    title: string;
    route: {
        name: string;
        params?: any;
    };
};

export type Breadcrumbs = BreadcrumbsBase[];

export type Archive = {
    items: CaseFileItem[];
    isLoading: boolean;
    error: any;
    breadcrumbs: Breadcrumbs;
    stats: Stats;
    selectedCaseFile?: CaseFileItem;
    itemCount: number;
    folderShareData: FolderShareData;
    sent: boolean;
    sharedFolders: SharedFolders;
};

export const enum StatTypes {
    DRAFTS = 'drafts',
    PENDING = 'pending',
    COMPLETED = 'completed',
    EXPIRED = 'expired',
    REJECTED = 'rejected',
    SCHEDULED = 'scheduled',
}

export type Stats = {
    items: {
        [key in StatTypes]: {
            count: number;
        };
    };
    isLoaded: boolean;
    isFetching: boolean;
    error: any;
};

export type FoldersCount = {
    totalFolderCount: number;
    ownedFolderCount: number;
    sharedFolderCount: number;
};

export type SharedFolders = {
    folders: SimpleFolderEntity[];
    folderCount: number;
    isLoaded: boolean;
    isFetching: boolean;
    error: any;
};

export const archiveInitialState: Archive = {
    items: [],
    itemCount: 0,
    isLoading: false,
    error: null,
    breadcrumbs: [],
    stats: {
        items: {
            drafts: {
                count: 0,
            },
            pending: {
                count: 0,
            },
            completed: {
                count: 0,
            },
            rejected: {
                count: 0,
            },
            expired: {
                count: 0,
            },
            scheduled: {
                count: 0,
            },
        },
        isLoaded: false,
        isFetching: false,
        error: null,
    },
    folderShareData: {},
    sent: false,
    sharedFolders: {
        folders: [],
        folderCount: 0,
        isLoaded: false,
        isFetching: false,
        error: null,
    },
};

// @todo: This is a temporary solution to support global breadcrumbs that get reset on navigation
// The idea is that we should keep the state from breadcrumbs separate from the process, and manage the state
// by using the breadcrumbs as a stack of routes.
export const archiveInitialBreadcrumb = (sharedFolder?: boolean) =>
    sharedFolder
        ? {
              title: i18n('Shared'),
              route: {
                  name: 'archive-virtual',
                  params: {
                      tab: 'shared',
                  },
              },
          }
        : {
              title: i18n('All Items'),
              route: {
                  name: 'archive',
              },
          };
export type RequestFoldersParamsOptions = {
    per_page?: number /* eslint camelcase:0 */;
    page?: number;
    sort?: string;
    status?: number | string;
    title?: string;
    subType?: string;
};

export type FolderUserRights =
    | typeof ACCESS_READONLY
    | typeof ACCESS_READ_WRITE
    | typeof ACCESS_FULL;

export type FolderUser = {
    id: number;
    email: string;
    fullName: string;
    name?: string;
    accessRights?: FolderUserRights;
    expireAt?: number;
    status?: number;
    language?: string;
    folderId?: string;
};

export type FolderUserWithCustomProperty = FolderUser & {
    isExternal: boolean;
    isPending: boolean;
};

const requestStateAction = (state: Archive) => ({
    ...state,
    isLoading: true,
    error: null,
});

const failureStateAction = (state: Archive, error: any) => ({
    ...state,
    isLoading: false,
    error,
});

const optimisticUpdateCaseFiles = (state, { items, itemCount = 0 }) => ({
    ...state,
    items,
    itemCount,
    isLoading: false,
});

export enum ErrorTypes {
    FetchingCaseFiles,
    CreatingFolder,
    UpdatingTitle,
    SettingDefaultFolder,
    DeletingFolder,
    MovingCaseFiles,
}

export default createReducer<Archive>(archiveInitialState, {
    [FETCH_CASE_FILES_ITEMS_REQUEST]: requestStateAction,
    [FETCH_CASE_FILES_ITEMS_SUCCESS]: (state, { items, itemCount = 0 }) => ({
        ...state,
        items,
        itemCount,
        isLoading: false,
    }),
    [OPTIMISTIC_UPDATE_CONTENT_CASE_FILES_ITEMS]: optimisticUpdateCaseFiles,
    [OPTIMISTIC_UPDATE_TOP_LEVEL_CASE_FILES_ITEMS]: optimisticUpdateCaseFiles,
    [OPTIMISTIC_UPDATE_CASEFILES_ITEMS]: optimisticUpdateCaseFiles,
    [OPTIMISTIC_SET_DEFAULT_FOLDER]: (state, items) => ({
        ...state,
        items,
    }),
    [SET_DEFAULT_FOLDER_REQUEST]: requestStateAction,
    [SET_DEFAULT_FOLDER_SUCCESS]: (state) => ({
        ...state,
        isLoading: false,
        error: null,
    }),
    [SET_DEFAULT_FOLDER_FAILURE]: failureStateAction,
    [UPDATE_BREADCRUMBS]: (state, breadcrumbs) => ({
        ...state,
        breadcrumbs,
    }),
    [ADD_BREADCRUMBS]: (state, item: BreadcrumbsBase) =>
        produce(state, (draftState) => {
            /**
             * Check if there is already a breadcrumb that has
             * the same title and the same route's name in the array
             * as the new incoming one.
             * Add it only if the new breadcrumb is unique.
             */
            const checkBreadcrumb = (breadcrumb: BreadcrumbsBase) =>
                breadcrumb.title === item.title &&
                breadcrumb.route.name === item.route.name;

            if (!state.breadcrumbs.some(checkBreadcrumb)) {
                draftState.breadcrumbs.push(item);
            }
        }),
    [REMOVE_LAST_BREADCRUMB]: (state) => ({
        ...state,
        breadcrumbs: state.breadcrumbs.slice(0, -1),
    }),
    [DELETE_FROM_BREADCRUMBS]: (state, index) => ({
        ...state,
        breadcrumbs: state.breadcrumbs.slice(0, index + 1),
    }),
    [RESET_BREADCRUMBS]: (state) => ({
        ...state,
        breadcrumbs: [],
    }),
    [DELETE_BREADCRUMBS]: (state) => ({
        ...state,
        breadcrumbs: [],
    }),
    [FETCH_CASE_FILES_ITEMS_FAILURE]: failureStateAction,
    [DELETE_ITEMS_REQUEST]: requestStateAction,
    [DELETE_ITEMS_SUCCESS]: (state) => ({
        ...state,
        isLoading: false,
    }),
    [OPTIMISTIC_DELETE_ITEMS]: (state, items) => ({
        ...state,
        items,
    }),
    [DELETE_ITEMS_FAILURE]: (state) => ({
        ...state,
        isLoading: false,
    }),
    [UPDATE_CASE_FILE_ITEM_TITLE_REQUEST]: requestStateAction,
    [OPTIMISTIC_UPDATE_CASE_FILE_ITEM_TITLE]: (state, items) => ({
        ...state,
        items,
    }),
    [UPDATE_CASE_FILE_ITEM_TITLE_SUCCESS]: (state) => ({
        ...state,
        isLoading: false,
        error: null,
    }),
    [UPDATE_CASE_FILE_ITEM_TITLE_FAILURE]: failureStateAction,
    [CREATE_FOLDER_REQUEST]: requestStateAction,
    [CREATE_FOLDER_SUCCESS]: (state, item) => ({
        ...state,
        items: [item, ...state.items],
        isLoading: false,
    }),
    [CREATE_FOLDER_FAILURE]: (state, error) => ({
        ...state,
        isLoading: false,
        error,
    }),
    [SELECT_CASE_FILE]: (state, selectedCaseFile) => ({
        ...state,
        selectedCaseFile,
    }),
    [RESET_CASE_FILE]: (state) => ({
        ...state,
        selectedCaseFile: undefined,
    }),

    // Folder Share
    [FETCH_FOLDER_SHARE_DATA_REQUEST]: (state, folderId) => ({
        ...state,
        folderShareData: {
            ...state.folderShareData,
            [folderId]: {
                isFetching: true,
                isLoaded: false,
                error: null,
            },
        },
    }),

    [FETCH_FOLDER_SHARE_DATA_SUCCESS]: (state, payload) => ({
        ...state,
        folderShareData: {
            ...state.folderShareData,
            [payload.folderId]: {
                ...payload,
                isFetching: false,
                isLoaded: true,
                error: null,
            },
        },
    }),

    [FETCH_SHARE_FOLDER_SUCCESS]: (state, payload) => ({
        ...state,
        folderShareData: {
            ...state.folderShareData,
            [payload.folderId]: {
                ...state.folderShareData[payload.folderId],
                shares: payload.shares,
                isFetching: false,
                isLoaded: true,
                error: null,
            },
        },
    }),

    [FETCH_INVITE_FOLDER_SUCCESS]: (state, payload) => ({
        ...state,
        folderShareData: {
            ...state.folderShareData,
            [payload.folderId]: {
                ...state.folderShareData[payload.folderId],
                invites: payload.invites,
                isFetching: false,
                isLoaded: true,
                error: null,
            },
        },
    }),

    [FETCH_DELETE_PENDING_INVITATION_SUCCESS]: (state, payload) => {
        const folder = state.folderShareData[payload.folderId];
        const invites = folder.invites.filter((i) => i.id !== payload.inviteId);

        return {
            ...state,
            folderShareData: {
                ...state.folderShareData,
                [payload.folderId]: {
                    ...state.folderShareData[payload.folderId],
                    invites,
                    isFetching: false,
                    isLoaded: true,
                    error: null,
                },
            },
        };
    },

    [FETCH_UNSHARE_FOLDER_SUCCESS]: (state, payload) => {
        const folder = state.folderShareData[payload.folderId];
        const shares = folder.shares.filter((i) => i.id !== payload.userId);

        return {
            ...state,
            folderShareData: {
                ...state.folderShareData,
                [payload.folderId]: {
                    ...state.folderShareData[payload.folderId],
                    shares,
                    isFetching: false,
                    isLoaded: true,
                    error: null,
                },
            },
        };
    },

    [FETCH_UPDATE_USER_FOLDER_RIGHTS_SUCCESS]: (state, payload) =>
        produce(state, (draftState) => {
            const { folderShareData } = state;
            const { shares } = folderShareData[payload.folderId];
            const sharedFolderDraft =
                draftState.folderShareData[payload.folderId];

            const updatedShares = shares.map((share) => {
                if (share.id === payload.userId) {
                    return {
                        ...share,
                        accessRights: payload.right,
                    };
                }

                return share;
            });

            sharedFolderDraft.error = null;
            sharedFolderDraft.isLoaded = true;
            sharedFolderDraft.isFetching = false;
            sharedFolderDraft.shares = updatedShares;
        }),

    [FETCH_ARCHIVE_STATS_REQUEST]: (state) => {
        return {
            ...state,
            stats: {
                ...state.stats,
                isLoaded: false,
                isFetching: true,
                error: null,
            },
        };
    },
    [FETCH_ARCHIVE_STATS_SUCCESS]: (state, payload) => {
        return {
            ...state,
            stats: {
                items: {
                    ...payload,
                },
                isLoaded: true,
                isFetching: false,
                error: null,
            },
        };
    },
    [REMOVE_SELF_FROM_SHARED_FOLDER_REQUEST]: requestStateAction,
    [OPTIMISTIC_REMOVE_SELF_FROM_SHARED_FOLDER]: (
        state,
        { items, itemCount = 0 }
    ) =>
        produce(state, (draftState) => {
            draftState.items = items;
            draftState.itemCount = itemCount;
        }),
    [REMOVE_SELF_FROM_SHARED_FOLDER_OPTIMISTIC_ROLLBACK]: (state, payload) =>
        produce(state, (draftState) => {
            const { unsharedFolderIndex, folder } = payload;

            draftState.items.splice(unsharedFolderIndex, 0, folder);
        }),
    [REMOVE_SELF_FROM_SHARED_FOLDER_SUCCESS]: (state) =>
        produce(state, (draftState) => {
            draftState.isLoading = false;
        }),
    [REMOVE_SELF_FROM_SHARED_FOLDER_FAILURE]: (state) =>
        produce(state, (draftState) => {
            draftState.isLoading = false;
        }),
    [SET_CASE_FILE_SENT]: (state) => {
        return {
            ...state,
            sent: true,
        };
    },
    [RESET_CASE_FILE_SENT]: (state) => {
        return {
            ...state,
            sent: false,
        };
    },
    // Folder Share
    [FETCH_SHARED_FOLDERS_REQUEST]: (state) => ({
        ...state,
        sharedFolders: {
            folders: [],
            folderCount: 0,
            isFetching: true,
            isLoaded: false,
            error: null,
        },
    }),

    [FETCH_SHARED_FOLDERS_SUCCESS]: (state, { folders, folderCount }) => ({
        ...state,
        sharedFolders: {
            folders,
            folderCount,
            isFetching: false,
            isLoaded: true,
            error: null,
        },
    }),

    [FETCH_SHARED_FOLDERS_FAILURE]: (state, error) => ({
        ...state,
        sharedFolders: {
            folders: [],
            folderCount: 0,
            isFetching: false,
            isLoaded: true,
            error: error,
        },
    }),
    // Folder Share stats
    [FETCH_SHARED_FOLDERS_STATS_REQUEST]: (state) => ({
        ...state,
        sharedFolders: {
            ...state.sharedFolders,
            folderCount: 0,
        },
    }),

    [FETCH_SHARED_FOLDERS_STATS_SUCCESS]: (state, payload) => ({
        ...state,
        sharedFolders: {
            ...state.sharedFolders,
            folderCount: payload,
        },
    }),
});

export const addBreadcrumbsAction = (payload: BreadcrumbsBase) => ({
    type: ADD_BREADCRUMBS,
    payload,
});

export const removeLastBreadcrumbAction = () => ({
    type: REMOVE_LAST_BREADCRUMB,
});

export const updateBreadcrumbsAction = (payload: Breadcrumbs) => ({
    type: UPDATE_BREADCRUMBS,
    payload,
});

export const resetBreadcrumbs = () => ({
    type: RESET_BREADCRUMBS,
});

export const deleteBreadcrumbs = () => ({
    type: DELETE_BREADCRUMBS,
});

export const requestCaseFilesItemsAction = (cancelToken: CancelTokenSource) => {
    // Cancel still pending requests
    archiveUpdateRequestQueue.forEach((ct, i) => {
        ct.cancel();
    });

    // Add request cancel token to queue.
    archiveUpdateRequestQueue.unshift(cancelToken);

    return {
        type: FETCH_CASE_FILES_ITEMS_REQUEST,
    };
};

const removeTokenFromRequestQueue = (cancelToken) => {
    const requestIndex = archiveUpdateRequestQueue.indexOf(cancelToken);

    archiveUpdateRequestQueue.splice(requestIndex, 1);
};

export const successfulRequestCaseFilesItemsAction = (
    payload: { items: CaseFileItem[]; itemCount: number },
    cancelToken: CancelTokenSource
) => {
    // Remove cancelToken from request queue if action succeeded.
    removeTokenFromRequestQueue(cancelToken);

    return {
        type: FETCH_CASE_FILES_ITEMS_SUCCESS,
        payload,
    };
};

export const resetCaseFileItemsError = () => ({
    type: RESET_CASE_FILES_ITEMS_ERROR,
});

export const getTopFolders = (params: RequestFoldersParamsOptions) => async (
    dispatch: AppDispatch,
    _: GetState,
    { api }: { api: { SigningAPI: ApiClient } }
) => {
    const url = '/folders/top';
    const cancelToken = api.SigningAPI.getCancelToken();

    dispatch(batchActions([requestCaseFilesItemsAction(cancelToken)]));

    const inMemoryDataKey = url + JSON.stringify(params);
    const inMemoryData = inMemory.get(inMemoryDataKey);

    if (inMemoryData) {
        return dispatch({
            type: OPTIMISTIC_UPDATE_TOP_LEVEL_CASE_FILES_ITEMS,
            payload: inMemoryData,
        });
    }

    try {
        const options = {
            paginate: true,
            cancelToken: cancelToken.token,
        };

        const response = await api.SigningAPI.get(url, params, options);

        const payload = {
            items: response.data,
            itemCount: response.count,
        };

        inMemory.add(inMemoryDataKey, payload, CASE_FILES_EXPIRATION_TIME);

        dispatch(successfulRequestCaseFilesItemsAction(payload, cancelToken));
    } catch (e) {
        if (e.cancel) {
            removeTokenFromRequestQueue(cancelToken);

            return false;
        }

        dispatch({
            type: FETCH_CASE_FILES_ITEMS_FAILURE,
            payload: ErrorTypes.FetchingCaseFiles,
        });
    }
};

export const removeBreadcrumbsByIndexAction = (breadcrumbIndex: number) => ({
    type: DELETE_FROM_BREADCRUMBS,
    payload: breadcrumbIndex,
});

export const getFolderPredecessors = (folderId: number) => async (
    dispatch: AppDispatch,
    _: GetState,
    { api }
) => {
    try {
        const predecessors = await api.SigningAPI.get(
            `/folders/${folderId}/predecessors`
        );

        return predecessors;
    } catch (e) {
        debug.log('error', e);
    }
};

export const getFolderChildren = (
    folderId: number,
    params: RequestFoldersParamsOptions
) => async (dispatch: AppDispatch, _: GetState, { api }) => {
    const url = `/v2/folders/${folderId}/content`;
    const cancelToken = api.SigningAPI.getCancelToken();

    dispatch(requestCaseFilesItemsAction(cancelToken));
    const inMemoryDataKey = url + JSON.stringify(params);

    const dataInMemory = inMemory.get(inMemoryDataKey);

    if (dataInMemory) {
        dispatch({
            type: OPTIMISTIC_UPDATE_CONTENT_CASE_FILES_ITEMS,
            payload: dataInMemory,
        });
    }

    try {
        const options = {
            paginate: true,
            cancelToken: cancelToken.token,
        };

        const { data, count }: any = await api.SigningAPI.get(
            url,
            params,
            options
        );
        const payload = {
            items: data,
            itemCount: count,
        };

        inMemory.add(inMemoryDataKey, payload, CASE_FILES_EXPIRATION_TIME);
        dispatch(successfulRequestCaseFilesItemsAction(payload, cancelToken));

        return Promise.resolve(null);
    } catch (e) {
        if (e.cancel) {
            removeTokenFromRequestQueue(cancelToken);

            return Promise.resolve(null);
        }

        dispatch({
            type: FETCH_CASE_FILES_ITEMS_FAILURE,
            payload: ErrorTypes.FetchingCaseFiles,
        });

        return Promise.reject(null);
    }
};

export const deleteCaseFilesItems = (ids: PrefixObject[]) => async (
    dispatch: AppDispatch,
    getState: GetState,
    { api }
) => {
    inMemory.clear();
    const byIds = ids.map((x) => x.id);
    const caseFilesIds = ids
        .filter((x) => x.type === Type.CaseFile)
        .map((x) => x.id);
    const foldersIds = ids
        .filter((x) => x.type === Type.Folder)
        .map((x) => x.id)
        .join(',');
    const optimisticDeleteItems = getState().archive.items.filter(
        (x) => byIds.indexOf(x.id) === -1
    );

    dispatch({
        type: OPTIMISTIC_DELETE_ITEMS,
        payload: optimisticDeleteItems,
    });

    dispatch({ type: DELETE_ITEMS_REQUEST });

    try {
        if (foldersIds.length) {
            await api.SigningAPI.delete(`/folders/${foldersIds}`);
        }

        if (caseFilesIds.length) {
            // @todo: Deleting multiple elements with a single request is not supported yet. Once backend implements that functionality this shall be refactored
            /**
             * NOTE: since deleting all the casefiles might take a while,
             * we have to wait for everything to go throught before continuing.
             * This is to prevent from resolving the main promise too early, which will lead
             * to errors on the sideeffects (fx. on the Archive)
             */
            await Promise.all(
                caseFilesIds.map((id: number) =>
                    api.SigningAPI.delete(`/casefiles/${id}`)
                )
            );
        }

        dispatch({ type: DELETE_ITEMS_SUCCESS });

        return Promise.resolve();
    } catch (e) {
        dispatch({ type: DELETE_ITEMS_FAILURE });

        return Promise.reject();
    }
};

export const unshareSelfFromFolder = (folder: CaseFileItem) => async (
    dispatch: AppDispatch,
    getState: GetState,
    { api }
) => {
    dispatch({ type: REMOVE_SELF_FROM_SHARED_FOLDER_REQUEST });

    const { id: folderId } = folder;
    const {
        archive: { items },
    } = getState();

    const {
        optimisticUpdatedItems,
        unsharedFolderIndex,
    } = removeFolderFromArray(folderId, items);

    const itemCount = optimisticUpdatedItems.length;

    dispatch({
        type: OPTIMISTIC_REMOVE_SELF_FROM_SHARED_FOLDER,
        payload: { items: optimisticUpdatedItems, itemCount },
    });

    try {
        await api.SigningAPI.post(`/folders/${folderId}/un-share`);

        dispatch({ type: REMOVE_SELF_FROM_SHARED_FOLDER_SUCCESS });

        analytics.track('archive - remove shared folder');

        return Promise.resolve();
    } catch (error) {
        dispatch({
            type: REMOVE_SELF_FROM_SHARED_FOLDER_OPTIMISTIC_ROLLBACK,
            payload: { unsharedFolderIndex, folder },
        });
        dispatch({
            type: REMOVE_SELF_FROM_SHARED_FOLDER_FAILURE,
        });

        return Promise.reject(error);
    }
};

/**
 * Removes a folder from an array of folders and returns an object
 * contaning the filtered array and the index of the removed folder.
 */
function removeFolderFromArray(folderId, items) {
    let unsharedFolderIndex: number | undefined;

    const isFolderToUnshare = ({ type, id }, folderToUnshareId) => {
        return type === 'folder' && id === folderToUnshareId;
    };

    const optimisticUpdatedItems = items.filter((archiveItem, index) => {
        const match = !isFolderToUnshare(archiveItem, folderId);

        if (!match) {
            unsharedFolderIndex = index;
        }

        return match;
    });

    return { optimisticUpdatedItems, unsharedFolderIndex };
}

export const setDefaultFolder = (folderId: number) => async (
    dispatch: AppDispatch,
    getState: GetState,
    { api }
) => {
    const optimisticUpdatedItems = getState().archive.items.map((folder) => {
        if (folder.id === folderId) {
            return {
                ...folder,
                folderType: Type.Unshareable,
            };
        } else if (folder.folderType === Type.Unshareable) {
            return {
                ...folder,
                folderType: Type.Other,
            };
        }

        return folder;
    });

    dispatch({
        type: OPTIMISTIC_SET_DEFAULT_FOLDER,
        payload: optimisticUpdatedItems,
    });

    dispatch({ type: SET_DEFAULT_FOLDER_REQUEST });

    try {
        await api.SigningAPI.put(`/folders/default/${folderId}`);
        dispatch({ type: SET_DEFAULT_FOLDER_SUCCESS });
    } catch (e) {
        dispatch({
            type: SET_DEFAULT_FOLDER_FAILURE,
            payload: ErrorTypes.SettingDefaultFolder,
        });
    }
};

export const updateCaseFilesItemTitle = (
    item: CaseFileItem,
    title: string
) => async (dispatch: AppDispatch, getState: GetState, { api }) => {
    const optimisticUpdatedItems = getState().archive.items.map(
        (caseFile: CaseFileItem) => {
            if (caseFile.id === item.id) {
                return { ...caseFile, title };
            }

            return caseFile;
        }
    );

    dispatch({
        type: OPTIMISTIC_UPDATE_CASE_FILE_ITEM_TITLE,
        payload: optimisticUpdatedItems,
    });

    dispatch({ type: UPDATE_CASE_FILE_ITEM_TITLE_REQUEST });

    try {
        const isCaseFile = item.type === Type.CaseFile;
        const url = `/${isCaseFile ? 'casefiles' : 'folders'}/${item.id}`;

        const payload = {
            title,
            // Also need to add the case file reference number (for case files)
            // and the parent folder id (for folders) otherwise the PUT endpoint
            // would reset them if not passed
            ...(isCaseFile && item.reference && { reference: item.reference }),
            ...(!isCaseFile && item.parentId && { parent: item.parentId }),
        };

        await api.SigningAPI.put(url, payload);
        dispatch({ type: UPDATE_CASE_FILE_ITEM_TITLE_SUCCESS });
    } catch (e) {
        dispatch({
            type: UPDATE_CASE_FILE_ITEM_TITLE_FAILURE,
            payload: ErrorTypes.UpdatingTitle,
        });
    }
};

export const createFolder = (title: string, parent?: number) => async (
    dispatch: AppDispatch & any,
    _: any,
    { api }
) => {
    inMemory.clear();
    dispatch({ type: CREATE_FOLDER_REQUEST });
    try {
        const item: CaseFileItem = await api.SigningAPI.post('/folders', {
            title,
            parent,
        });

        dispatch({
            type: CREATE_FOLDER_SUCCESS,
            payload: {
                ...item,
                accessLevel: ACCESS_FULL,
                type: Type.Folder,
            },
        });
    } catch (e) {
        dispatch({
            type: CREATE_FOLDER_FAILURE,
            payload: ErrorTypes.CreatingFolder,
        });
    }
};

export const selectCaseFileItem = (caseFile: CaseFileItem) => ({
    type: SELECT_CASE_FILE,
    payload: caseFile,
});

export type FoldersPayload = {
    id: number;
    parentId?: number;
}[];

/**
 * @todo: This function needs to be refactored as it should do one thing and one thing only.
 * This function does two completely different things:
 *  - Moving folders which only requires: `folders` parameter
 *  - Moving case files which requires: `newFolderId`, `oldFolderId`, `caseFilesIds` function arguments.
 */
export const moveFoldersAndCaseFiles = (
    newFolderId: number,
    oldFolderId: number | undefined,
    caseFilesIds: number[],
    folders: FoldersPayload
) => async (dispatch: AppDispatch, _: GetState, { api }) => {
    inMemory.clear();
    dispatch({ type: MOVE_FOLDERS_AND_CASE_FILES_REQUEST });

    try {
        if (caseFilesIds.length > 0 && !!oldFolderId) {
            const caseFiles = caseFilesIds.join(',');

            await api.SigningAPI.post(
                `/folders/${newFolderId}/casefiles/${caseFiles}`
            );
            await api.SigningAPI.delete(
                `/folders/${oldFolderId}/casefiles/${caseFiles}`
            );
        }

        if (folders.length > 0) {
            await api.SigningAPI.patch('/folders', { folders });
        }

        dispatch({ type: MOVE_FOLDERS_AND_CASE_FILES_SUCCESS });

        return Promise.resolve(null);
    } catch (e) {
        dispatch({ type: MOVE_FOLDERS_AND_CASE_FILES_FAILURE });

        return Promise.reject(e);
    }
};

export const getFolderShareData = (folderId: number) => async (
    dispatch: AppDispatch,
    _: GetState,
    { api }
) => {
    dispatch({ type: FETCH_FOLDER_SHARE_DATA_REQUEST, payload: folderId });

    try {
        const data = await api.SigningAPI.get(`/folders/${folderId}`);
        const shares = await api.SigningAPI.get(`/folders/${folderId}/share`);
        const invites = await api.SigningAPI.get(
            `/folders/${folderId}/invites`
        );

        dispatch({
            type: FETCH_FOLDER_SHARE_DATA_SUCCESS,
            payload: {
                folderId,
                shares,
                invites,
                ...data,
            },
        });
    } catch (e) {
        debug.error(e);
        dispatch({ type: FETCH_FOLDER_SHARE_DATA_FAILURE });
    }
};

// @todo: Refactor to support multiple invitations
export const shareFolder = (folderId: number, recipient) => async (
    dispatch: AppDispatch,
    _,
    { api: { SigningAPI } }
) => {
    dispatch({ type: FETCH_SHARE_FOLDER_REQUEST });

    if (recipient.external) {
        const payload = {
            invitations: [recipient],
        };

        await SigningAPI.post(`/folders/${folderId}/invite`, payload);
        const invites = await SigningAPI.get(`/folders/${folderId}/invites`);

        dispatch({
            type: FETCH_INVITE_FOLDER_SUCCESS,
            payload: {
                folderId,
                invites,
            },
        });

        return Promise.resolve();
    }

    const payload = {
        shares: [recipient],
    };

    await SigningAPI.post(`/folders/${folderId}/share`, payload);
    const shares = await SigningAPI.get(`/folders/${folderId}/share`);

    dispatch({
        type: FETCH_SHARE_FOLDER_SUCCESS,
        payload: {
            folderId,
            shares,
        },
    });

    return Promise.resolve();
};

export const deleteFolderInvitation = (
    folderId: number,
    inviteId: number
) => async (dispatch: AppDispatch, _: GetState, { api }) => {
    dispatch({ type: FETCH_DELETE_PENDING_INVITATION_REQUEST });
    try {
        await api.SigningAPI.delete(`/folders/invites/${inviteId}`);

        dispatch({
            type: FETCH_DELETE_PENDING_INVITATION_SUCCESS,
            payload: {
                folderId,
                inviteId,
            },
        });

        return Promise.resolve(inviteId);
    } catch (e) {
        dispatch({
            type: FETCH_DELETE_PENDING_INVITATION_FAILURE,
            payload: e,
        });

        return Promise.reject(e);
    }
};

export const unshareFolder = (folderId: number, userId: number) => async (
    dispatch: AppDispatch,
    _: GetState,
    { api }
) => {
    dispatch({ type: FETCH_UNSHARE_FOLDER_REQUEST });
    try {
        await api.SigningAPI.delete(`/folders/${folderId}/share`, {
            users: [userId],
        });

        dispatch({
            type: FETCH_UNSHARE_FOLDER_SUCCESS,
            payload: {
                folderId,
                userId,
            },
        });

        return Promise.resolve(null);
    } catch (e) {
        dispatch({
            type: FETCH_UNSHARE_FOLDER_FAILURE,
            payload: e,
        });

        return Promise.reject(e);
    }
};

export const updateUserFolderRights = (
    folderId: number,
    userId: number,
    right: string
) => async (dispatch: AppDispatch, _: GetState, { api }) => {
    dispatch({ type: FETCH_UPDATE_USER_FOLDER_RIGHTS_REQUEST });
    try {
        await api.SigningAPI.put(`/folders/${folderId}/share/${userId}`, {
            right,
        });

        dispatch({
            type: FETCH_UPDATE_USER_FOLDER_RIGHTS_SUCCESS,
            payload: {
                folderId,
                userId,
                right,
            },
        });

        return Promise.resolve(null);
    } catch (e) {
        dispatch({
            type: FETCH_UPDATE_USER_FOLDER_RIGHTS_FAILURE,
            payload: e,
        });

        return Promise.reject(e);
    }
};

export const getCaseFiles = (params: object) => async (
    dispatch,
    _,
    { api }
) => {
    const cancelToken = api.SigningAPI.getCancelToken();
    const url = '/v2/casefiles';

    dispatch(requestCaseFilesItemsAction(cancelToken));

    const inMemoryDataKey = url + JSON.stringify(params);
    const inMemoryData = inMemory.get(inMemoryDataKey);

    if (inMemoryData) {
        dispatch({
            type: OPTIMISTIC_UPDATE_CASEFILES_ITEMS,
            payload: inMemoryData,
        });
    }

    try {
        const { data, count } = await api.SigningAPI.get(url, params, {
            paginate: true,
        });
        const payload = {
            itemCount: count,
            items: data.map((item) => ({
                ...item,
                type: 'casefile',
                accessLevel: ACCESS_FULL,
            })),
        };

        inMemory.add(inMemoryDataKey, payload, CASE_FILES_EXPIRATION_TIME);
        dispatch(successfulRequestCaseFilesItemsAction(payload, cancelToken));
    } catch (e) {
        if (e.cancel) {
            removeTokenFromRequestQueue(cancelToken);

            return false;
        }

        dispatch({
            type: FETCH_CASE_FILES_ITEMS_FAILURE,
            payload: e,
        });
    }
};

export const fetchCaseFileStats = (days?: number) => async (
    dispatch: AppDispatch,
    _,
    { api: { SigningAPI } }
) => {
    // Only go into "fetching" state once.
    dispatch({
        type: FETCH_ARCHIVE_STATS_REQUEST,
    });
    const createdAfter = moment(new Date())
        .subtract(days, 'days')
        .format('YYYY-MM-DD');
    const statuses = (await SigningAPI.get(
        '/v2/casefile/statuses',
        days && {
            createdAfter,
        }
    )) as {
        anonymized: number;
        completed: number;
        scheduled: number;
        deleted: number;
        expired: number;
        failed: number;
        //prettier-ignore
        'new': number; //new is a keyword - to avoid inference problems this is handled
        pending: number;
        rejected: number;
        shredded: number;
        signed: number;
    };

    dispatch({
        type: FETCH_ARCHIVE_STATS_SUCCESS,
        payload: {
            drafts: { count: statuses.new },
            pending: { count: statuses.pending },
            completed: { count: statuses.completed },
            rejected: { count: statuses.rejected },
            expired: { count: statuses.expired },
            scheduled: { count: statuses.scheduled },
        },
    });
};

export const getCasesCompletedRecently = (
    days: number = 7,
    resultCount: number = 5
) => async (dispatch, getState: GetState, { api: { SigningAPI } }) => {
    const options = {
        paginate: true, // Required to be able to extract item count from headers
    };
    // Common query parameters
    const query = {
        page: 1,
        per_page: resultCount,
        status: 5,
        completedAfter: moment(new Date())
            .subtract(days, 'days')
            .format('YYYY-MM-DD'),
        sort: '-completed',
    };

    return await SigningAPI.get('/v2/casefiles', query, options);
};

export const getCasesExpiringSoon = (
    days: number = 7,
    resultCount: number = 5
) => async (dispatch, getState: GetState, { api: { SigningAPI } }) => {
    const options = {
        paginate: true, // Required to be able to extract item count from headers
    };
    // Common query parameters
    const query = {
        page: 1,
        per_page: resultCount,
        status: 1, // pending casefiles
        expiresBefore: moment(new Date())
            .add(days, 'days')
            .format('YYYY-MM-DD'), // Cases that expire before n* days
        sort: '-expireAt',
    };

    return await SigningAPI.get('/v2/casefiles', query, options);
};

export const getLatestCases = async () => {
    const options = {
        paginate: true,
    };
    const query = {
        page: 1,
        per_page: 50,
        status: 1, // pending casefiles
        sort: '-expireAt',
    };

    return await SigningAPI.get('/v2/casefiles', query, options);
};

export const getPendingSignatures = async () =>
    await SigningAPI.get(`/signingrequests/pending-by-current-user`);

export const setCasefileSent = () => ({
    type: SET_CASE_FILE_SENT,
});

export const resetCasefileSent = () => ({
    type: RESET_CASE_FILE_SENT,
});

export const fetchFolderOwnerName = async (folderId: number) => {
    const { owner } = await SigningAPI.get(`/folders/${folderId}/users`);

    return owner;
};

export const fetchSharedFolders = (params: object) => async (
    dispatch: AppDispatch,
    _,
    { api: { SigningAPI } }
) => {
    dispatch({
        type: FETCH_SHARED_FOLDERS_REQUEST,
    });

    try {
        const { data: folders, count: folderCount } = await SigningAPI.get(
            '/folders/shared/top',
            params,
            {
                paginate: true,
            }
        );

        dispatch({
            type: FETCH_SHARED_FOLDERS_SUCCESS,
            payload: { folders, folderCount },
        });
    } catch (error) {
        dispatch({
            type: FETCH_SHARED_FOLDERS_FAILURE,
            error,
        });
    }
};

export const fetchSharedFoldersStats = () => async (
    dispatch: AppDispatch,
    _,
    { api: { SigningAPI } }
) => {
    dispatch({
        type: FETCH_SHARED_FOLDERS_STATS_REQUEST,
    });

    try {
        const count: FoldersCount = await SigningAPI.get('/folders/count', {
            top: true,
        });

        dispatch({
            type: FETCH_SHARED_FOLDERS_STATS_SUCCESS,
            payload: count.sharedFolderCount,
        });
    } catch (error) {
        console.error(error);
    }
};
