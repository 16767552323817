import { images } from 'Constants';
import { i18n } from 'Language';
import React, { useEffect } from 'react';
import {
    CustomerBranding,
    SigningCasefileLimited,
    signingRequestStatus,
} from 'types/SigningProcess';
import { Languages } from 'Language/Constants';
import { checkColorSchemeValid, trackSigning } from './utils';
import UIButton from 'Common/components/Vega/Button';
import './signing-done-lead-gen.scss';
import './signing-footer-lead-gen.scss';
import {
    ArrowTopRightOnSquareIcon,
    ArrowRightIcon,
} from '@heroicons/react/20/solid';

const URL_PENNEO_BASE = 'https://penneo.com';
const EVENT_PAGE_LOADED = 'Signing Completed Promote Penneo - Page Loaded';
const EVENT_FREE_TRIAL_CLICKED = 'Get a Free Trial - Button Clicked';
const EVENT_LEARN_MORE_CLICKED = 'Learn More About Penneo - Link Clicked';
const EVENT_GO_TO_ARCHIVE_CLICKED = 'Go to Archive - Button Clicked';

type Props = {
    isSigningDoneLeadGen: boolean;
    requestStatus: signingRequestStatus;
    shouldRedirect: boolean;
    linkTracking(name: string, url: string): void;
    links: {
        name: string;
        url: string;
    }[];
    language: Languages;
    branding: CustomerBranding;
    linkLocalization: (language?: Languages) => string;
    caseFileTypeName: Pick<
        SigningCasefileLimited,
        'caseFileTypeName'
    >['caseFileTypeName'];
};

export const SigningDoneComponentsLeadGen = ({
    isSigningDoneLeadGen,
    linkTracking,
    links,
    language,
    branding,
    linkLocalization,
    caseFileTypeName,
}: Props) => {
    useEffect(() => {
        if (isSigningDoneLeadGen) {
            trackSigning(EVENT_PAGE_LOADED);
        }
    }, [isSigningDoneLeadGen]);

    const visitPenneo = `${URL_PENNEO_BASE}/${linkLocalization(
        language
    )}digital-signing-data-collection/?utm_source=after-signing-page`;

    const tryPenneo = `${URL_PENNEO_BASE}/${linkLocalization(
        language
    )}free-trial/?utm_source=after-signing-page`;

    const handleFreeTrialClick = () => {
        window.open(tryPenneo, '_blank', 'noopener noreferrer');
        trackSigning(EVENT_FREE_TRIAL_CLICKED, {
            caseFileSigningFlow: caseFileTypeName,
        });
    };

    const handleGoToArchiveClick = () => {
        window.open('/casefiles/manage', '_blank', 'noopener noreferrer');
        trackSigning(EVENT_GO_TO_ARCHIVE_CLICKED, {
            caseFileSigningFlow: caseFileTypeName,
        });
    };

    return (
        <>
            <div className="signing-container-background">
                <div className="signing-content">
                    <img
                        className="penneo-logo"
                        src={`${images}/penneo-trust.svg`}
                        alt="Penneo Trust"
                    />

                    <div className="title">
                        {i18n`Your signature has been successfully registered`}
                    </div>
                    <div className="description">
                        {i18n`Once all signatures are collected, you'll receive an email with access to your signed document`}
                    </div>
                    <div className="archive-container">
                        <div className="archive-content">
                            <div className="archive-info">
                                <img
                                    src={`${images}/folder-confirmed.svg`}
                                    alt="Folder Confirmed"
                                />
                                <div className="archive-text">
                                    {i18n`Easily archive all your documents signed via Penneo, accessible anytime, anywhere. Use your eID to create or log in to your account.`}
                                </div>
                            </div>
                            <UIButton
                                onClick={handleGoToArchiveClick}
                                priority="tertiary"
                                type="success"
                                size="md"
                                theme="dark">
                                {i18n`Go to Archive`}
                                <ArrowRightIcon className="h-6"></ArrowRightIcon>
                            </UIButton>
                        </div>
                    </div>
                    {isSigningDoneLeadGen && (
                        <>
                            <div className="visit-penneo">
                                <div className="visit-text">
                                    {i18n`Interested in digital signing solution -`}
                                </div>
                                <a
                                    href={visitPenneo}
                                    target="_blank"
                                    rel="noreferrer"
                                    onClick={() =>
                                        trackSigning(EVENT_LEARN_MORE_CLICKED, {
                                            caseFileSigningFlow: caseFileTypeName,
                                        })
                                    }>
                                    {i18n`learn more about Penneo Sign`}
                                    <ArrowTopRightOnSquareIcon className="h-6 ml-2"></ArrowTopRightOnSquareIcon>
                                </a>
                            </div>
                            <div className="section-title about-penneo">{i18n`About Penneo Sign`}</div>
                            <div className="section-description">
                                {i18n`Penneo Sign is a scalable solution that automates company workflows related to digital signing and document management in a secure, compliant, and efficient way.`}
                            </div>
                            <div className="info-text">
                                {i18n`By providing a platform that meets the highest security standards and complies with EU and local regulations (e.g., GDPR, eIDAS), Penneo secures trust between companies and their clients.`}
                            </div>
                            <div className="try-penneo">
                                <UIButton
                                    onClick={handleFreeTrialClick}
                                    priority="primary"
                                    type="accent"
                                    size="md">
                                    {i18n`Try Penneo Sign for free`}
                                </UIButton>
                            </div>
                            <div className="dashboard-image">
                                <img
                                    src={`${images}/penneo-sign-dashboard.svg`}
                                    alt="Penneo Sign Dashboard"
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>

            <div
                className="footer"
                style={{
                    ...(branding &&
                        checkColorSchemeValid(branding) && {
                            backgroundColor: branding?.backgroundColor,
                        }),
                }}>
                <div className="footer-text">
                    <div className="text-block title">
                        {i18n`About Penneo digital signatures:`}
                    </div>
                    <div className="text-block links">
                        {links.map((link) => {
                            const href = `${URL_PENNEO_BASE}/${linkLocalization(
                                language
                            )}${link.url}`;

                            return (
                                <a
                                    key={link.name}
                                    href={href}
                                    target="_blank"
                                    rel="noreferrer"
                                    onClick={() =>
                                        linkTracking(link.name, href)
                                    }>
                                    {i18n(link.name)}
                                </a>
                            );
                        })}
                    </div>
                </div>
            </div>
        </>
    );
};
