import React from 'react';
import { i18n } from 'Language';
import { images } from 'Constants';
import analytics from 'Common/Analytics';
import '../marketing-feature-template.scss';
import Button from 'Common/components/Button';

type Props = {};
class EmailTemplates extends React.Component<Props> {
    registerInterest = () => {
        analytics.track('marketing - feature upsell', { feature: 'forms' });
        this.setState({ clicked: true });
    };

    render() {
        return (
            <div className="marketing-feature ui-v2">
                <h4 className="title">
                    {i18n`Penneo Forms`}
                    &nbsp;
                    <span className="text-blue">
                        <i className="far fa-lock-alt"></i>
                    </span>
                </h4>
                <div className="content text-center">
                    <img
                        src={`${images}/graphics/signatures.png`}
                        height="160"
                        alt=""
                    />
                    <h1>
                        {i18n`Penneo Forms are a part of our`}
                        &nbsp;
                        <a
                            className="underline-link"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://penneo.com/pricing/"
                            style={{ fontWeight: 'bold' }}>
                            {i18n`business plan`}
                        </a>
                    </h1>
                    <h4>
                        {i18n`Allow signers to fill data into any document before signing`}
                        <br />
                        <br />
                        {i18n`Filling in information and signing forms can be a hassle. Process forms digitally to facilitate signature flows and data post-processing.`}
                    </h4>
                    <p>
                        {i18n`If you want to enable this feature, get in touch with support for a demo`}
                    </p>
                    <br />
                    <a
                        href="https://penneo.force.com/support/s/contactsupport"
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={this.registerInterest}>
                        <Button theme="blue" icon="far fa-external-link">
                            {i18n`Contact Support`}
                        </Button>
                    </a>
                </div>
            </div>
        );
    }
}

export default EmailTemplates;
