import { debug } from 'Core';
import Analytics from 'Common/Analytics';

/**
 * Track the navigation from the dashboard to other pages via analytics services
 * @param {string} label The label to append to the common prefix
 * @param {object} eventProps Event properties to bundle with the event
 */
export const trackDashboardInteraction = (
    label: string,
    eventProps: object = {}
) => {
    const trackingParams = [`New Dashboard - ${label}`, eventProps];

    Analytics.track(...trackingParams);

    debug.isDebugEnabled && console.log(trackingParams);
};
