import PropTypes from 'prop-types';
import React from 'react';
import assign from 'object-assign';
import Constants from '../../Constants';
import { env } from 'Constants';
import Loader from 'Common/components/Common/Loader.jsx';
import { notify } from 'react-notify-toast';
import { modal } from 'Common/components/Common/Modal';
import { i18n } from 'Language';
import { debug } from 'Core';
import { parseURIString, loadCasefileObject } from '../../utils/uri';
import amplitude from 'Common/Amplitude';
import analytics from 'Common/Analytics';

// Stores
import CasefileStore from '../../stores/CasefileStore';
import SignerStore from '../../stores/SignerStore';
import MessageTemplateStore from '../../stores/MessageTemplateStore';
import DocumentStore from '../../stores/DocumentStore';
import URIStore from '../../stores/URIStore';
import AuthStore from 'Auth/stores/AuthStore';
import CustomerStore from 'Auth/stores/CustomerStore';
import { TranslationStore } from 'Language';

// Actions
import CasefileActions from '../../actions/CasefilesActionCreators';
import SignerActions from '../../actions/SignerActionCreators';
import DocumentActions from '../../actions/DocumentActionCreators';
import MessageTemplateActions from '../../actions/MessageTemplateActionCreators';
import URIActions from '../../actions/URIActionCreators';
import { fetchCustomer } from 'Auth/redux/customer/actions';
import Button from 'Common/components/Button';

import { connect } from 'react-redux';
import { formatSSN } from 'utils';
import { EmailTemplateGroup, TemplateType } from 'types/EmailTemplates';
import { getCustomDefaultTemplate } from 'Common/utils/customDefaultEmailTemplate';

const getCaseFileCustomDefaultTemplate = (
    templates,
    type,
    customDefaultEmailTemplates
) => {
    return getCustomDefaultTemplate(
        templates || [],
        EmailTemplateGroup.CASEFILE,
        type,
        customDefaultEmailTemplates
    );
};

/* CasefileContainer serves as a Controller View component
 * It listens to change events and retrieve Application state from Stores.
 * It then passes that data down to its child components via props.
 */
class CasefileContainer extends React.Component {
    static contextTypes = {
        router: PropTypes.object.isRequired,
    };

    static propTypes = {
        children: PropTypes.object.isRequired,
        customerSettings: PropTypes.object,
        userSettings: PropTypes.object,
        dispatch: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            ...this.getCurrentState(),
            forcedFetch: false,
            applyingPayload: false,
            appliedData: false,
            documentsReady: false,
            clearing: false,
            cleared: false,
            extractedData: null,
            loadingData: false,
            loaded: false,
            sendingCasefile: false,
            // Controls if app is ready for use.
            isBootstrapped: false,

            // State to cache URI payload if app is not bootstrapped when URI is received
            cachedURIPayload: null,
        };
    }

    attachElectronEvents = () => {
        const { ipcEvents } = Constants;
        const { ipcRenderer } = env.electron;
        const {
            URI_LOADED,
            DEBUG_CONSOLE,
            INTEGRATION_CODE_REQUEST,
            URI_FAILED,
        } = ipcEvents;

        ipcRenderer.on(URI_LOADED, this.handleURI);
        ipcRenderer.on(URI_FAILED, this.handleURIFailure);
        ipcRenderer.on(DEBUG_CONSOLE, this.displayConsole);
        ipcRenderer.on(
            INTEGRATION_CODE_REQUEST,
            URIActions.generateIntegrationCode
        );

        // Printer Events
        ipcRenderer.on('printer-convert-start', this.processFile);
    };

    processFile = (event, data) => {
        notify.hide();

        notify.show(
            <span>
                <i className="far fa-sync fa-spin"></i>
                &nbsp;
                {i18n`Processing printed file`}
            </span>,
            'info',
            -1
        );

        URIActions.convertPrinterDocument(data);
    };

    handleExtractedDocument = (data) => {
        notify.hide();

        notify.show(
            <span>
                <i className="fa fa-check"></i>&nbsp;
                {i18n`Document added`}
            </span>,
            'success'
        );

        env.electron.ipcRenderer.send('printer-convert-finish', data);
    };

    handleURIFailure = () => {
        notify.hide();

        notify.show(
            <span>
                <i className="far fa-exclamation-circle"></i>
                &nbsp;
                {i18n`Failed to load the data`}
            </span>,
            'error'
        );
    };

    handleExtractedError = (data) => {
        notify.hide();

        if (!data || !data.error) {
            return false;
        }

        let message;

        switch (data.error.status) {
            case 413:
                message = `File is too large to print`;
                break;
            default:
                message = `Could not print file`;
                break;
        }

        let content = (
            <span>
                <i className="fa fa-times"></i>&nbsp;
                {i18n(message)}
            </span>
        );

        notify.show(content, 'error');

        debug.log('Failed printing', data);
    };

    removeElectronEvents = () => {
        if (env.platform !== 'desktop') {
            return false;
        }

        const { ipcEvents } = Constants;
        const { ipcRenderer } = env.electron;
        const {
            URI_LOADED,
            DEBUG_CONSOLE,
            INTEGRATION_CODE_REQUEST,
            URI_FAILED,
        } = ipcEvents;

        // Remove electron listeners
        ipcRenderer.removeListener(URI_LOADED, this.handleURI);
        ipcRenderer.removeListener(URI_FAILED, this.handleURIFailure);
        ipcRenderer.removeListener(DEBUG_CONSOLE, this.displayConsole);
        ipcRenderer.removeListener(
            INTEGRATION_CODE_REQUEST,
            URIActions.generateIntegrationCode
        );

        // Printer Events
        env.electron.ipcRenderer.removeListener(
            'printer-convert-start',
            this.processFile
        );
    };

    displayConsole = (...args) => {
        debug.log(...args);
    };

    patchedDialog = async (options) => {
        const { dialog } = env.electron.remote;

        const version = window.process.versions.electron?.split('.');

        if (parseInt(version?.[0] ?? 0) >= 8) {
            return dialog.showMessageBox(null, options);
        }

        return new Promise((res) =>
            dialog.showMessageBox(null, options, (buttonIndex) =>
                res({ response: buttonIndex })
            )
        );
    };

    // For future reference:
    // handleUriNewVersion() =>{
    //     if clearDataOrNot
    //         ...
    //     finallyLoadPayloadData
    //     promptUserForAction
    //     act...
    // }
    //
    // SEQUENCE AS->IS:
    // [
    //    notBootstrapped->
    //        cachePayload->
    //          notifyElectron->
    //              clearData->
    //                  updateFolderForcefully->
    //                      loadPayloadData->
    //                          cleanSigners->
    //                              validateCasefile->
    //                                  sendCasefile
    // ]
    //
    handleURI = async (event, payload) => {
        notify.hide();

        notify.show(
            <span>
                <i className="far fa-sync fa-spin"></i>
                &nbsp;
                {i18n`Processing file`}
            </span>,
            'info',
            -1
        );

        this.setState({ forcedFetch: true });

        // This is when everything fails! Show an error!
        if (
            !payload &&
            this.state.applyingPayload &&
            !this.state.cachedURIPayload
        ) {
            this.handleURIFailure();

            // If you are in this state, we simply cannot help you!
            // We need to back the hell out and provide you with a preloaded page so you can send out a casefile!
            return this.setState({
                applyingPayload: false,
                forcedFetch: false,
            });
        }

        //debugger;
        if (!payload) {
            return false;
        }

        // If URI arrived before the app is fully loaded, cache it to run this function after bootstrapping.
        if (
            (!this.state.isBootstrapped && payload) ||
            (this.state.isBootstrapped &&
                !this.state.cachedURIPayload &&
                payload)
        ) {
            return this.setState({ cachedURIPayload: payload });
        }

        if (!this.state.applyingPayload) {
            this.setState({
                applyingPayload: true,
                appliedData: false, // new
                forcedFetch: true,
            });
        }

        // Notify electron about data received
        this.notifyElectron(Constants.ipcEvents.CASEFILE_DATA_LOADED);

        /**
         * Track that data arrived via URI,
         * its schema version and its source
         */
        analytics.track('uri - data loaded', {
            schemaVersion: payload.data.schemaVersion,
            source: payload.data.source ? payload.data.source : null,
        });

        if (
            (payload?.clearData || payload?.data?.clearData) &&
            !this.state.cleared
        ) {
            await this.resetData();

            return;
        }

        if (payload?.data?.folderId) {
            CasefileStore.updateFolderForce(payload?.data?.folderId);
        }

        let extractedData = parseURIString(payload.data);

        if (
            (!payload?.clearData &&
                !this.state.loadingData &&
                !this.state.appliedData) ||
            (!this.state.loadingData &&
                this.state.loaded &&
                !this.state.appliedData)
        ) {
            this.setState({ forcedFetch: true, applyingPayload: true });
            await loadCasefileObject(extractedData).then(() => {
                this.setState({ appliedData: true });
            });
        }

        if (
            !this.state.documentsReady &&
            extractedData?.documents?.length <= 0
        ) {
            return;
        }

        if (!this.state.applyingPayload && payload.submitWhenReady) {
            return;
        }

        if (!payload.submitWhenReady && this.state.applyingPayload) {
            this.setState({
                forcedFetch: false,
                applyingPayload: false,
                appliedData: false, // NEW
                cachedURIPayload: null,
            });
        }

        let { router } = this.context;

        if (
            payload.submitWhenReady &&
            this.state.applyingPayload &&
            !this.state.clearing &&
            !this.state.loadingData &&
            (this.state.documentsReady || payload?.data?.documents?.length <= 0)
        ) {
            notify.hide();

            notify.show(
                <span>
                    <i className="fa fa-check"></i>&nbsp;
                    {i18n`Processed payload`}
                </span>,
                'success'
            );

            router.push({
                name: 'casefile-summary',
            });

            if (!this.state.sendingCasefile && payload.clearData) {
                //check payload prior
                SignerActions.clearSigners();

                const signers = SignerStore.getSigners();
                const cleanSigners = signers.filter(
                    (signer) => signer.name && signer.email
                );

                /**
                 * In order for the new signer list to take effect
                 * the old store must be wiped and set again.
                 * Only then the validation will kick in and allow to send the casefile.
                 */
                SignerActions.updateSigners(cleanSigners);
            }

            if (this.state.sendingCasefile) {
                return;
            }

            const options = {
                type: 'info',
                title: 'The Case is ready to be sent',
                message:
                    "If you are sure, that you want to send that case click 'Yes'. " +
                    "If you want to review your data click 'No'.",
                buttons: ['Yes', 'No'],
            };

            if (env.platform === 'desktop') {
                this.setState({ sendingCasefile: true }, () => {
                    this.patchedDialog(options).then((data) => {
                        const buttonIndex = data.response;

                        /**
                         * When using submitWhenReady = true, we 'jump' to the Summary page
                         * before the signers from the payload have been properly loaded,
                         * triggering an error on the empty signer that gets automatically added.
                         * Here we clean all empty signers before building the final payload.
                         */

                        if (buttonIndex === 0) {
                            notify.hide();
                            this.setState({
                                applyingPayload: false,
                            });
                            this.casefileSendValidation();

                            return;
                        }

                        if (buttonIndex === 1) {
                            this.setState({
                                forcedFetch: false,
                                applyingPayload: false,
                                appliedData: false,
                                sendingCasefile: false,
                                documentsReady: false,
                                loaded: false,
                                cachedURIPayload: null,
                                clearing: false,
                                cleared: false,
                            });

                            return;
                        }
                    });
                });
            }
        }

        if (this.state.forcedFetch || this.state.applyingPayload) {
            return;
        }

        if (payload?.data?.folderId || payload?.folderId) {
            CasefileStore.updateFolderForce(
                payload?.data?.folderId || payload?.folderId
            );
        }

        // New
        // To avoid resetting accidentially if there is a event & payload present?
        if (event && payload) {
            this.setState({ forcedFetch: true }); // Show a spinner because we are clearly processing something

            return;
        }

        notify.hide();

        notify.show(
            <span>
                <i className="fa fa-check"></i>&nbsp;
                {i18n`Processed payload`}
            </span>,
            'success'
        );

        this.setState({
            documentsReady: false,
            loaded: false,
            cachedURIPayload: null,
            clearing: false,
            cleared: false,
            loadingData: false,
        });
    };

    /** Clears all the stores */
    clearStores = async () => {
        this.setState({
            forcedFetch: true,
            clearing: true,
            documentsReady: false,
            appliedData: false,
        });
        await CasefileActions.clearStore();
        await SignerActions.clearStore();
        await DocumentActions.clearStore();
        this.setState({ clearing: false, cleared: true });
    };

    /** Clears stores and reloads initial bootstrapping data */
    resetData = async () => {
        await this.clearStores();
        await this.loadData();
    };

    // Listen for changes
    componentDidMount() {
        if (env.platform === 'desktop') {
            this.attachElectronEvents();
        }

        // Custom Actions
        const {
            CREATE_CASEFILE_SUCCESS,
            CREATE_CASEFILE_FAILURE,
        } = Constants.ActionTypes;

        // Attach Change Listeners
        CasefileStore.addChangeListener(this._onChange);
        MessageTemplateStore.addChangeListener(this._onChange);
        SignerStore.addChangeListener(this._onChange);
        DocumentStore.addChangeListener(this._onChange);
        URIStore.addChangeListener(this._onChange);
        TranslationStore.addChangeListener(this.updateLanguage);
        // Attach Custom Listeners
        CasefileStore.addEventListener(
            CREATE_CASEFILE_SUCCESS,
            this._onCasefileSent
        );
        CasefileStore.addEventListener(
            CREATE_CASEFILE_FAILURE,
            this._onCasefileSentError
        );

        // Attach Printer Listeners
        URIStore.addEventListener(
            'DOCUMENT_EXTRACT_URI_SUCCESS',
            this.handleExtractedDocument
        );
        URIStore.addEventListener(
            'DOCUMENT_EXTRACT_URI_FAILURE',
            this.handleExtractedError
        );

        if (env.platform === 'desktop') {
            this.notifyElectron(
                Constants.ipcEvents.APPLICATION_DID_MOUNT,
                'ping'
            );
        }

        this.loadData();
    }

    notifyElectron = (channel, message = '') => {
        env.electron.ipcRenderer.send(channel, message);
    };
    updateLanguage = () => {
        this.forceUpdate();
    };

    // Unbind change listener
    componentWillUnmount() {
        this.clearStores();

        // Custom Actions
        const {
            CREATE_CASEFILE_SUCCESS,
            CREATE_CASEFILE_FAILURE,
        } = Constants.ActionTypes;

        // Remove Listeners
        CasefileStore.removeChangeListener(this._onChange);
        MessageTemplateStore.removeChangeListener(this._onChange);
        SignerStore.removeChangeListener(this._onChange);
        DocumentStore.removeChangeListener(this._onChange);
        URIStore.removeChangeListener(this._onChange);

        // Remove Custom Listeners
        CasefileStore.removeEventListener(
            CREATE_CASEFILE_SUCCESS,
            this._onCasefileSent
        );
        CasefileStore.removeEventListener(
            CREATE_CASEFILE_FAILURE,
            this._onCasefileSentError
        );

        // Remove Printer Documents
        URIStore.removeEventListener(
            'DOCUMENT_EXTRACT_URI_SUCCESS',
            this.handleExtractedDocument
        );
        URIStore.removeEventListener(
            'DOCUMENT_EXTRACT_URI_FAILURE',
            this.handleExtractedError
        );

        // Remove Electron Listeners
        this.removeElectronEvents();

        // Hide any active notifications
        notify.hide();
        modal.hide();
    }

    componentDidUpdate(prevProps, prevState) {
        // If an error is dispatched
        if (!prevState.error && this.state.error) {
            this.displayErrorModal();
        }

        const { messageTemplates: prevMessageTemplates } = prevState;
        const { messageTemplates } = this.state;
        const haveMessageTemplatesLoaded =
            !prevMessageTemplates.length && messageTemplates.length;

        if (haveMessageTemplatesLoaded) {
            const { customDefaultEmailTemplates } = this.props;
            const { messageTemplates } = this.state;
            const customDefaultTpl = getCaseFileCustomDefaultTemplate(
                messageTemplates,
                TemplateType.SIGNER,
                customDefaultEmailTemplates
            );

            // If the user has a custom default template, automatically select it
            if (customDefaultTpl) {
                MessageTemplateActions.updateSelectedTemplateId(
                    customDefaultTpl.id
                );
            }
        }

        // If component has bootstrapped and there is a cached URI payload. process it
        const {
            isBootstrapped,
            cachedURIPayload,
            documentsReady,
            applyingPayload,
            loaded,
            cleared,
            sendingCasefile,
        } = this.state;

        if (
            ((!prevState.loaded && loaded) ||
                (!prevState.sendingCasefile && sendingCasefile) ||
                (!prevState.cleared && cleared) ||
                (prevState.isBootstrapped &&
                    isBootstrapped &&
                    !applyingPayload &&
                    !prevState.applyingPayload) ||
                (!prevState.isBootstrapped && isBootstrapped) ||
                (!prevState.documentsReady && documentsReady)) &&
            cachedURIPayload
        ) {
            this.handleURI(null, cachedURIPayload);
        }
    }

    loadData = async () => {
        this.setState({ loadingData: true, documentsReady: false });
        const { dispatch } = this.props;

        this.setState({ isBootstrapped: false });

        await CasefileActions.fetchCasefileTypes();
        await CasefileActions.fetchFolders();
        await CasefileActions.fetchDefaultFolder();
        await MessageTemplateActions.fetchMessageTemplates();

        // Set default sensitive information based on company settings.
        const customer = CustomerStore.getCustomer(
            AuthStore.getAuthDetails().cid
        );

        if (customer) {
            SignerActions.setDefaultReminderInterval(
                this.props.customerSettings.casefile.reminderInterval
            );

            // Fetch customer data (on Redux)
            await dispatch(fetchCustomer(customer.id));
        }

        this.setState({
            isBootstrapped: true,
            loadingData: false,
            loaded: true,
        });
    };

    // Update view state when change event is received
    _onChange = (action) => {
        switch (action) {
            case 'GENERATE_INTEGRATION_CODE':
                this.sendIntegrationCode();
                break;
            case 'DOCUMENTS_CHANGED':
                this.documentsReady();
                break;
            default:
                break;
        }

        this.setState(this.getCurrentState(action));
    };

    documentsReady() {
        this.setState({ documentsReady: true });
    }

    getCurrentState(action = null) {
        const casefile = CasefileStore.getCasefile();
        const templates = MessageTemplateStore.getMessageTemplates();

        // The completed and reminder email templates are not exposed in the UI
        // nor are part of the flux store. We simply reassign them on every update
        const { customDefaultEmailTemplates } = this.props;
        const completedEmail = getCaseFileCustomDefaultTemplate(
            templates,
            TemplateType.COMPLETED,
            customDefaultEmailTemplates
        );
        const reminderEmail = getCaseFileCustomDefaultTemplate(
            templates,
            TemplateType.REMINDER,
            customDefaultEmailTemplates
        );

        return {
            // Casefile
            casefile: casefile,
            folders: CasefileStore.getFolders(),
            casefileTypes: CasefileStore.getCasefileTypes(),

            // Message Template
            messageTemplates: templates,
            messages: {
                signer: MessageTemplateStore.getMessageTemplate(
                    casefile.language
                ),
                completed: completedEmail,
                reminder: reminderEmail,
            },

            // Store folder
            defaultFolder: CasefileStore.getDefaultFolder(),
            selectedFolderId: CasefileStore.getSelectedFolder(),

            // Documents
            documents: DocumentStore.getDocuments(),
            selectedDocumentTypes: DocumentStore.getDocumentTypes(),
            availableDocumentTypes: DocumentStore.getAvailableDocumentTypes(),
            options: DocumentStore.getDocumentOptions(),

            // Signers
            signers: SignerStore.getSigners(),
            signerRules: SignerStore.getSignerRules(),
            availableSignerTypes: SignerStore.getAvailableSignerTypes(),

            // Date
            sendAtDate: CasefileStore.getSendAtDate(),
            sendLater: CasefileStore.getSendLater(),
            expireAtDate: CasefileStore.getExpireAtDate(),
            expireEnable: CasefileStore.getExpireEnable(),

            // Copy recipients
            recipients: CasefileStore.getRecipients(),

            // Error handlers
            casefileInputErrors: CasefileStore.getValidationErrors().casefile,
            documentInputErrors: CasefileStore.getValidationErrors().documents,
            signerInputErrors: CasefileStore.getValidationErrors().signers,
            recipientInputErrors: CasefileStore.getValidationErrors()
                .recipients,
            messageInputErrors: CasefileStore.getValidationErrors().message,

            // Integration code
            integrationCode: URIStore.getIntegrationCode(),

            // Store Status
            isFetching: CasefileStore.isFetching(),
            error: CasefileStore.getError(),
            action: action,

            // Data metadata
            source: CasefileStore.getDataSource(),
        };
    }

    // *************************************************************************
    // CASE VALIDATION BEFORE SEND
    // *************************************************************************
    casefileSendValidation = async () => {
        this.setState({ forcedFetch: true });

        // Trigger casefile validation before sending case.
        CasefileActions.validateInputs();

        // Read input validation state
        let isInputValid = CasefileStore.validateAllFormInputs();

        if (isInputValid) {
            this.saveCasefile();

            return;
        }

        this.setState({ forcedFetch: false, sendingCasefile: false });
    };

    saveCasefile = async () => {
        let {
            casefile,
            documents,
            signers,
            recipients,
            sendAtDate,
            expireAtDate,
            source,
        } = this.state;
        const { userSettings } = this.props;
        let { selectedFolderId, messages, options, extractedData } = this.state;

        if (!selectedFolderId) {
            selectedFolderId = CasefileStore.getFolder(extractedData?.folderId)
                ?.id;
        }

        if (!selectedFolderId) {
            selectedFolderId =
                CasefileStore.getFolder(userSettings?.lastUsedFolder?.folderId)
                    ?.id ??
                CasefileStore.getSelectedFolder() ??
                CasefileStore.getDefaultFolder();
        }

        casefile.sendAt = sendAtDate ? sendAtDate.unix() : null;
        casefile.expireAt = expireAtDate ? expireAtDate.unix() : null;

        signers = signers.map((item) => {
            return {
                ...item,
                ssn: item.ssn ? formatSSN(item.ssn, item.ssnType) : item.ssn,
            };
        });

        // If the casefile type has options, apply them to all documents.
        if (options) {
            documents.forEach((doc) => {
                doc.opts = {};
                doc.opts[options.opts[0].name] = options.opts[0].value;

                if (
                    options.opts[0].name === 'boardSignCount' &&
                    options.opts[0].value > 0
                ) {
                    doc.opts.conditionalSigningEnabled = true;
                }
            });
        }

        const payload = {
            casefile: casefile,
            documents: documents,
            signers: signers,
            selectedFolderId: selectedFolderId,
            recipients: recipients,
            messages: {
                emailSubject: messages.signer.subject,
                emailText: messages.signer.message,
                ...(messages.completed && {
                    completedEmailSubject: messages.completed.subject,
                    completedEmailText: messages.completed.message,
                }),
                ...(messages.reminder && {
                    reminderEmailSubject: messages.reminder.subject,
                    reminderEmailText: messages.reminder.message,
                }),
            },
            source,
        };

        CasefileActions.sendCasefile(payload);

        this.setState({
            forcedFetch: false,
            applyingPayload: false,
            appliedData: false,
            documentsReady: false,
            loaded: false,
            cachedURIPayload: null,
            clearing: false,
            cleared: false,
            loadingData: false,
            sendingCasefile: false,
        });
    };

    _onCasefileSent = (casefile) => {
        const { router } = this.context;
        const { documents, signers, recipients, source } = this.state;

        analytics.track('casefile - sent', {
            id: casefile.id,
            caseFileTypeId: casefile.caseFileTypeId,
            sensitiveData: !!casefile.sensitiveData,
            signOnMeeting: !!casefile.signOnMeeting,
            visibilityMode: casefile.visibilityMode,
            language: casefile.language,
            expireAt: casefile.expireAt,
            sendAt: casefile.sendAt,
            documentCount: documents.length,
            signerCount: signers.length,
            copyRecipientCount: recipients.length,
            source: source,
        });

        amplitude.incrementUserProperty('casefiles sent');

        router.push({ name: 'casefile-success' });
    };

    _onCasefileSentError = (error) => {
        this.displayErrorModal(error);
    };

    displayErrorModal = () => {
        let { error } = this.state;

        let config = {
            title: i18n`Error sending case file`,
            body: (
                <div>
                    {error.reason && <h4>{i18n`${error.reason}`}</h4>}

                    <p>{i18n`Please, verify that all the information
                             is correct and try again. If the problem
                             persists contact support`}</p>

                    <p className="text-warning">
                        <i className="fa fa-warning"></i>&nbsp;
                        {i18n`Error code: ${error.status}`}
                    </p>
                </div>
            ),
            buttons: (
                <div>
                    <Button onClick={modal.hide} theme="blue">
                        {i18n`Close`}
                    </Button>
                </div>
            ),
        };

        modal.show(config);
    };

    sendIntegrationCode = () => {
        let integrationCode = URIStore.getJSONIntegrationCode();

        env.electron.ipcRenderer.send(
            Constants.ipcEvents.INTEGRATION_CODE_GENERATED,
            integrationCode
        );
    };

    render() {
        let { children } = this.props;

        let props = assign(this.state, {
            resetData: this.resetData,
            casefileSendValidation: this.casefileSendValidation,
            user: {
                ...this.props.user,
                ...{ language: TranslationStore.getLanguage() },
            },
            key: TranslationStore.getLanguage(),
        });

        if (
            !this.state.isBootstrapped ||
            this.state.isFetching ||
            this.state.applyingPayload
        ) {
            return <Loader />;
        }

        return React.cloneElement(children, props);
    }
}

export default connect((state) => ({
    customDefaultEmailTemplates: state.settings.data.user.defaultEmailTemplates,
    customerSettings: state.settings.data.customer,
    userSettings: state.settings.data.user,
}))(CasefileContainer);
