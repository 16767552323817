import UIButton from 'Common/components/Vega/Button';
import { i18n } from 'Language';
import React from 'react';
import { ReactRouter, Route } from 'types/Router';
import {
    UIModalContainer,
    UIModalFooter,
    UIModalHeader,
    UIModalOverlay,
} from 'Common/components/Vega/Modal';

type Props = {
    handleCloseModal: () => void;
    router: ReactRouter;
    nextRoute?: Route;
    saveCasefileDraft: () => void;
};

const CasefileRoundsExitModal = ({
    handleCloseModal,
    router,
    nextRoute,
    saveCasefileDraft,
}: Props) => (
    <UIModalOverlay onClose={handleCloseModal} zIndex="higher">
        <UIModalContainer>
            <UIModalHeader>
                <i
                    slot="icon"
                    className="fas fa-info-circle text-[20px] text-neutral-700"
                />
                <span slot="title">{i18n`Save Casefile progress?`}</span>
                <span slot="subtitle">
                    {i18n`When you discard progress on creating a Casefile, it will be lost. Are you sure you want to leave the page without saving?`}
                </span>
            </UIModalHeader>
            <UIModalFooter>
                <UIButton
                    slot="tertiaryButton"
                    onClick={() => handleCloseModal()}
                    priority="tertiary"
                    type="neutral">
                    {i18n`Cancel`}
                </UIButton>
                <UIButton
                    slot="secondaryButton"
                    onClick={() => nextRoute && router.push(nextRoute)}
                    priority="tertiary"
                    type="neutral">
                    {i18n`Discard`}
                </UIButton>
                <UIButton
                    slot="primaryButton"
                    onClick={() => saveCasefileDraft()}
                    priority="primary"
                    type="neutral">
                    {i18n`Save as draft`}
                </UIButton>
            </UIModalFooter>
        </UIModalContainer>
    </UIModalOverlay>
);

export default CasefileRoundsExitModal;
