import keyMirror from 'keymirror';

const constants = {
    VISIBILITY_MODES: {
        0: 'all_documents',
        1: 'certain_documents',
    },

    // Utility Constants
    IMAGE_PATH: '/images/',
    DATE_FORMAT: 'DD/MM/YYYY',
    DATETIME_FORMAT: 'DD/MM/YYYY',

    MAX_DOCS_SIZE_FOR_COPY_EMAIL: '7Mb',

    DEFAULT_REMINDER_INTERVAL: 2,
    DEFAULT_USERS_CAN_STORE_CONTACTS: true,

    AUTH_METHODS: {
        SESSION: 'SID',
        SECRET: 'SECRET',
        JWT: 'JWT',
        PUBLIC: 'PUBLIC',
    },

    ipcEvents: {
        // Electron related channels
        URI_LOADED: 'uri-loaded',
        URI_FAILED: 'uri-failed',
        CASEFILE_DATA_LOADED: 'casefile-data-loaded',
        INTEGRATION_CODE_REQUEST: 'integration-code-request',
        INTEGRATION_CODE_GENERATED: 'integration-code-generated',
        APPLICATION_DID_MOUNT: 'application-did-mount',
        DOCUMENT_FILE_REQUEST: 'document-file-request',
        DOCUMENT_FILE_REPLY: 'document-file-reply',
        DOCUMENT_PREVIEW_REQUEST: 'document-preview-request',
        DEBUG_CONSOLE: 'debug-console',
    },

    ActionTypes: keyMirror({
        // Archive actions
        FETCH_CASEFILES_SUCCESS: null,
        CURRENT_QUERY_CHANGED: null,
        CURRENT_QUERY_CLEARED: null,
        SELECTED_VIEW: null,
        COLUMNS_UPDATED: null,
        SET_ITEMS_PERPAGE: null,

        // Casefile Actions
        FETCH_CASEFILE_TYPES_REQUEST: null,
        FETCH_CASEFILE_TYPES_SUCCESS: null,
        FETCH_CASEFILE_TYPES_FAILURE: null,

        CREATE_CASEFILE_REQUEST: null,
        CREATE_CASEFILE_SUCCESS: null,
        CREATE_CASEFILE_FAILURE: null,

        FETCH_CASEFILE_DRAFT_REQUEST: null,
        FETCH_CASEFILE_DRAFT_SUCCESS: null,
        FETCH_CASEFILE_DRAFT_FAILURE: null,

        SAVE_DRAFT_REQUEST: null,
        SAVE_DRAFT_SUCCESS: null,
        SAVE_DRAFT_FAILURE: null,

        CREATE_FOLDER_REQUEST: null,
        CREATE_FOLDER_SUCCESS: null,
        CREATE_FOLDER_FAILURE: null,

        FETCH_FOLDERS_REQUEST: null,
        FETCH_FOLDERS_SUCCESS: null,
        FETCH_FOLDERS_FAILURE: null,

        FETCH_CASEFILE_LOG_SUCCESS: null,

        FETCH_DEFAULT_FOLDER_SUCCESS: null,

        SENSITIVE_DATA_CHANGED: null,
        CLEAR_CASEFILE_STORE: null,
        CLEAR_FOLDERS: null,
        SIGN_AT_MEETING: null,
        CASEFILE_CLEAR_ERROR: null,

        FETCH_MESSAGE_TEMPLATES_REQUEST: null,
        FETCH_MESSAGE_TEMPLATES_SUCCESS: null,
        FETCH_MESSAGE_TEMPLATES_FAILURE: null,

        // Validation
        VALIDATE_INPUTS: null,

        // Other Actions
        CONDITIONAL_SIGNING_OPTIONS_UPDATED: null,
        SET_CONDITIONAL_SIGNING_COUNT: null,
        CASEFILE_CHANGED: null,
        CASEFILE_TYPE_CHANGED: null,
        INTEGRATION_CODE_CHANGED: null,
        VISIBILITY_MODE_CHANGED: null,
        DOCUMENTS_CHANGED: null,
        DOCUMENT_OPTIONS_CHANGED: null,
        DOCUMENT_ADDED: null,
        DOCUMENT_ROLE_UPDATED: null,
        DOCUMENT_REMOVED: null,
        DOCUMENT_EDITED: null,
        DOCUMENT_UPLOADED: null,
        DOCUMENT_REORDER: null,
        DOCUMENT_DELETE_SUCCESS: null,
        FETCH_DOCUMENTS_SUCCESS: null,
        AVAILABLE_DOCUMENT_TYPES_UPDATED: null,
        CLEAR_DOCUMENT_STORE: null,
        FOLDER_CHANGED: null,
        LOCAL_FILES_LOADED: null,
        PDF_BLOB_LOADED: null,
        SIGNER_ROLES_RESET: null,
        SIGNER_RULES_UPDATED: null,
        SIGNER_ROLES_UPDATED: null,
        SIGNERS_CHANGED: null,
        SIGNERS_CLEARED: null,
        SIGNER_CHANGED: null,
        SIGNER_ADDED: null,
        SIGNER_REMOVED: null,
        UPDATED_SIGNER_ROLE: null,
        CLEAR_SIGNER_STORE: null,
        REMOVE_COPY_RECIPIENTS_V2: null,
        REMINDER_INTERVAL_SET: null,
        SET_DATA_SOURCE: null,

        RECIPIENTS_CHANGED: null,
        RECIPIENT_ADDED: null,
        RECIPIENT_REMOVED: null,
        RECIPIENT_UPDATED: null,
        RECIPIENTS_REMOVED: null,

        SEND_AT_DATE_CHANGED: null,
        SEND_LATER_TOGGLE: null,
        SEND_DATE_CLEARED: null,

        EXPIRE_AT_DATE_CHANGED: null,
        ENABLE_EXPIRE_TOGGLE: null,
        EXPIRE_DATE_CLEARED: null,

        MESSAGE_TEMPLATE_STORE_CLEARED: null,
        MESSAGE_TEMPLATE_SELECTED: null,
        MESSAGE_TEMPLATE_UPDATED: null,
        MESSAGE_TEMPLATE_URI_UPDATED: null,

        DRAFT_DOCUMENT_ADDED_REQUEST: null,
        DRAFT_DOCUMENT_ADDED_SUCCESS: null,
        DRAFT_DOCUMENT_ADDED_FAILURE: null,
    }),

    signerEventLog: [
        {
            id: 0,
            event: 'Email Sent',
            description: 'Signer received the email with the signing link',
        },
        {
            id: 1,
            event: 'Email Opened',
            description: 'Signer opened the email with the signing link',
        },
        {
            id: 2,
            event: 'Signing Link Clicked',
            description: 'Signer clicked on the signing link',
        },
        {
            id: 3,
            event: 'Signed',
            description: 'Signer signed the document',
        },
        {
            id: 4,
            event: 'Rejected',
            description: 'Signer rejected to sign the document',
        },
        {
            id: 5,
            event: 'Reminder Sent',
            description: 'Signer was sent a reminder email',
        },
        {
            id: 6,
            event: 'Email Undeliverable',
            description:
                'Signer could not be delivered the email with the signing link',
        },
        {
            id: 7,
            event: 'Casefile ready',
            description: 'Casefile has been made available for Signer',
        },
        {
            id: 8,
            event: 'Casefile completed',
            description: 'Case file was completed successfully',
        },
    ],
};

export const CaseFileStatus = {
    DRAFT: 0,
    PENDING: 1,
    REJECTED: 2,
    DELETED: 3,
    SIGNED: 4,
    COMPLETED: 5,
    FAILED: 6,
    EXPIRED: 7,
    ANONYMIZED: 8,
    SHREDDED: 9,
};

export const ArchiveRoutes = [
    {
        id: 'all',
        label: 'All',
        route: {
            name: 'archive-all',
            params: {
                tab: null,
            },
        },
    },
    {
        id: 'shared',
        label: 'Shared',
        route: {
            name: 'archive-virtual',
            params: {
                tab: 'shared',
            },
        },
    },
    {
        id: 'drafts',
        label: 'Drafts',
        query: {
            status: 0,
            sort: '-created',
        },
        route: {
            name: 'archive-virtual',
            params: {
                tab: 'drafts',
            },
        },
    },
    {
        id: 'scheduled',
        label: 'Scheduled delivery',
        query: {
            status: 0,
            sort: '-created',
        },
        route: {
            name: 'archive-virtual',
            params: {
                tab: 'scheduled-delivery',
            },
        },
    },
    {
        id: 'pending',
        label: 'Pending',
        query: {
            status: 1,
            sort: '-created',
        },
        route: {
            name: 'archive-virtual',
            params: {
                tab: 'pending',
            },
        },
    },
    {
        id: 'rejected',
        label: 'Rejected',
        query: {
            status: 2,
            sort: '-created',
        },
        route: {
            name: 'archive-virtual',
            params: {
                tab: 'rejected',
            },
        },
    },
    {
        id: 'completed',
        label: 'Completed',
        query: {
            status: 5,
            sort: '-completed',
        },
        route: {
            name: 'archive-virtual',
            params: {
                tab: 'completed',
            },
        },
    },
];

/**
 * The id of an email template that was manually
 * edited by the user
 */
export const CUSTOM_EMAIL_TEMPLATE_ID = -1;

/**
 * In case conditional signing is enabled, we can't derive the signer status
 * from the signing requests and need to use this custom status.
 * */
export const ABUNDANT_SIGNER_STATUS = {
    name: 'Signature no longer necessary',
    details: 'Casefile has already been finalized',
    icon: 'clock',
    color: 'cyan' as 'red' | 'green' | 'yellow' | 'gray' | 'cyan',
};

export const DEFAULT_BOARD_COUNT_DESKTOP_APP = 30;

export default constants;
