/**
 * This file contains custom names for errors interpreted from server responses
 */

export enum ERRORS {
    // Error thrown when the user attempts to log in with an account that hasn't finished activation.
    AUTH_ACCOUNT_NOT_SETUP = 'AUTH_ACCOUNT_NOT_SETUP',

    // Preshared authentication failure. This means the preshared token is not valid or it expired.
    AUTH_PRESHARED_TOKEN_INVALID = 'AUTH_PRESHARED_TOKEN_INVALID',

    // Error thrown when the user doesn't have a valid JWT or refresh token to continue refreshing the current session
    AUTH_SESSION_EXPIRED = 'AUTH_SESSION_EXPIRED',
}
